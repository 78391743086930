import { DEFAULT_TIMEZONE } from '@traba/consts'
import { calendarStyleCombos } from '@traba/theme'
import {
  ShiftPayType,
  ShiftStatus,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { useState } from 'react'
import { Input, Table } from 'src/components'
import { useUserContext } from 'src/context/user/UserContext'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { getTimeZoneAbbreviation } from 'src/shared/utils/dateUtils'
import { UserRolePermission } from 'src/types'
import { hasPermissions } from 'src/utils/userUtils'
import { ScheduleDailyViewWorkerTableRoleChunk } from './ScheduleDailyViewWorkerTableRoleChunk'

const TABLE_ROW_HEIGHT = 66
const TABLE_ROW_HEADER_HEIGHT = 116

interface Props {
  shifts: ShiftAndAddress[]
  isFromDashboard?: boolean
}

export function ScheduleDailyViewWorkerTableContent(props: Props) {
  const { shifts, isFromDashboard } = props

  const userContext = useUserContext()
  const userCanManageWorkers = hasPermissions(userContext.state.userProfile, [
    UserRolePermission.ManageWorkers,
  ])

  const [selectedRows, setSelectedRows] = useState<
    WorkerShiftWithWorkerDetails[]
  >([])
  const [searchFilter, setSearchFilter] = useState('')
  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])
  const shift = shifts[0]
  const timezone = shift?.timezone || DEFAULT_TIMEZONE
  const tzAbbreviation = getTimeZoneAbbreviation(
    shift.businessStartTime ?? shift.startTime,
    timezone,
  )
  const isUpcoming = shift.startTime > new Date()
  const isPBUShift = shift?.payType === ShiftPayType.UNIT
  const shiftIsCanceled = shift && shift.status === ShiftStatus.CANCELED

  const showEarnedMoney = userCanViewWages && !isUpcoming && !shiftIsCanceled
  const headers = [
    'Role',
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: theme.space.xxs,
        fontWeight: 600,
      }}
    >
      Worker name
    </div>,
    ...(isUpcoming ? ['Status'] : []),
    `Clock in (${tzAbbreviation})`,
    `Clock out (${tzAbbreviation})`,
    'Break Time',
    ...(isPBUShift ? ['Units Completed'] : []),
    'Worked Time',
    ...(showEarnedMoney ? ['Worker Charge'] : []),
    ...(userCanManageWorkers ? ['Actions'] : []),
    ...(userCanManageWorkers ? [''] : []), // context menu
  ]
  if (!shift) {
    return null
  }

  const handleWorkerShiftRowSelection = (
    checked: boolean,
    workerShift: WorkerShiftWithWorkerDetails,
  ) => {
    setSelectedRows((prevSelectedRows) => {
      if (checked) {
        return prevSelectedRows.filter(
          ({ workerId }) => workerId !== workerShift.workerId,
        )
      } else {
        return [...prevSelectedRows, workerShift]
      }
    })
  }

  return (
    <>
      {!isFromDashboard && (
        <Input
          placeholder="Search workers by name..."
          leftIconName="search"
          name="workerSearch"
          containerStyle={{
            marginBottom: theme.space.xs,
          }}
          type="text"
          defaultValue=""
          width="300px"
          value={searchFilter}
          onChange={(e) => {
            e.preventDefault()
            setSearchFilter(e.target.value)
          }}
          onClear={() => setSearchFilter('')}
        />
      )}
      <div
        style={{
          maxHeight: TABLE_ROW_HEADER_HEIGHT + 8 * TABLE_ROW_HEIGHT,
          overflowY: 'auto',
        }}
      >
        <Table textAlignEndLast headers={headers} showEmptyState>
          {shifts.map((shift, index) => {
            return (
              <ScheduleDailyViewWorkerTableRoleChunk
                reviewColSpan={headers.length}
                shift={shift}
                searchFilter={searchFilter}
                handleWorkerShiftRowSelection={handleWorkerShiftRowSelection}
                selectedRows={selectedRows}
                isFirstShift={index === 0}
                key={shift.shiftId}
                setSearchFilter={setSearchFilter}
                dayViewRoleColor={calendarStyleCombos[index].borderColor}
              />
            )
          })}
        </Table>
      </div>
    </>
  )
}
