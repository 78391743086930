import { Invoice, Money } from '@traba/types'
import { useEffect, useState } from 'react'
import { trabaApi } from 'src/api/helpers'

import { useUpflowPortalUrl } from 'src/hooks/useUpflowPortalUrl'
import {
  InvoiceDetailsTableProps,
  InvoiceDetailsTableRow,
} from './InvoiceDetailsTable.ui'
import {
  calculateTotalGrossPayToWorker,
  convertToRowData,
  getInvoiceSubtitle,
} from './utils'

export type InvoiceDetails = Invoice & {
  totalGrossPay?: Money
  invoiceSubtitle?: string
}

export const useInvoiceDetailsTable = (props: {
  invoiceId: string
}): InvoiceDetailsTableProps => {
  const [lineItemRows, setLineItemData] = useState<InvoiceDetailsTableRow[]>([])
  const [invoiceDetails, setInvoiceDetails] = useState<
    InvoiceDetails | undefined
  >()
  const [loading, setLoading] = useState<boolean>(false)

  const getInvoiceById = async (invoiceId: string) => {
    try {
      setLoading(true)
      const response = await trabaApi.get(`/my-company/invoices/${invoiceId}`)
      const { lineItems, ...rest } = response.data
      const totalGrossPay = {
        amount: calculateTotalGrossPayToWorker(lineItems),
        currency: 'USD',
      }
      const invoiceSubtitle = getInvoiceSubtitle(response.data)

      setLineItemData(convertToRowData(lineItems))
      setInvoiceDetails({ ...rest, totalGrossPay, invoiceSubtitle })
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(`Error retrieving invoice ${invoiceId}`, error)
    }
  }

  const { isLoading, upflowPortalUrl } = useUpflowPortalUrl()

  useEffect(() => {
    getInvoiceById(props.invoiceId)
  }, [])

  return {
    invoiceDetails,
    lineItemRows,
    upflowPortalUrl,
    loading: loading || isLoading,
  }
}
