import React, { HTMLProps } from 'react'
import { Text } from 'src/components/base'
import {
  InputErrorIcon,
  InputErrorMessage,
} from 'src/components/base/Input/Input.styles'
import { theme } from 'src/libs/theme'

import * as S from './SingleSelector.styles'

interface Option<T extends string> {
  value: T
  label: string
  detail?: string
}

export interface SingleSelectorProps<T extends string> {
  options: Option<T>[]
  selectedOption: T
  onChange: (selected: T) => void
  error?: string
  style?: HTMLProps<HTMLDivElement>['style']
  className?: HTMLProps<HTMLDivElement>['className']
}

export function SingleSelectorUI<T extends string>(
  props: SingleSelectorProps<T>,
) {
  const { options, onChange, selectedOption, error, style, className } = props

  function handleSelectOption(option: Option<T>, isActive: boolean) {
    if (!isActive) {
      onChange(option.value)
    }
  }

  return (
    <>
      <S.SingleSelectorContainer style={style} className={className}>
        {options.map((option, i: number) => {
          const isActive = selectedOption === option.value

          return (
            <S.OptionItemContainer key={i}>
              <S.OptionItem
                onClick={() => handleSelectOption(option, isActive)}
                key={i}
                isActive={isActive}
              >
                {option.label}
              </S.OptionItem>
              {option.detail ? (
                <Text style={{ fontSize: 12 }} variant="label">
                  {option.detail}
                </Text>
              ) : null}
            </S.OptionItemContainer>
          )
        })}
      </S.SingleSelectorContainer>
      {!!error && (
        <InputErrorMessage style={{ marginBottom: theme.space.med }}>
          <InputErrorIcon />
          {error}
        </InputErrorMessage>
      )}
    </>
  )
}
