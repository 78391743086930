import {
  RoleInfoForCreateShiftRequest,
  ShiftRequest,
  ShiftRequestParentWithShiftRequest,
} from '@traba/types'
import { Dispatch, SetStateAction } from 'react'
import AddRoleToSchedule from '../components/AddRoleToSchedule'
import AddShiftToSchedule from '../components/AddShiftToSchedule'
import { AddToExistingScheduleOptions } from './SelectAddToExistingScheduleOptions'

type AddToExistingScheduleContentProps = {
  onPrevious: () => void
  onContinue: () => void
  selectedOption: AddToExistingScheduleOptions
  shiftRequestParent: ShiftRequestParentWithShiftRequest
  shiftRequest: ShiftRequest
  setShiftRequest: () => void
  recurringRoles: RoleInfoForCreateShiftRequest[]
  setRecurringRoles: Dispatch<SetStateAction<RoleInfoForCreateShiftRequest[]>>
  addNewRole: () => void
  removeRole: (roleId: string) => void
  updateRoleInfoForCreateShiftRequest: (
    updatedRoleInfoForCreateShiftRequest: RoleInfoForCreateShiftRequest,
    originalRoleId: string,
  ) => void
}

export default function AddToExistingScheduleContent(
  props: AddToExistingScheduleContentProps,
) {
  const {
    onPrevious,
    onContinue,
    selectedOption = AddToExistingScheduleOptions.ADD_SINGLE_DAY_TO_SCHEDULE,
    shiftRequestParent,
    shiftRequest,
    setShiftRequest,
    recurringRoles,
    setRecurringRoles,
    addNewRole,
    updateRoleInfoForCreateShiftRequest,
    removeRole,
  } = props

  if (
    selectedOption === AddToExistingScheduleOptions.ADD_SINGLE_DAY_TO_SCHEDULE
  ) {
    return (
      <AddShiftToSchedule
        shiftRequestParent={shiftRequestParent}
        onContinue={onContinue}
        onPrevious={onPrevious}
        shiftRequest={shiftRequest}
        setShiftRequest={setShiftRequest}
        recurringRoles={recurringRoles}
        addNewRole={addNewRole}
        updateRoleInfoForCreateShiftRequest={
          updateRoleInfoForCreateShiftRequest
        }
        removeRole={removeRole}
      />
    )
  } else if (
    selectedOption ===
    AddToExistingScheduleOptions.ADD_ROLE_OR_WORKERS_TO_SCHEDULE
  ) {
    return (
      <AddRoleToSchedule
        shiftRequest={shiftRequest}
        onContinue={onContinue}
        onPrevious={onPrevious}
        recurringRoles={recurringRoles}
        setRecurringRoles={setRecurringRoles}
        addNewRole={addNewRole}
        updateRoleInfoForCreateShiftRequest={
          updateRoleInfoForCreateShiftRequest
        }
      />
    )
  }
}
