import { CompanyCategory, InvitedWorkers } from '@traba/types'
import { isRoleLocationNotMatchingShiftLocation } from '@traba/utils'
import { addHours, isAfter } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { Button, ButtonVariant, Modal, Row } from 'src/components'
import { CreateMemberModal } from 'src/components/Modals/CreateMemberModal'
import { CreateRoleModal } from 'src/components/Modals/CreateRoleModal'
import { useCompany } from 'src/hooks/useCompany'
import { useConnections } from 'src/hooks/useConnections'
import { useRoles } from 'src/hooks/useRoles'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import EditShiftsSaveButton from 'src/screens/EditShifts/EditShiftsSaveButton'
import { BookShiftsProps } from '../BookShiftsScreen'
import { BookShiftRoleSection } from '../components/BookShiftRoleSection'
import { BookShiftsWorkersContentModal } from '../types'
import {
  getMinHourlyPayRate,
  MIN_WORKER_HOURLY_PAY_DEFAULT,
  validateShiftEdits,
  validateWorkersStepShiftDataModel,
} from '../validation'

export function BookShiftsWorkersContentShiftDataModel(props: BookShiftsProps) {
  const {
    shiftRequest,
    setShiftRequest,
    onContinue,
    onPrevious,
    onSaveChanges,
    selectedShifts,
    setShowSelectModal,
    shiftUpdates,
    isEdit,
    defaultShiftRequest,
    existingShiftInvitations,
    recurringRoles,
    addNewRole,
  } = props
  const { invitedWorkers } = shiftRequest
  const schedule = shiftRequest.schedules[0]
  const { company } = useCompany()
  const { hotSettings } = useHotSettings()
  const { roles } = useRoles()
  const [modalType, setModalType] =
    useState<BookShiftsWorkersContentModal | null>(null)
  const roleLocationNotMatchingShiftLocation = recurringRoles.some((role) =>
    isRoleLocationNotMatchingShiftLocation(
      shiftRequest.locationId,
      roles.find((r) => r.roleId === role.roleId),
    ),
  )

  function closeModal() {
    setModalType(null)
  }

  const { favoriteWorkers } = useConnections()
  const noFavorites = favoriteWorkers.length === 0
  const tooLateForFavoritesFirst =
    !schedule.isRecurringSchedule &&
    isAfter(addHours(new Date(), 48), schedule.startTime)
  const favoritesFirstDisabled = noFavorites || tooLateForFavoritesFirst

  // When there are no favorites, force invitedWorkers to all.
  useEffect(() => {
    if (
      favoritesFirstDisabled &&
      invitedWorkers === InvitedWorkers.FAVORITES_FIRST
    ) {
      setShiftRequest({ invitedWorkers: InvitedWorkers.ALL })
    }
  }, [favoritesFirstDisabled, invitedWorkers, setShiftRequest])

  const minHourlyPay = getMinHourlyPayRate({
    companyMinHourlyPay: company?.minHourlyPayRate,
    platformMinHourlyPay:
      hotSettings?.platformMinHourlyPayRate ?? MIN_WORKER_HOURLY_PAY_DEFAULT,
  })
  const validationErrors = recurringRoles.map((r) =>
    validateWorkersStepShiftDataModel({
      data: shiftRequest,
      minHourlyPay,
      existingShiftInvitations,
      recurringRoles: [r],
      role: roles.find((roleInside) => r.roleId === roleInside.roleId),
    }),
  )
  const validationError = validationErrors.find((e) => e !== undefined)

  const editsError =
    selectedShifts &&
    defaultShiftRequest &&
    validateShiftEdits(defaultShiftRequest, selectedShifts, shiftUpdates)

  const isEventCompany = company?.category === CompanyCategory.EVENTS

  const isAddAnotherRoleDisabled = useMemo(() => {
    return (
      recurringRoles.some(
        (r) => !r.roleId || !r.slotsRequested || !r.supervisorId,
      ) || roleLocationNotMatchingShiftLocation
    )
  }, [recurringRoles, roleLocationNotMatchingShiftLocation])

  return (
    <>
      {recurringRoles.map((r) => (
        <BookShiftRoleSection {...props} role={r} setModalType={setModalType} />
      ))}
      <Row
        style={{ justifyContent: 'space-between', marginTop: theme.space.lg }}
      >
        {isEdit ? (
          <EditShiftsSaveButton
            onSaveChanges={onSaveChanges}
            validationError={editsError || validationError}
            selectedShifts={selectedShifts}
            setShowSelectModal={setShowSelectModal}
          />
        ) : (
          <>
            <Button onClick={onPrevious} variant={ButtonVariant.OUTLINED}>
              Previous
            </Button>
            <Row gap={theme.space.xs}>
              <Button onClick={addNewRole} disabled={isAddAnotherRoleDisabled}>
                + Add Another Role
              </Button>
              <Button onClick={onContinue} disabled={!!validationError}>
                Continue
              </Button>
            </Row>
          </>
        )}
      </Row>
      <Modal isOpen={modalType !== null} handleClose={closeModal}>
        {modalType === 'ROLE' || modalType === 'ROLE_PREVIOUS' ? (
          <CreateRoleModal
            onClose={closeModal}
            onCreate={(r) =>
              setShiftRequest({
                roleId: r.roleId,
                payRate: r.defaultPayRate,
                genderPreference: r.genderPreference,
              })
            }
            isEventCompany={isEventCompany}
            fromPrevious={modalType === 'ROLE_PREVIOUS'}
            filterLocationId={shiftRequest.locationId}
          />
        ) : modalType === 'CONTACT' ? (
          <CreateMemberModal
            setShowModal={closeModal}
            onCreate={(c) => setShiftRequest({ supervisorId: c.uid })}
          />
        ) : null}
      </Modal>
    </>
  )
}
