import { Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { DailyViewOfScheduleDetails } from '@traba/types'
import { EmptyPlaceholderTile } from 'src/components'
import { GoToScheduleTabButton } from './GoToScheduleTabButton'
import { ScheduleDailyViewShiftInvitation } from './ScheduleDailyViewShiftInvitation'

interface Props {
  shiftRequestParentDayDetails: DailyViewOfScheduleDetails | undefined
  shiftRequestParentId: string
}

export const ScheduleDailyViewShiftInvitations = (props: Props) => {
  const { shiftRequestParentDayDetails, shiftRequestParentId } = props

  if (shiftRequestParentDayDetails?.isRMSA) {
    return (
      <EmptyPlaceholderTile
        iconName="info"
        title={`Looking to send schedule invitations?`}
        subtitle={
          <Text
            variant="body2"
            className="xs-hide md-show"
            style={{
              textAlign: 'center',
              marginTop: theme.space.xxxs,
              alignItems: 'center',
            }}
          >
            You can invite workers to the full schedule by
            <GoToScheduleTabButton
              shiftRequestParentId={shiftRequestParentId}
              tab={3}
            />
          </Text>
        }
      />
    )
  }
  return (
    <>
      <Text variant="h4">Shifts invitations</Text>{' '}
      <Text variant="body2" mb={theme.space.xs}>
        If you are looking to invite workers directly onto the full schedule,
        you can do so by{' '}
        <GoToScheduleTabButton
          shiftRequestParentId={shiftRequestParentId}
          tab={3}
        />
      </Text>
      {Object.values(
        shiftRequestParentDayDetails?.shiftRequestToShiftMetadataMap ?? {},
      )?.map((im) => (
        <ScheduleDailyViewShiftInvitation
          key={im.shift.shiftId}
          shiftId={im.shift.shiftId}
          individualMetadata={im}
        />
      ))}
    </>
  )
}
