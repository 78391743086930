import { DEFAULT_PAGE_SIZE } from '@traba/consts'
import { useHotSettings } from '@traba/hooks'
import { TabPanel, Tab, Tabs, TabsContainer } from '@traba/react-components'
import { JobStatus, Roster } from '@traba/types'
import { shiftIsClientShift } from '@traba/utils'
import { endOfDay, startOfDay } from 'date-fns'
import React, { useState } from 'react'
import { Button, ButtonVariant, Row, SvgIcon, Text } from 'src/components/base'
import { TimeSheetsTable } from 'src/components/TimeSheetsTable/TimeSheetsTable'
import {
  TimeSheetsTableLoggingSource,
  TimeSheetsTableWorkerNameSearchSource,
} from 'src/components/TimeSheetsTable/types'
import { WorkerNameSearch } from 'src/components/WorkerNameSearch/WorkerNameSearch'
import { WorkersOnShiftTable } from 'src/components/WorkersOnShiftTable'
import { useUserContext } from 'src/context/user/UserContext'
import { useCompanyWorkersByIds } from 'src/hooks/useCompanyWorkers'
import { useDebouncedState } from 'src/hooks/useDebouncedState'
import useMobile, { AppScreen } from 'src/hooks/useMobile'
import { usePagination } from 'src/hooks/usePagination'
import { useRoster } from 'src/hooks/useRoster'
import {
  TIMESHEET_WORKER_SHIFT_QUERY_KEY,
  TimeSheetsWorkerShiftResponse,
  useTimeSheetMutations,
  useWorkerShiftsForTimesheetWithPagination,
  WorkerShiftsForTimesheetsProps,
} from 'src/hooks/useTimesheet'
import { useTimesheetForShift } from 'src/hooks/useTimesheetForShift'
import { theme } from 'src/libs/theme'
import { BookShiftsInvitationsModal } from 'src/screens/BookShifts/steps/sections/BookShiftsInvitationsModal'
import {
  EditWorkerSlotsEntryPoint,
  EditWorkerSlotsModal,
} from 'src/screens/EditShifts/EditWorkerSlotsModal'

import { TimesheetsGroupBy, TimesheetsPage } from 'src/screens/TimeSheets/types'
import { useWorkersOnShift } from '../../WorkersOnShiftTable/WorkersOnShiftTable.hooks'
import { UpcomingShiftDetailsProps } from '../UpcomingShiftDetails.hooks'
import { Announcements } from './Announcements'
import { buildWorkerTableCSV } from './export-utils'
import ShareShiftDetails from './ShareShiftDetails'
import { ShiftCostSummary } from './ShiftCostSummary'
import { ShiftInvitationsTable } from './ShiftInvitationsTable'

export const UpcomingShiftSummary: React.FC<UpcomingShiftDetailsProps> = (
  props: UpcomingShiftDetailsProps,
) => {
  const {
    shift,
    expectedCost,
    userCanViewWages,
    userCanManageWorkers,
    editing,
    setEditing,
    updateShift,
    setShiftToUpdate,
    shiftToUpdate,
    userCanManageShift,
    location,
    parkingLocation,
    shiftInvitations,
    rescindInvitation,
    sendInvitations,
    isEditSlotsEnabled,
    isEditWorkerSlotsModalOpen,
    setIsEditWorkerSlotsModalOpen,
    handleUpdateSlotsRequested,
  } = props
  const { hotSettings } = useHotSettings()
  const { workerShifts = [] } = props
  const [currentTab, setCurrentTab] = useState(0)
  const [isInviteAdditionalOpen, setIsInviteAdditionalOpen] = useState(false)
  const [selectedRoster, setSelectedRoster] = useState<Roster | undefined>()
  const [selectedWorkers, setSelectedWorkers] = useState(new Set<string>())

  const { roster } = useRoster(
    selectedRoster?.id || '',
    shift.locationId,
    shift.roleId,
  )
  const userContext = useUserContext()
  const { data: workers } = useCompanyWorkersByIds()
  const { slotsRequested } = shift
  const { isMobileViewOrReactNative } = useMobile()

  const { workersOnShift, workersOnBackup } = useWorkersOnShift({
    isUpcoming: true,
    workerShifts,
    slotsRequested,
  })
  const isPastShift = shift && new Date() > shift.endTime

  const { timesheet } = useTimesheetForShift(shift.id)

  const tableActions = (
    <Button
      onClick={() =>
        buildWorkerTableCSV(
          [...workersOnShift, ...workersOnBackup],
          shift,
          location,
          userContext?.state,
          hotSettings?.enableWorkerEditTime,
        )
      }
      variant={ButtonVariant.OUTLINED}
      title="Download worker roster"
    >
      <SvgIcon name="download" color={theme.colors.Grey50} />
    </Button>
  )

  const doPageLeft = () => {
    window.analytics.track(`Timesheets Page Left Clicked`, {
      pageNumber: page,
      page: TimesheetsPage.DAILY,
      screen: isMobileViewOrReactNative ? AppScreen.MOBILE : AppScreen.DESKTOP,
      source: TimeSheetsTableWorkerNameSearchSource.SHIFT_DETAILS,
    })
    onPageLeft()
  }

  const doPageRight = () => {
    window.analytics.track(`Timesheets Page Right Clicked`, {
      pageNumber: page,
      page: TimesheetsPage.DAILY,
      screen: isMobileViewOrReactNative ? AppScreen.MOBILE : AppScreen.DESKTOP,
      source: TimeSheetsTableWorkerNameSearchSource.SHIFT_DETAILS,
    })
    onPageRight()
  }

  const [firstName, debouncedFirstName, setFirstName] = useDebouncedState(
    '',
    (newState) => {
      window.analytics.track(`Timesheets First Name Search Changed`, {
        firstName: newState,
        page: TimesheetsPage.DAILY,
        source: TimeSheetsTableWorkerNameSearchSource.SHIFT_DETAILS,
      })
    },
  )
  const [lastName, debouncedLastName, setLastName] = useDebouncedState(
    '',
    (newState) => {
      window.analytics.track(`Timesheets Last Name Search Changed`, {
        lastName: newState,
        page: TimesheetsPage.DAILY,
        source: TimeSheetsTableWorkerNameSearchSource.SHIFT_DETAILS,
      })
    },
  )

  const { approveWorkerShifts } = useTimeSheetMutations()
  const groupBy = TimesheetsGroupBy.SHIFT
  const {
    page,
    onPageLeft,
    onPageRight,
    data: response,
    resetPagination,
    isLoading,
  } = usePagination<
    WorkerShiftsForTimesheetsProps,
    TimeSheetsWorkerShiftResponse
  >(
    TIMESHEET_WORKER_SHIFT_QUERY_KEY,
    {
      groupBy,
      after: startOfDay(shift.businessStartTime ?? shift.startTime),
      before: endOfDay(shift.endTime),
      firstName:
        debouncedFirstName.length === 0 ? undefined : debouncedFirstName,
      lastName: debouncedLastName.length === 0 ? undefined : debouncedLastName,
      shiftIds: [shift.id],
    },
    useWorkerShiftsForTimesheetWithPagination,
    DEFAULT_PAGE_SIZE,
    0,
  )
  const data = response?.result

  const TABS = [
    {
      key: 'workers',
      label: 'Workers',
      Component: isPastShift ? (
        <>
          <WorkerNameSearch
            resetPagination={resetPagination}
            setFirstName={setFirstName}
            setLastName={setLastName}
            firstName={firstName}
            lastName={lastName}
            page={page}
            doPageLeft={doPageLeft}
            doPageRight={doPageRight}
            additionalWorkerActions={tableActions}
            response={response}
          />
          <TimeSheetsTable
            groupBy={groupBy}
            data={data}
            isLoading={isLoading}
            refetchData={() => {
              return
            }}
            editMode={false}
            approveWorkerShifts={approveWorkerShifts}
            hideTotalsRow={isMobileViewOrReactNative}
            loggingContext={{
              source: TimeSheetsTableLoggingSource.SHIFT_DETAILS_SCREEN,
              groupBy,
              page: TimesheetsPage.DAILY,
            }}
            isInShiftSummary={true}
          />
        </>
      ) : (
        <WorkersOnShiftTable
          shift={shift}
          workersOnBackup={workersOnBackup}
          isUpcoming={true}
          isReactNativeApp={false}
          additionalWorkerActions={tableActions}
          onClickInviteWorker={() => {
            setIsInviteAdditionalOpen(true)
          }}
          isEditSlotsEnabled={isEditSlotsEnabled}
          onClickEditWorkerSlots={() => {
            setIsEditWorkerSlotsModalOpen(true)
          }}
        />
      ),
    },
    ...(!(shiftIsClientShift(shift) && shift.hideInvitations)
      ? [
          {
            key: 'shift-invitations',
            label: 'Shift Invitations',
            Component: (
              <ShiftInvitationsTable
                shift={shift}
                shiftInvitations={shiftInvitations}
                canceledWorkerShifts={workerShifts.filter(
                  (ws) => ws.jobStatus === JobStatus.Canceled,
                )}
                rescindInvitation={rescindInvitation}
                onClickInviteWorker={() => {
                  setIsInviteAdditionalOpen(true)
                }}
              />
            ),
          },
        ]
      : []),
    ...(userCanManageWorkers &&
    !(shiftIsClientShift(shift) && shift.hideAnnouncements)
      ? [
          {
            key: 'announcements',
            label: 'Announcements',

            Component: (
              <Announcements
                shiftId={shift.id}
                shift={shift}
                location={location}
                parkingLocation={parkingLocation}
              />
            ),
          },
        ]
      : []),
    ...(userCanViewWages
      ? [
          {
            key: 'estimated-cost',
            label: 'Estimated Cost',
            Component: (
              <ShiftCostSummary
                workerShifts={workerShifts}
                expectedCost={expectedCost}
                shift={shift}
                timesheet={timesheet}
              />
            ),
          },
        ]
      : []),
    ...(userCanManageShift
      ? [
          {
            key: 'share-shift-details',
            label: 'Share Shift Details',
            Component: (
              <ShareShiftDetails
                editing={editing}
                setEditing={setEditing}
                updateShift={updateShift}
                setShiftToUpdate={setShiftToUpdate}
                shiftToUpdate={shiftToUpdate}
                shift={shift}
              />
            ),
          },
        ]
      : []),
  ]

  const onClickWorker = (workerId: string) => {
    const newWorkers = new Set(selectedWorkers)
    if (selectedWorkers.has(workerId)) {
      newWorkers.delete(workerId)
    } else {
      newWorkers.add(workerId)
    }
    setSelectedWorkers(newWorkers)
  }

  const sendInvitationsToWorkers = async () => {
    sendInvitations({
      shiftId: shift.shiftId,
      workerIds: Array.from(selectedWorkers),
      includeRescinded: true,
    })
    setIsInviteAdditionalOpen(false)
    const idx = TABS.findIndex((tab) => tab.key === 'shift-invitations')
    setCurrentTab(idx)
  }

  return (
    <>
      <TabsContainer>
        <Tabs
          variant="scrollable"
          value={currentTab}
          onChange={(_, v) => setCurrentTab(v)}
        >
          {TABS.map((t, index) => (
            <Tab
              label={
                <Row>
                  <Text
                    variant="h5"
                    color={
                      currentTab === index
                        ? theme.colors.MidnightBlue
                        : theme.colors.Grey60
                    }
                  >
                    {t.label}
                  </Text>
                </Row>
              }
            />
          ))}
        </Tabs>
      </TabsContainer>
      {TABS.map(({ Component }, i) => (
        <TabPanel value={currentTab} index={i}>
          {Component}
        </TabPanel>
      ))}
      <BookShiftsInvitationsModal
        isOpen={isInviteAdditionalOpen}
        setIsOpen={setIsInviteAdditionalOpen}
        selectedRoster={selectedRoster}
        setSelectedRoster={setSelectedRoster}
        selectedWorkers={selectedWorkers}
        setSelectedWorkers={setSelectedWorkers}
        onConfirm={sendInvitationsToWorkers}
        roster={roster}
        disabledWorkerIdsSet={
          new Set(shiftInvitations?.map((invitation) => invitation.workerId))
        }
        onClickWorker={onClickWorker}
        locationId={shift.locationId}
        roleId={shift.roleId}
        shiftId={shift.shiftId}
      />
      <EditWorkerSlotsModal
        isOpen={isEditWorkerSlotsModalOpen}
        setIsOpen={setIsEditWorkerSlotsModalOpen}
        onClose={() => setIsEditWorkerSlotsModalOpen(false)}
        shift={shift}
        workerShifts={workersOnShift.filter(
          // Filter out canceled worker shifts from list of workers to remove
          (ws) => ws.jobStatus !== JobStatus.Canceled,
        )}
        workersOnBackup={workersOnBackup.filter(
          (ws) => ws.jobStatus !== JobStatus.Canceled,
        )}
        companyWorkers={workers}
        onClickInviteWorker={() => {
          setIsInviteAdditionalOpen(true)
        }}
        handleUpdateSlotsRequested={handleUpdateSlotsRequested}
        entryPoint={EditWorkerSlotsEntryPoint.WORKERS_ON_SHIFT_TABLE}
      />
    </>
  )
}
