import { Tab, TabPanel, Tabs, TabsContainer } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { endOfDay, startOfDay, subDays } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { Col, Row, Text } from 'src/components/base'
import DateRangePicker from 'src/components/base/AriaDatePicker/DateRangePicker'
import { RegionalFilterButton } from 'src/components/base/RegionalFilterSelect/RegionalFilterButton'
import { useAppContext } from 'src/context/appContext/AppContext'
import { useLocations } from 'src/hooks/useLocations'
import useMobile from 'src/hooks/useMobile'
import { useTimesheetSummary } from 'src/hooks/useTimesheet'
import { LocationTilesList } from './components/LocationTilesList'
import { TimesheetApprovalTab, TimesheetsPage } from './types'

interface TimeSheetApprovalProps {
  tabIndex: number
  handleChangeTabs: (
    ev: React.SyntheticEvent<Element, Event>,
    val: number,
  ) => void
  userCanManageTimesheets: boolean
}

export const TimeSheetApprovalLayout = ({
  tabIndex,
  handleChangeTabs,
  userCanManageTimesheets,
}: TimeSheetApprovalProps) => {
  const [timePeriod, setTimePeriod] = useState<[Date | null, Date | null]>([
    subDays(new Date(), 7),
    new Date(),
  ])
  const {
    state: { regionalFilter },
  } = useAppContext()
  const { isMobileViewOrReactNative, isReactNativeApp } = useMobile()

  const isPendingTab = tabIndex === 0

  const activeLocationIds = regionalFilter
    ? Object.values(regionalFilter || {}).flat()
    : undefined

  const {
    data: summary,
    isLoading,
    refetch: refetchSummary,
  } = useTimesheetSummary({
    after: timePeriod[0] ? startOfDay(timePeriod[0]) : undefined,
    before: timePeriod[1] ? endOfDay(timePeriod[1]) : undefined,
    activeLocationIds,
  })
  const { getLocationById } = useLocations()
  const locationIds = useMemo(
    () =>
      Array.from(
        new Set(summary?.shifts.map((shift) => shift.activeLocationId)),
      ),
    [summary],
  )
  const totalUnapprovedWorkerShifts = useMemo(
    () =>
      summary?.shifts.reduce(
        (sum, shift) =>
          sum + (shift.numWorkerShifts - shift.numApprovedWorkerShifts),
        0,
      ),
    [summary],
  )
  const totalApprovedWorkerShifts = useMemo(
    () =>
      summary?.shifts.reduce(
        (sum, shift) => sum + shift.numApprovedWorkerShifts,
        0,
      ),
    [summary],
  )

  const description = `Quickly approve timesheets for a time period, or view already approved times. All the shifts at a location are grouped together for your convenience. 
        To view all the times in a time period together, navigate to the totals view. `

  const titleString = isPendingTab ? `To Approve` : `Approved`
  const countString = isPendingTab
    ? `${totalUnapprovedWorkerShifts}`
    : `${totalApprovedWorkerShifts}`

  return (
    <>
      {!isMobileViewOrReactNative && (
        <Row justifyBetween mt={16} mb={40}>
          <Col className="xs-12 xl-8">
            <Text variant="body2">{description}</Text>
          </Col>
        </Row>
      )}

      <div
        style={{
          ...(isReactNativeApp
            ? { paddingLeft: theme.space.sm, paddingRight: theme.space.sm }
            : {}),
        }}
      >
        <DateRangePicker
          dateRange={timePeriod}
          setDateRange={(dateRange) => {
            setTimePeriod(dateRange)
            window.analytics.track('Timesheets Time Period Changed', {
              dateRange,
              page: TimesheetsPage.APPROVAL,
              tab: isPendingTab
                ? TimesheetApprovalTab.PENDING
                : TimesheetApprovalTab.APPROVED,
            })
          }}
          maxDate={new Date()}
          granularity="day"
          label={'Time period'}
          isClearable={false}
        />
        {isReactNativeApp && (
          <Row
            style={{
              marginTop: theme.space.xs,
              marginBottom: theme.space.zero,
            }}
          >
            <RegionalFilterButton />
          </Row>
        )}
      </div>
      <TabsContainer
        style={{
          marginTop: isMobileViewOrReactNative ? 0 : undefined,
        }}
      >
        <Tabs
          variant="scrollable"
          value={tabIndex}
          onChange={handleChangeTabs}
          isReactNative={isMobileViewOrReactNative}
          style={{ width: '100%' }}
        >
          <Tab
            label={
              <Row>{`Pending${isMobileViewOrReactNative && !!totalUnapprovedWorkerShifts ? ` (${totalUnapprovedWorkerShifts})` : ''}`}</Row>
            }
            isReactNative={isMobileViewOrReactNative}
            style={{ alignItems: 'center' }}
          />
          <Tab
            label={
              <Row>{`Approved${isMobileViewOrReactNative && !!totalApprovedWorkerShifts ? ` (${totalApprovedWorkerShifts})` : ''}`}</Row>
            }
            isReactNative={isMobileViewOrReactNative}
            style={{ alignItems: 'center' }}
          />
        </Tabs>
      </TabsContainer>

      {!isMobileViewOrReactNative && (
        <>
          <Row mt={theme.space.sm}>
            <Text variant="h4">{`${titleString} ${summary !== undefined ? `(${countString})` : ''}`}</Text>
          </Row>
          {!isLoading && totalUnapprovedWorkerShifts !== undefined && (
            <Row>
              <Text variant="body1">{`${countString} worker time${makePlural(tabIndex == 0 ? totalUnapprovedWorkerShifts : (totalApprovedWorkerShifts ?? 0))} ${tabIndex === 0 ? 'to approve' : 'approved'} from this time period`}</Text>
            </Row>
          )}
        </>
      )}

      {/* *****************  */}
      {/* Pending Timesheets */}
      {/* *****************  */}
      <TabPanel value={tabIndex} index={0}>
        {summary && locationIds && (
          <LocationTilesList
            locationIds={locationIds}
            summary={summary}
            getLocationById={getLocationById}
            tabIndex={tabIndex}
            timePeriod={timePeriod}
            refetchSummary={refetchSummary}
            isMobileViewOrReactNative={isMobileViewOrReactNative}
            userCanManageTimesheets={userCanManageTimesheets}
          />
        )}
      </TabPanel>

      {/* ******************  */}
      {/* Approved Timesheets */}
      {/* ******************  */}
      <TabPanel value={tabIndex} index={1}>
        {summary && locationIds && (
          <LocationTilesList
            locationIds={locationIds}
            summary={summary}
            getLocationById={getLocationById}
            tabIndex={tabIndex}
            timePeriod={timePeriod}
            refetchSummary={refetchSummary}
            isMobileViewOrReactNative={isMobileViewOrReactNative}
            userCanManageTimesheets={userCanManageTimesheets}
          />
        )}
      </TabPanel>
    </>
  )
}
