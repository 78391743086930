import { useAlert } from '@traba/context'
import {
  CardTile,
  LoadingSpinner,
  MODAL_SIZE,
  StarRating,
  Tab,
  TabPanel,
  Tabs,
  TabsContainer,
} from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { format } from 'date-fns'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from 'src/context/user/UserContext'
import {
  useBehaviorAttributes,
  useRoleAttributes,
} from 'src/hooks/useAttributes'
import {
  useCompanyWorkersByIds,
  WorkerDetails as WorkerDetailsType,
} from 'src/hooks/useCompanyWorkers'
import { useConnections } from 'src/hooks/useConnections'
import useMobile from 'src/hooks/useMobile'
import { useMostRecentWorkerReview } from 'src/hooks/useMostRecentReview'
import { useShifts } from 'src/hooks/useShifts'
import { useHotSettings } from 'src/hooks/useSystem'
import { useWorkerAccountStatus } from 'src/hooks/useWorkerAccountStatus'
import { useWorkerHistoryWithMe } from 'src/hooks/useWorkerHistoryWithMyCompany'
import { useWorkerProfileCertsRosters } from 'src/hooks/useWorkerProfile'
import { useWorkerRegion } from 'src/hooks/useWorkerRegion'
import { useWorkerScores } from 'src/hooks/useWorkerScores'
import { useWorkerWorkHistory } from 'src/hooks/useWorkerWorkHistory'
import { theme } from 'src/libs/theme'

import { ConnectionType, JobStatus, UserRolePermission } from 'src/types'
import { getInitials } from 'src/utils/stringUtils'
import { hasPermissions } from 'src/utils/userUtils'

import { Col, Image, Row, SvgIcon, Text } from '../base'
import { Modal } from '../base/Modal/Modal'
import { HiredStatusBadge } from '../base/WorkerBadge/HiredStatusBadge'
import {
  RemoveConnectionConfirmationModal,
  RemoveConnectionConfirmationModalProps,
} from '../RemoveConnectionConfirmationModal/RemoveConnectionConfirmationModal'
import { ReviewWorkerModalContent } from '../ReviewWorker/components/ReviewWorkerModalContent.legacy'
import { ShiftCalendar } from '../ShiftCalendar'
import WorkerConnectionMenu, {
  WorkerConnectionActionButton,
} from '../WorkerConnectionMenu/WorkerConnectionMenu'
import { InvitedShiftsTab } from './InvitedShiftsTab'
import { PastShiftsWithYou } from './PastShiftsWithYou'
import { WorkerLinkText } from './WorkerLinkText'
import { WorkProfile } from './WorkProfile'

function Header({
  photoUrl,
  firstName,
  lastName,
  workerDetails,
  setReviewModal,
}: {
  photoUrl?: string
  firstName: string
  lastName: string
  workerDetails: WorkerDetailsType
  setReviewModal: (open: boolean) => void
}) {
  const { isMobileViewOrReactNative } = useMobile()

  const userContext = useUserContext()
  const userCanManageWorkers = hasPermissions(userContext.state.userProfile, [
    UserRolePermission.ManageWorkers,
  ])
  const connections = useConnections()
  const [removeConnectionModal, setRemoveConnectionModal] =
    useState<Partial<RemoveConnectionConfirmationModalProps | undefined>>()
  const { worker } = workerDetails || {}
  const isBlocked = worker && connections.isBlocked(worker.uid)
  const isFavorite = worker && connections.isFavorite(worker.uid)
  const isScheduledBlock = worker && connections.isScheduledBlock(worker.uid)
  const initials = getInitials(firstName, lastName)

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
        }}
      >
        {photoUrl ? (
          <Image
            style={{
              width: '56px',
              height: '56px',
              borderRadius: theme.space.xxs,
            }}
            src={photoUrl}
            alt="worker-photo"
            expandable={true}
          />
        ) : (
          <CardTile size="56px" style={{ minWidth: 56 }}>
            <Text variant="h5">{initials}</Text>
          </CardTile>
        )}
      </div>

      <Row justifyBetween style={{ padding: `${theme.space.xs}px 0px` }}>
        <Row alignCenter>
          <Text variant="h2">
            {firstName} {lastName}
          </Text>
          <HiredStatusBadge workerId={worker?.workerId || worker?.uid} />
        </Row>
        {!isMobileViewOrReactNative && (
          <div>
            {workerDetails?.shiftHistory.mostRecentShift && (
              <WorkerConnectionMenu
                hidden={!(userCanManageWorkers && worker)}
                worker={worker}
                isFavorited={!!isFavorite}
                isBlocked={!!isBlocked}
                isIneligible={connections.isIneligible(worker?.uid)}
                isScheduledBlock={!!isScheduledBlock}
                onFavorite={() => {
                  worker && connections.favoriteWorker(worker.uid)
                  setReviewModal(true)
                }}
                workerShift={workerDetails?.shiftHistory.mostRecentShift}
                buttonsToShow={[
                  WorkerConnectionActionButton.AddToRoster,
                  WorkerConnectionActionButton.Block,
                  WorkerConnectionActionButton.DownloadResume,
                  WorkerConnectionActionButton.Favorite,
                  WorkerConnectionActionButton.MessageWorker,
                ]}
              />
            )}
          </div>
        )}
      </Row>
      {isMobileViewOrReactNative && (
        <Row pb={theme.space.sm}>
          <div>
            {workerDetails?.shiftHistory.mostRecentShift && (
              <WorkerConnectionMenu
                hidden={!(userCanManageWorkers && worker)}
                worker={worker}
                isFavorited={!!isFavorite}
                isBlocked={!!isBlocked}
                isIneligible={connections.isIneligible(worker?.uid)}
                isScheduledBlock={!!isScheduledBlock}
                onFavorite={() => {
                  worker && connections.favoriteWorker(worker.uid)
                  setReviewModal(true)
                }}
                workerShift={workerDetails?.shiftHistory.mostRecentShift}
                buttonsToShow={[
                  WorkerConnectionActionButton.AddToRoster,
                  WorkerConnectionActionButton.Block,
                  WorkerConnectionActionButton.Favorite,
                  WorkerConnectionActionButton.MessageWorker,
                ]}
              />
            )}
          </div>
        </Row>
      )}
      <RemoveConnectionConfirmationModal
        isOpen={!!removeConnectionModal}
        handleClose={() => setRemoveConnectionModal(undefined)}
        {...removeConnectionModal}
      />
    </>
  )
}

export default function WorkerDetails(props: { workerId: string }) {
  const navigate = useNavigate()
  const profileCertsRosters = useWorkerProfileCertsRosters(props.workerId)
  const {
    worker,
    isLoading: profilesCertsRostersAreLoading,
    errorWorker,
    errorProfile,
  } = profileCertsRosters
  const rosters =
    profileCertsRosters.rosters?.sort((a, b) =>
      a.rosterName.localeCompare(b.rosterName),
    ) || []
  const {
    data: workersDetailsList = [],
    error: companyWorkerError,
    isLoading: workerIsLoading,
  } = useCompanyWorkersByIds({ workerIds: [props.workerId] })
  const {
    historyWithMe,
    isLoading: historyIsLoading,
    error: historyError,
  } = useWorkerHistoryWithMe(props.workerId)
  const {
    refetch: refetchMostRecentReview,
    mostRecentReview,
    isLoading: reviewIsLoading,
    error: reviewError,
  } = useMostRecentWorkerReview(props.workerId)
  const [reviewModal, setReviewModal] = useState(false)
  const {
    data: upcomingShift,
    isLoading: shiftIsLoading,
    error: shiftsError,
  } = useShifts({
    workerId: props.workerId,
    workerShiftStatuses: [JobStatus.ToDo],
    limit: 1,
  })

  const [errorShown, setErrorShown] = useState(false)
  const { handleError } = useAlert()
  useEffect(() => {
    const error =
      errorWorker ||
      errorProfile ||
      historyError ||
      reviewError ||
      shiftsError ||
      companyWorkerError
    if (!errorShown && error) {
      handleError(
        error,
        'WorkerDetailsScreen',
        'Please try again. Contact support if the issue persists.',
        'Error',
      )
      setErrorShown(true)
    }
  }, [
    errorShown,
    errorWorker,
    errorProfile,
    historyError,
    reviewError,
    shiftsError,
    setErrorShown,
    handleError,
    companyWorkerError,
  ])

  // prefetch
  const { refetch: refetchScores } = useWorkerScores(props.workerId)
  useWorkerRegion(props.workerId)
  useRoleAttributes()
  useBehaviorAttributes()
  useWorkerWorkHistory(props.workerId)
  useHotSettings()
  useWorkerAccountStatus(props.workerId)

  const workerDetails =
    workersDetailsList && workersDetailsList.length > 0
      ? workersDetailsList[0]
      : undefined
  const [currentTab, setCurrentTab] = useState(0)

  if (
    !historyWithMe ||
    historyIsLoading ||
    workerIsLoading ||
    !worker ||
    !rosters ||
    !mostRecentReview ||
    reviewIsLoading ||
    shiftIsLoading ||
    !upcomingShift ||
    profilesCertsRostersAreLoading ||
    !workerDetails
  ) {
    return <LoadingSpinner />
  }

  function handleReviewModalClose() {
    setReviewModal(false)
    refetchMostRecentReview()
    refetchScores()
  }

  const tabs = [
    {
      key: 'work_profile',
      label: 'Work Profile',
      component: <WorkProfile workerId={props.workerId} />,
    },
    ...(upcomingShift.length > 0
      ? [
          {
            key: 'upcoming_shifts_with_you',
            label: 'Current & Upcoming shifts with you',
            component: (
              <ShiftCalendar
                after={new Date()}
                workerId={props.workerId}
                cacheKey={`upcoming_shifts_${props.workerId}`}
                hideWorkers={true}
                hideFilters={true}
                sectionTitleTextVariant="h4"
              />
            ),
          },
        ]
      : []),
    ...(historyWithMe.shiftsWithCompany > 0
      ? [
          {
            key: 'past_shifts_with_you',
            label: 'Past shifts with you',
            component: (
              <PastShiftsWithYou
                numPastShifts={historyWithMe.shiftsWithCompany}
                workerId={props.workerId}
              />
            ),
          },
        ]
      : []),
    {
      key: 'shift_invites',
      label: 'Shift Invites',
      component: <InvitedShiftsTab workerId={props.workerId} />,
    },
  ]

  return (
    <>
      <Header
        photoUrl={worker.photoUrl}
        firstName={worker.firstName}
        lastName={worker.lastName}
        workerDetails={workerDetails}
        setReviewModal={setReviewModal}
      />
      {workerDetails.shiftHistory.totalShiftCount > 0 &&
      workerDetails.shiftHistory.mostRecentShift?.shiftInfo.startTime ? (
        <Col>
          <Text style={{ marginBottom: theme.space.xxs }} variant="h5">
            Metrics with you
          </Text>
          <Row
            style={{
              justifyItems: 'center',
            }}
            onClick={() => {
              setReviewModal(true)
            }}
          >
            {mostRecentReview.mostRecentReview?.rating ? (
              <Text
                style={{ marginRight: theme.space.xxs, fontWeight: 'bold' }}
              >
                {mostRecentReview.mostRecentReview.rating.toFixed(1)}
              </Text>
            ) : undefined}

            <StarRating
              hideLabel={true}
              value={mostRecentReview.mostRecentReview?.rating || null}
              id={`most-recent-review_${props.workerId}`}
              size={16}
              readOnly={true}
            />
            <SvgIcon name="edit" />
          </Row>
          <Text>
            <span style={{ fontWeight: 'bold' }}>
              {workerDetails.shiftHistory.totalShiftCount}
            </span>{' '}
            shifts with you •{' '}
            <span style={{ fontWeight: 'bold' }}>
              {(
                (workerDetails.shiftHistory.totalMinutesWorked || 0) / 60
              ).toFixed(0)}
            </span>{' '}
            hrs • last worked with you on{' '}
            {format(
              workerDetails.shiftHistory.mostRecentShift.shiftInfo.startTime,
              'MM/dd/yyyy',
            )}
          </Text>
        </Col>
      ) : undefined}
      {!!rosters?.length && (
        <Row>
          <Text>
            On your{' '}
            {rosters.map((roster, i) => (
              <React.Fragment key={roster.id}>
                <WorkerLinkText
                  color={theme.colors.MidnightBlue}
                  variant="link"
                  onClick={() => navigate(`/roster/${roster.id}`)}
                >
                  {roster.rosterName}
                </WorkerLinkText>
                <span>
                  {rosters.length > 1
                    ? i === rosters.length - 1
                      ? ''
                      : i === rosters.length - 2
                        ? ' and '
                        : ', '
                    : ''}
                </span>
              </React.Fragment>
            ))}
            {` roster${makePlural(rosters)}.`}
          </Text>
        </Row>
      )}
      {tabs.length > 1 ? (
        <>
          <TabsContainer>
            <Tabs
              variant="scrollable"
              value={currentTab}
              onChange={(_, v) => setCurrentTab(v)}
            >
              {tabs.map((tab, index) => (
                <Tab
                  label={
                    <Row>
                      <Text
                        variant="h5"
                        color={
                          currentTab === index
                            ? theme.colors.MidnightBlue
                            : theme.colors.Grey60
                        }
                      >
                        {tab.label}
                      </Text>
                    </Row>
                  }
                  key={tab.label}
                />
              ))}
            </Tabs>
          </TabsContainer>
          {tabs.map(({ component }, i) => (
            <TabPanel value={currentTab} index={i} key={`tab_${i}`}>
              {component}
            </TabPanel>
          ))}
        </>
      ) : (
        tabs[0].component
      )}
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={!!reviewModal}
        handleClose={handleReviewModalClose}
      >
        <ReviewWorkerModalContent
          onClose={handleReviewModalClose}
          variation={ConnectionType.FAVORITE}
          worker={worker}
          workerShift={workerDetails?.shiftHistory.mostRecentShift}
        />
      </Modal>
    </>
  )
}
