import { Tooltip } from '@mui/material'
import { Button, LoadingSpinner, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import { recurringSchedulesEnabled } from '@traba/utils'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  ButtonVariant,
  EmptyPlaceholderTile,
  MainLayout,
  Row,
} from 'src/components'
import { EmptyDataLocationSubtitle } from 'src/components/base/RegionalFilterSelect/EmptyDataLocationSubtitle'
import { RegionalFilterButton } from 'src/components/base/RegionalFilterSelect/RegionalFilterButton'
import {
  RegionalFilterStatus,
  useAppContext,
} from 'src/context/appContext/AppContext'
import { useCompany } from 'src/hooks/useCompany'
import useMobile from 'src/hooks/useMobile'
import { useShiftRequestParents } from 'src/hooks/useShiftRequestParents'
import { useHotSettings } from 'src/hooks/useSystem'
import { SchedulesList } from './SchedulesList'

const SchedulesScreen = () => {
  const { dispatch: appContextDispatch } = useAppContext()
  const navigate = useNavigate()
  const { hotSettings } = useHotSettings()
  const { company } = useCompany()
  const { isMobileViewOrReactNative, isReactNativeApp } = useMobile()
  const { isLoading: isLoadingShiftRequestParents, shiftRequestParents } =
    useShiftRequestParents({ includeCompletedSchedules: false })

  useEffect(() => {
    appContextDispatch({
      type: 'SET_REGIONAL_FILTER_STATUS',
      value: RegionalFilterStatus.ENABLED,
    })
    return () => {
      appContextDispatch({
        type: 'SET_REGIONAL_FILTER_STATUS',
        value: RegionalFilterStatus.HIDE,
      })
    }
  }, [appContextDispatch])

  useEffect(() => {
    if (
      !recurringSchedulesEnabled({
        companyId: company?.companyId,
        hotSettings,
      })
    ) {
      navigate('/dashboard')
    }
  }, [company, hotSettings, navigate])

  if (isLoadingShiftRequestParents) {
    return <LoadingSpinner />
  }

  return (
    <MainLayout title="Schedules">
      <div
        style={{ marginTop: isMobileViewOrReactNative ? 0 : theme.space.xs }}
      >
        <Row
          justifyBetween
          alignCenter
          mb={theme.space.med}
          style={{
            columnGap: theme.space.xxs,
            marginBottom: isMobileViewOrReactNative
              ? theme.space.xs
              : undefined,
          }}
        >
          <Text variant="h2">Schedules</Text>
          <Tooltip title="Create schedule">
            <Button
              variant={ButtonVariant.FILLED}
              style={{ flexShrink: 0 }}
              onClick={() =>
                navigate(
                  recurringSchedulesEnabled({
                    hotSettings,
                    companyId: company?.companyId,
                  })
                    ? '/new'
                    : '/book-shifts',
                )
              }
            >
              Create a schedule
            </Button>
          </Tooltip>
        </Row>

        {isReactNativeApp && (
          <Row
            style={{
              marginLeft: theme.space.zero,
              marginBottom: theme.space.xxs,
            }}
          >
            <RegionalFilterButton />
          </Row>
        )}
        {shiftRequestParents && shiftRequestParents.length > 0 ? (
          <SchedulesList shiftRequestParents={shiftRequestParents} />
        ) : (
          <EmptyPlaceholderTile
            title="You have no schedules in selected locations"
            subtitle={
              <EmptyDataLocationSubtitle
                isMobileView={isMobileViewOrReactNative}
                extraText="to see schedules in those locations, or book a new shift."
              />
            }
            iconName="folder"
          />
        )}
      </div>
    </MainLayout>
  )
}

export default SchedulesScreen
