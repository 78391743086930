import { Tooltip } from '@mui/material'
import { BIZ_REFERRAL_AMOUNT } from '@traba/consts'
import { SvgIcon } from '@traba/react-components'
import { IconName, ShiftStatus } from '@traba/types'
import { recurringSchedulesEnabled } from '@traba/utils'
import { useState } from 'react'
import { Location, useLocation, useSearchParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { SidebarIconName } from 'src/assets/svg/icons'
import {
  Badge,
  Button,
  ButtonVariant,
  CompanyLogo,
  Icon,
  Row,
  Text,
} from 'src/components/base'
import { useAppContext } from 'src/context/appContext/AppContext'
import { useUserContext } from 'src/context/user/UserContext'
import { useChats } from 'src/hooks/useChats'
import { useCompany } from 'src/hooks/useCompany'
import useMobile from 'src/hooks/useMobile'
import { useShifts } from 'src/hooks/useShifts'
import { useShouldShowSearch } from 'src/hooks/useShouldShowSearch'
import { useHotSettings } from 'src/hooks/useSystem'
import { useUserPermissions } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { UserRolePermission } from 'src/types'
import { hasPermissions } from 'src/utils/userUtils'

import { ChevronDown, ChevronDownWrapper } from '../Header/Header.styles'
import * as S from './Sidebar.styles'
import { SidebarCurrentShiftsAccordion } from './SidebarCurrentShiftsAccordion'

export const BookShiftButtonText = 'Book a shift'

type SidebarOption = {
  route: string
  icon: SidebarIconName | IconName
  useSvgIcon?: boolean
  iconSize?: number
  label: string
  permissions: UserRolePermission[]
  badgeTitle?: string
  hidden?: boolean
  subOptions?: SidebarOption[]
}

const SidebarOptionComponent = ({
  option,
  onClick,
  subOptions,
  location,
  isSubOption,
}: {
  option: SidebarOption
  subOptions: SidebarOption[]
  onClick: () => void
  location: Location
  isSubOption?: boolean
}) => {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const [isExpanded, setIsExpanded] = useState<boolean>(true)
  const [searchParams] = useSearchParams()

  if (option.hidden) {
    return undefined
  }

  const [optionPath, queryString] = option.route.split('?')
  const matchesRoute = location.pathname.startsWith(optionPath)
  const optionQueryParams = new URLSearchParams(queryString)
  const firstOptionQueryParam = Array.from(optionQueryParams)[0]
  const matchesQuery = firstOptionQueryParam
    ? searchParams.get(firstOptionQueryParam[0]) === firstOptionQueryParam[1]
    : true // match only the first query param

  const isActive =
    (!subOptions.length && matchesRoute && matchesQuery) || isHovered

  return (
    <>
      <S.Link
        key={option.route}
        $isActive={isActive}
        to={option.route}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={(event) => {
          if (subOptions.length > 0) {
            event.preventDefault()
            setIsExpanded((isExpanded) => !isExpanded)
          }
          onClick()
        }}
        style={isSubOption ? { paddingLeft: theme.space.lg } : {}}
      >
        {option.useSvgIcon ? (
          <SvgIcon
            name={option.icon as IconName}
            color={isHovered ? theme.ActiveHoverColor : theme.colors.Grey50}
            style={{ marginRight: theme.space.xs }}
            size={24}
          />
        ) : (
          <Icon
            name={
              `${option.icon}${isActive || isHovered ? '_active' : ''}` as SidebarIconName
            }
          />
        )}
        {option.label}
        {option.badgeTitle && (
          <Badge
            title={option.badgeTitle}
            variant="brand"
            style={{ marginLeft: theme.space.xxs }}
          />
        )}
        {subOptions.length > 0 && (
          <ChevronDownWrapper
            id={`${option.route}-chevron`}
            style={{ paddingLeft: theme.space.xs }}
          >
            <ChevronDown
              $isActive={isExpanded}
              color={theme.colors.MidnightBlue}
            />
          </ChevronDownWrapper>
        )}
      </S.Link>
      {isExpanded &&
        subOptions.length > 0 &&
        subOptions.map((option) => (
          <SidebarOptionComponent
            key={`biz-app-sidebar-option-${option.route}`}
            option={option}
            onClick={onClick}
            location={location}
            subOptions={[]}
            isSubOption={true}
          />
        ))}
    </>
  )
}
export function Sidebar() {
  const appContext = useAppContext()
  const userContext = useUserContext()
  const { setIsSideBarOpen } = appContext
  const { unReadChats } = useChats()

  const currentTime = new Date()
  const location = useLocation()
  const navigate = useNavigate()
  const { company } = useCompany()
  const { showSearch } = useShouldShowSearch()
  const { data: currentNonCancelledShifts = [] } = useShifts({
    startBefore: currentTime,
    endAfter: currentTime,
    // Don't show cancelled shifts
    status: ShiftStatus.ACTIVE,
  })

  const userCanCreateShift = useUserPermissions([
    UserRolePermission.ManageShifts,
  ])

  const { hotSettings } = useHotSettings()

  const shouldFreezeShiftPosting = hotSettings?.freezeShiftsPosting
  const { isMobileViewOrReactNative, isExclusivelyMobileView } = useMobile()

  const sidebarOptions: SidebarOption[] = [
    {
      route: '/dashboard',
      icon: 'dashboard',
      label: 'Dashboard',
      permissions: [UserRolePermission.ViewShifts],
    },
    {
      route: '/schedules',
      icon: 'scheduled',
      label: 'Schedules',
      permissions: [UserRolePermission.ViewShifts],
    },
    {
      route: '/calendar',
      icon: 'calendar',
      label: 'Calendar',
      permissions: [UserRolePermission.ViewShifts],
    },
    {
      route: '/messaging',
      icon:
        unReadChats && unReadChats.length > 0 ? 'unread_message' : 'message',
      label: 'Messaging',
      permissions: [UserRolePermission.ViewWorkers],
    },
    {
      route: '/workers',
      icon: 'workers',
      label: 'Workers',
      permissions: [UserRolePermission.ViewWorkers],
    },
    {
      route: '/find-new-workers',
      icon: 'find_workers' as SidebarIconName,
      label: 'Find new workers',
      permissions: [UserRolePermission.ViewWorkers],
      hidden: !showSearch,
    },
    {
      route: '/timesheet/approval',
      icon: 'timesheet',
      label: 'Timesheets',
      permissions: [UserRolePermission.ViewTimesheets],
      subOptions: [
        {
          route: '/timesheet/approval',
          icon: 'circleCheck',
          useSvgIcon: true,
          label: 'Quick Approval',
          permissions: [UserRolePermission.ViewTimesheets],
        },
        {
          route: '/timesheet/totals?view=totals',
          icon: 'checklist',
          useSvgIcon: true,
          iconSize: 28,
          label: 'Totals View',
          permissions: [UserRolePermission.ViewTimesheets],
        },
        {
          route: '/timesheet/totals?view=daily',
          icon: 'calendarDay',
          useSvgIcon: true,
          label: 'Daily View',
          permissions: [UserRolePermission.ViewTimesheets],
        },
      ],
    },
    {
      route: '/billing',
      icon: 'billing',
      label: 'Invoices',
      permissions: [UserRolePermission.ViewInvoices],
    },
  ]

  const bottomOptions: SidebarOption[] = [
    {
      route: '/refer',
      icon: 'refer',
      label: `Refer & Earn $${BIZ_REFERRAL_AMOUNT}`,
      permissions: [UserRolePermission.ViewShifts], // lowest level permission
    },
    {
      route: '/account-settings',
      icon: 'accountSettings',
      label: 'Account Settings',
      permissions: [UserRolePermission.ViewCompanySettings],
    },
  ]

  const sidebarBadge = hotSettings?.sidebarBadge
  if (sidebarBadge && Date.now() < sidebarBadge.expirationTimestamp) {
    const optionToUpdate = sidebarOptions.find(
      (option) => option.label === sidebarBadge.optionName,
    )
    if (optionToUpdate) {
      optionToUpdate.badgeTitle = 'New'
    }
  }

  return (
    <S.SidebarWrapper>
      {isMobileViewOrReactNative && (
        <Row alignCenter pb={theme.space.xs}>
          <CompanyLogo
            style={{ width: 32, height: 32 }}
            companyName={company?.employerName}
            companyLogoUrl={company?.companyLogo}
          />
          <div style={{ marginLeft: theme.space.xs }}>
            <Text variant="h5">{company?.employerName}</Text>
          </div>
        </Row>
      )}

      {currentNonCancelledShifts.length > 0 && (
        <Row alignCenter pb={theme.space.xs}>
          <SidebarCurrentShiftsAccordion
            setIsSideBarOpen={setIsSideBarOpen}
            currentShifts={currentNonCancelledShifts}
          />
        </Row>
      )}

      {userCanCreateShift && (
        <Row alignCenter>
          <Tooltip
            title={
              shouldFreezeShiftPosting
                ? `Shift posting temporarily disabled`
                : ''
            }
          >
            <Button
              variant={ButtonVariant.FILLED}
              style={{ flexGrow: 1 }}
              trackingEvent={{
                message: 'User Clicked Book Shifts',
                properties: {
                  source: 'Sidebar',
                },
              }}
              onClick={() =>
                navigate(
                  recurringSchedulesEnabled({
                    hotSettings,
                    companyId: company?.companyId,
                  })
                    ? '/new'
                    : '/book-shifts',
                )
              }
              disabled={shouldFreezeShiftPosting}
            >
              {BookShiftButtonText}
            </Button>
          </Tooltip>
        </Row>
      )}

      <S.SidebarLinks>
        {sidebarOptions
          .filter((option) => {
            // Don't show schedules page on sidebar if hot setting is off
            if (
              option.route === '/schedules' &&
              !recurringSchedulesEnabled({
                companyId: company?.companyId,
                hotSettings,
              })
            ) {
              return false
            }

            return hasPermissions(
              userContext.state.userProfile,
              option.permissions,
            )
          })
          .map((option) => (
            <SidebarOptionComponent
              key={`${option.label}-${option.route}`}
              option={option}
              onClick={() => setIsSideBarOpen(false)}
              location={location}
              subOptions={option.subOptions ?? []}
            />
          ))}
        <S.BottomLinksWrapper>
          {bottomOptions
            .filter((option) => {
              const bottomOptionIsAccountSettings =
                option.route === '/account-settings' ||
                option.label === 'Account Settings'
              // On mobile the user can already readily access account settings
              // tapping their profile, so this is redundant and unnecessary
              if (isExclusivelyMobileView && bottomOptionIsAccountSettings) {
                return false
              }
              return true
            })
            .map((option) => (
              <SidebarOptionComponent
                key={`${option.label}-${option.route}`}
                option={option}
                onClick={() => setIsSideBarOpen(false)}
                location={location}
                subOptions={option.subOptions ?? []}
              />
            ))}
        </S.BottomLinksWrapper>
      </S.SidebarLinks>
    </S.SidebarWrapper>
  )
}
