import { makePlural } from '@traba/string-utils'
import { theme } from '@traba/theme'
import { RecurringShiftsOutput, Schedule } from '@traba/types'
import { combineRecurringShifts, getRecurringShifts } from '@traba/utils'
import { useMemo } from 'react'
import { Col } from '../base-components/Col'
import Row from '../base-components/Row'
import { SvgIcon } from '../base-components/SvgIcon'
import { Text } from '../base-components/Text'
import { ScheduleDays } from './ScheduleDays'

type ScheduleStripProps = {
  schedules: Schedule[]
}

function getIntervalFreq(interval: number, freq: string) {
  if (interval === 1) {
    return freq.toLowerCase()
  } else if (interval === 2) {
    return `bi-${freq.toLowerCase()}`
  }
  return `every ${interval} weeks`
}

export function ScheduleStrip(props: ScheduleStripProps) {
  const { schedules } = props
  const [scheduleA, scheduleB] = schedules
  const { startTime, endTime, recurringSchedule } = scheduleA

  const { shiftCount } = useMemo((): RecurringShiftsOutput => {
    if (!recurringSchedule) {
      return {
        shiftCount: 1,
        allShiftDates: [startTime],
        shiftCountWeekly: 1,
      }
    }
    const recurringShiftsA = getRecurringShifts(scheduleA)
    if (scheduleB) {
      const recurringShiftsB = getRecurringShifts(scheduleB)
      return combineRecurringShifts(recurringShiftsA, recurringShiftsB)
    }
    return recurringShiftsA
  }, [scheduleA, scheduleB, recurringSchedule, startTime])

  // Convert startTime and endTime to strings
  const startTimeStr = startTime.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })
  const endTimeStr = endTime.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  // Convert startTime and endDate to date strings
  const startDateStr = startTime.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
  })

  let recurringSection: JSX.Element | undefined
  if (recurringSchedule) {
    const { freq, interval, endDate } = recurringSchedule
    const endDateStr = endDate?.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })

    recurringSection = (
      <>
        <SvgIcon name="undo" size={theme.space.xs} />
        <Row gap={theme.space.xms} alignCenter>
          <Text variant="body3">Repeats {getIntervalFreq(interval, freq)}</Text>
          <Col gap={theme.space.xxs}>
            {schedules.map(
              ({ recurringSchedule }) =>
                recurringSchedule && (
                  <ScheduleDays repeatOn={recurringSchedule.repeatOn} />
                ),
            )}
          </Col>
        </Row>
        {!!endDateStr && (
          <>
            <SvgIcon
              name="calendar"
              size={theme.space.sm}
              color={theme.colors.Grey60}
            />
            <Text variant="body3">
              {startDateStr} - {endDateStr} ({shiftCount} shift
              {makePlural(shiftCount)})
            </Text>
          </>
        )}
      </>
    )
  }

  return (
    <Row gap={theme.space.xxs} alignCenter>
      <SvgIcon name="clock" size={theme.space.sm} color={theme.colors.Grey50} />
      <Text variant="body3">
        {startTimeStr} to {endTimeStr}
      </Text>
      {!!recurringSection && recurringSection}
    </Row>
  )
}
