import { FormControlLabel, FormGroup, Switch } from '@mui/material'
import { Anchor, CardTile } from '@traba/react-components'
import {
  Worker,
  ShiftInvitation,
  Roster,
  ForwardFillMax,
  ShiftInvitationDto,
  CreateShiftRequest,
} from '@traba/types'
import { useEffect, useState } from 'react'
import { Col, Image, InlineBanner, Row, SvgIcon } from 'src/components/base'
import { Text } from 'src/components/base/Text'
import { RadioButton } from 'src/components/RadioButton'
import { Table, Td, Tr } from 'src/components/Table/Table'
import { TableHeader } from 'src/components/Table/Table.styles'
import { useRoster } from 'src/hooks/useRoster'
import { useRosters } from 'src/hooks/useRosters'
import { useVirtualRosters } from 'src/hooks/useVirtualRosters'
import { theme } from 'src/libs/theme'
import { sortWorkersByFirstNameThenLastName } from 'src/shared/utils/sortUtils'
import { getInitials } from 'src/utils/stringUtils'

import * as S from '../../BookShiftsScreen.styles'
import { BookShiftsInvitationsModal } from './BookShiftsInvitationsModal'

const WorkerRow = ({ worker }: { worker: Worker }) => {
  return (
    <Tr key={worker.uid}>
      <Td>
        <Row alignCenter style={{ marginRight: theme.space.xxs }}>
          <CardTile size="40px" style={{ minWidth: 40 }}>
            {worker.photoUrl ? (
              <Image
                style={{
                  width: '40px',
                  height: '40px',
                  borderRadius: theme.space.xxs,
                }}
                src={worker.photoUrl}
                alt="worker-photo"
              />
            ) : (
              <Text variant="h5">
                {getInitials(worker.firstName, worker.lastName)}
              </Text>
            )}
          </CardTile>
          <Text color={theme.colors.MidnightBlue} variant="h6" ml="xs">
            {`${worker.firstName} ${worker.lastName}`}
          </Text>
        </Row>
      </Td>
    </Tr>
  )
}

export function BookShiftsInvitationsSection({
  shiftRequest,
  setShiftRequest,
  existingShiftInvitations,
  getWorkerById,
  selectedSingleShiftDates,
}: {
  shiftRequest: CreateShiftRequest
  setShiftRequest: (d: Partial<CreateShiftRequest>) => void
  existingShiftInvitations?: ShiftInvitation[]
  getWorkerById: (uid: string) => Worker
  selectedSingleShiftDates: Date[] | null
}) {
  const { virtualRosters, virtualRostersWorkersMap } = useVirtualRosters(
    shiftRequest.locationId,
    shiftRequest.roleId,
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedRoster, setSelectedRoster] = useState<Roster | undefined>()
  const { roster, workersMap } = useRoster(
    selectedRoster?.id || '',
    shiftRequest.locationId,
    shiftRequest.roleId,
  )
  const [selectedWorkers, setSelectedWorkers] = useState(
    shiftRequest.shiftInvitations
      ? new Set(
          shiftRequest.shiftInvitations.flatMap((i) =>
            i.workers.map((worker) => worker.workerId),
          ),
        )
      : new Set<string>(),
  )
  const [showInvitationsWarning, setShowInvitationsWarning] = useState(false)

  const { rosters } = useRosters({
    locationId: shiftRequest.locationId,
    roleId: shiftRequest.roleId,
  })

  const { forwardFillMax, shiftInvitations, slotsRequested } = shiftRequest

  const isInvitationShift =
    forwardFillMax === ForwardFillMax.INVITED_ONLY ||
    forwardFillMax === ForwardFillMax.INVITED_FIRST

  const invitationsPossible = !!(
    (rosters && rosters.length > 0) ||
    Object.values(virtualRosters ?? {}).some(
      (roster) => roster.workers.length > 0,
    )
  )

  useEffect(() => {
    if (!shiftInvitations) {
      setSelectedWorkers(new Set())
    }
  }, [shiftInvitations])

  useEffect(() => {
    if (
      isInvitationShift &&
      slotsRequested >
        selectedWorkers.size + (existingShiftInvitations?.length ?? 0)
    ) {
      setShowInvitationsWarning(true)
      setShiftRequest({ forwardFillMax: ForwardFillMax.INVITED_FIRST })
    } else {
      setShowInvitationsWarning(false)
    }
  }, [
    slotsRequested,
    selectedWorkers,
    setShiftRequest,
    isInvitationShift,
    existingShiftInvitations?.length,
  ])

  const onClickWorker = (workerId: string) => {
    const newWorkers = new Set(selectedWorkers)
    if (selectedWorkers.has(workerId)) {
      newWorkers.delete(workerId)
    } else {
      newWorkers.add(workerId)
    }
    setSelectedWorkers(newWorkers)
  }

  function onClickContinue() {
    setShiftRequest({
      shiftInvitations: selectedWorkers.size
        ? [
            {
              batch: 1,
              workers: Array.from(selectedWorkers).map((workerId, index) => ({
                workerId,
                index: index + 1,
              })),
            },
          ]
        : undefined,
    })
    setIsModalOpen(false)
  }

  const existingInvitedWorkers: Worker[] | undefined = existingShiftInvitations
    ?.map((invitation) => getWorkerById(invitation.workerId))
    .sort(sortWorkersByFirstNameThenLastName)

  return (
    <>
      <S.ClickableCard isClickable={invitationsPossible}>
        <S.ClickableRow
          alignCenter
          fullWidth
          justifyBetween
          onClick={
            invitationsPossible
              ? () => {
                  setIsModalOpen(true)
                  if (
                    !shiftRequest.forwardFillMax ||
                    shiftRequest.forwardFillMax
                  ) {
                    setShiftRequest({
                      forwardFillMax: ForwardFillMax.INVITED_FIRST,
                    })
                  }
                }
              : undefined
          }
        >
          <Row style={{ marginRight: theme.space.xxs }}>
            <CardTile
              size="56px"
              style={{ minWidth: 56, marginRight: theme.space.xs }}
            >
              <SvgIcon
                name="userPlus"
                size={theme.space.sm}
                color={theme.colors.Grey50}
              />
            </CardTile>
            <Col>
              <Row>
                <Text color={theme.colors.MidnightBlue} variant="h5">
                  Invite from roster
                </Text>
              </Row>
              <Text variant="body2">
                This option will allow you to invite workers who have worked
                with you before.
              </Text>
            </Col>
          </Row>
          <RadioButton
            selected={
              forwardFillMax === ForwardFillMax.INVITED_FIRST ||
              forwardFillMax === ForwardFillMax.INVITED_ONLY
            }
          />
        </S.ClickableRow>

        {!invitationsPossible && (
          <InlineBanner
            text={
              <>
                You can only invite workers whom you've already added to your
                rosters or marked as favorites. You can easily favorite workers
                or add them to rosters from the{' '}
                <Anchor
                  openInNewTab={true}
                  href="/workers"
                  style={{ fontSize: 12, fontWeight: 400 }}
                >
                  worker management
                </Anchor>{' '}
                tab after they have worked with you.
              </>
            }
            style={{ marginTop: theme.space.xs }}
          />
        )}

        {/* No newly added invitations */}
        {isInvitationShift && !shiftInvitations?.length && (
          <>
            <Text
              mt="xxs"
              style={{ textAlign: 'end' }}
              variant="link"
              onClick={() => setIsModalOpen(true)}
            >
              Add workers
            </Text>
            {!existingShiftInvitations?.length && (
              <InlineBanner
                severity="warning"
                text="Please invite some workers to continue."
                style={{ marginTop: theme.space.xs }}
              />
            )}
          </>
        )}

        {(!!shiftInvitations?.length || !!existingShiftInvitations?.length) && (
          <>
            <Row
              style={{
                marginTop: theme.space.xxs,
              }}
            >
              {forwardFillMax && forwardFillMax !== ForwardFillMax.NONE && (
                <FormGroup style={{ flex: 1 }}>
                  <FormControlLabel
                    componentsProps={{
                      typography: {
                        color: theme.colors.MidnightBlue,
                        fontSize: 14,
                      },
                    }}
                    control={
                      <Switch
                        checked={
                          forwardFillMax === ForwardFillMax.INVITED_FIRST
                        }
                        disabled={showInvitationsWarning}
                        onChange={() =>
                          setShiftRequest({
                            forwardFillMax:
                              forwardFillMax === ForwardFillMax.INVITED_ONLY
                                ? ForwardFillMax.INVITED_FIRST
                                : ForwardFillMax.INVITED_ONLY,
                          })
                        }
                      />
                    }
                    label={
                      <Text variant="h6" color={theme.colors.MidnightBlue}>
                        Invite all qualified Traba workers if these workers are
                        not available?{' '}
                        <span
                          style={{
                            color: theme.colors.Grey60,
                            fontWeight: 600,
                          }}
                        >
                          {forwardFillMax === ForwardFillMax.INVITED_FIRST
                            ? 'Yes'
                            : 'No'}
                        </span>
                      </Text>
                    }
                  />
                </FormGroup>
              )}

              {shiftInvitations && !!shiftInvitations.length && (
                <Text variant="link" onClick={() => setIsModalOpen(true)}>
                  Edit workers
                </Text>
              )}
            </Row>

            {showInvitationsWarning && (
              <InlineBanner
                severity="warning"
                text={`Currently, ${
                  selectedWorkers.size + (existingShiftInvitations?.length ?? 0)
                } of the ${slotsRequested} slots requested will be reserved for the invited workers.
                Either decrease the number of workers requested or invite more workers to be able to disable this option.`}
                style={{ marginTop: theme.space.xs }}
              />
            )}

            <Table style={{ marginTop: theme.space.xxs }}>
              {!!existingInvitedWorkers?.length && (
                <TableHeader>
                  <Text variant="h6">Previously Invited Workers</Text>
                </TableHeader>
              )}
              {existingInvitedWorkers?.map((worker) => (
                <WorkerRow worker={worker} />
              ))}
            </Table>

            {shiftInvitations?.map((shiftInvitation) => {
              if (!shiftInvitation.workers.length) {
                return null
              }
              const workers: Worker[] = shiftInvitation.workers
                .map(({ workerId }: ShiftInvitationDto) => {
                  if (
                    (
                      existingShiftInvitations?.map((i) => i.workerId) || []
                    ).includes(workerId)
                  ) {
                    return undefined
                  }
                  return (
                    workersMap.get(workerId)?.worker || // get the worker object from the WorkerDetails{worker, shiftHistory} object on the ShiftInvitation
                    virtualRostersWorkersMap.get(workerId)
                  )
                })
                .filter(Boolean) as Worker[]

              workers.sort(sortWorkersByFirstNameThenLastName)
              return (
                <Table
                  key={shiftInvitation.batch}
                  style={{ marginTop: theme.space.xxs }}
                >
                  {!!workers.length && (
                    <TableHeader>
                      <Text variant="h6">New Workers to Invite</Text>
                    </TableHeader>
                  )}
                  {workers.map((worker) => {
                    return worker && <WorkerRow worker={worker} />
                  })}
                </Table>
              )
            })}
          </>
        )}
      </S.ClickableCard>

      <BookShiftsInvitationsModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        selectedRoster={selectedRoster}
        setSelectedRoster={setSelectedRoster}
        selectedWorkers={selectedWorkers}
        setSelectedWorkers={setSelectedWorkers}
        onConfirm={onClickContinue}
        roster={roster}
        onClickWorker={onClickWorker}
        confirmOnDismiss
        disabledWorkerIdsSet={
          new Set(
            existingShiftInvitations?.map((invitation) => invitation.workerId),
          )
        }
        shiftRequest={shiftRequest}
        locationId={shiftRequest.locationId}
        roleId={shiftRequest.roleId}
        selectedSingleShiftDates={selectedSingleShiftDates}
      />
    </>
  )
}
