import { Worker, WorkerShift } from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { useAppContext } from 'src/context/appContext/AppContext'
import { FIVE_MINUTES_IN_MS } from 'src/libs/constants'

export type ShiftHistory = {
  mostRecentShift?: WorkerShift
  mostRecentShiftId?: string
  mostRecentShiftStart?: Date
  totalShiftCount: number
  totalMinutesWorked?: number
}

export type WorkerDetails = {
  worker: Worker
  shiftHistory: ShiftHistory
}

async function getCompanyWorkersByIds(
  workerIds?: string[],
  excludeWorkerIds?: string[],
  excludeInvalidStatusWorkers?: boolean,
): Promise<WorkerDetails[] | undefined> {
  try {
    const res = await trabaApi.get(`my-company/workers`, {
      params: {
        workerIds: workerIds,
        excludeWorkerIds,
        excludeInvalidStatusWorkers,
      },
    })
    return res.data
  } catch (error) {
    console.error('useWorker -> getCompanyWorkersByIds() ERROR', error)
  }
}

// Returns workers who have worker shifts associated with this company
export function useCompanyWorkersByIds({
  workerIds,
  excludeWorkerIds,
  isEnabled = true,
  filterByRegion = false,
  excludeInvalidStatusWorkers,
}: {
  workerIds?: string[]
  excludeWorkerIds?: string[]
  isEnabled?: boolean
  filterByRegion?: boolean
  excludeInvalidStatusWorkers?: boolean
} = {}) {
  const shouldFetchWorkers = workerIds ? !!workerIds.length : true

  const { isLoading, isError, data, error, isFetched } = useQuery<
    WorkerDetails[] | undefined,
    Error
  >(
    [
      'companyWorkerByIds',
      workerIds,
      excludeWorkerIds,
      excludeInvalidStatusWorkers,
    ],
    () => {
      return shouldFetchWorkers
        ? getCompanyWorkersByIds(
            workerIds,
            excludeWorkerIds,
            excludeInvalidStatusWorkers,
          )
        : []
    },
    {
      staleTime: FIVE_MINUTES_IN_MS,
      enabled: isEnabled,
    },
  )

  const { state: appContextState } = useAppContext()
  const regionIds = new Set(
    Object.keys(appContextState?.regionalFilter || {}).flat(),
  )

  const filteredData = data?.filter((worker) =>
    filterByRegion ? regionIds.has(worker.worker.regionId) : true,
  )
  const workersMap = new Map(data?.map((worker) => [worker.worker.uid, worker]))

  return {
    isLoading,
    isError,
    data: filteredData,
    workersMap,
    error,
    isFetched,
  }
}
