import { ShiftRequest, ShiftRequestParentWithShiftRequest } from '@traba/types'
import { getMonthFromDate, getShiftTimeString } from '@traba/utils'
import { utcToZonedTime } from 'date-fns-tz'
import { WeekdayStr } from 'rrule'

/**
 * A given shift request parent will *usually* have n shift requests associated
 * with it, where each shift request corresponds to a single role on that shift
 * request parent. However, a shift request parent can be modified to have
 * one-off shifts added to it, which are not recurring. This function filters
 * through a shift request parent's shift requests and returns only the shift
 * requests that have recurring schedules (i.e. the shift requests that were
 * created at the onset of the shift request parent's creation).
 */
function getOnlyRecurringShiftRequestsFromParent(
  shiftRequestParent: ShiftRequestParentWithShiftRequest,
) {
  return shiftRequestParent.shiftRequests.filter((shiftRequest) => {
    if (!shiftRequest.schedules || shiftRequest.schedules.length === 0) {
      return false
    }
    for (const schedule of shiftRequest.schedules) {
      if (schedule.isRecurringSchedule && schedule.recurringSchedule) {
        return true
      }
    }
    return false
  })
}

function buildRoleToQuantityMap(recurringShiftRequests: ShiftRequest[]) {
  const roleToQuantityMap = new Map<string, number>()
  for (const shiftRequest of recurringShiftRequests) {
    const role = shiftRequest.shiftRole
    roleToQuantityMap.set(role, shiftRequest.slotsRequested)
  }
  return roleToQuantityMap
}

export interface ScheduleDetailsTileInfo {
  title: string
  location: string
  roleToQuantityMap: Map<string, number>
  slotsRequestedPerShift: number
  scheduleIsBiWeekly: boolean
  scheduleHasEndDate: boolean
  scheduleRepeatsOnDays: Array<WeekdayStr[]>
  scheduleStartMonthAndDay: { month: string; day: number } | undefined
  scheduleEndMonthAndDay: { month: string; day: number } | undefined
  totalShiftsInSchedule: number | undefined
  shiftTimeString: string | undefined
}

export function getScheduleDetailsTileInfo(
  shiftRequestParent: ShiftRequestParentWithShiftRequest,
) {
  const title = shiftRequestParent.title
  const recurringShiftRequests =
    getOnlyRecurringShiftRequestsFromParent(shiftRequestParent)
  let location = ''
  if (recurringShiftRequests.length) {
    location =
      recurringShiftRequests[0].location?.name ??
      recurringShiftRequests[0].shortLocation
  }
  const roleToQuantityMap = buildRoleToQuantityMap(recurringShiftRequests)
  let slotsRequestedPerShift = 0
  roleToQuantityMap.forEach((value: number) => {
    slotsRequestedPerShift += value
  })
  // 1+ schedules from any recurring shift request on the parent. If the
  // schedule is weekly, there will just be one schedule. If the schedule is
  // bi-weekly, there will be two (hence why
  // schedulesFromAnyRecurringShiftRequest below is an array).
  const schedulesFromAnyRecurringShiftRequest = recurringShiftRequests.length
    ? recurringShiftRequests[0].schedules
    : []
  const scheduleIsBiWeekly = schedulesFromAnyRecurringShiftRequest.length > 1
  const scheduleHasEndDate = schedulesFromAnyRecurringShiftRequest.some(
    (schedule) => schedule.recurringSchedule?.endDate,
  )
  let totalShiftsInSchedule = undefined
  if (scheduleHasEndDate) {
    totalShiftsInSchedule = shiftRequestParent.totalWrittenShiftCounts
  }
  let scheduleStartMonthAndDay = undefined
  let scheduleEndMonthAndDay = undefined
  let shiftTimeString = undefined
  if (schedulesFromAnyRecurringShiftRequest.length) {
    const startTimeForShiftTimeZone = utcToZonedTime(
      schedulesFromAnyRecurringShiftRequest[0].startTime,
      schedulesFromAnyRecurringShiftRequest[0].timeZone,
    )
    scheduleStartMonthAndDay = {
      month: getMonthFromDate(startTimeForShiftTimeZone),
      day: startTimeForShiftTimeZone.getDate(),
    }

    if (scheduleHasEndDate) {
      const endDate =
        schedulesFromAnyRecurringShiftRequest[0].recurringSchedule?.endDate
      if (endDate) {
        scheduleEndMonthAndDay = {
          month: getMonthFromDate(endDate),
          day: endDate.getDate(),
        }
      }
    }

    shiftTimeString = getShiftTimeString(
      schedulesFromAnyRecurringShiftRequest[0].startTime,
      schedulesFromAnyRecurringShiftRequest[0].endTime,
      recurringShiftRequests[0].timezone ?? 'utc',
    )
  }
  const scheduleRepeatsOnDays: Array<WeekdayStr[]> =
    schedulesFromAnyRecurringShiftRequest.map(
      (schedule) => schedule.recurringSchedule?.repeatOn || [],
    )

  return {
    title,
    location,
    roleToQuantityMap,
    slotsRequestedPerShift,
    scheduleIsBiWeekly,
    scheduleHasEndDate,
    scheduleRepeatsOnDays,
    scheduleStartMonthAndDay,
    scheduleEndMonthAndDay,
    totalShiftsInSchedule,
    shiftTimeString,
  }
}
