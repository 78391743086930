import { addPixelUnit } from '@traba/theme'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

export type TextVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'h7'
  | 'h8'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'link1'
  | 'link2'
  | 'label'
  | 'caption'
  | 'sup'
  | 'sub'
  | 'error'
  | 'link'
  | 'linkUnderline'
  | 'month'
  | 'inputError'
  | 'strikethrough'
  | 'brand'
  | 'money'

export type SpacingProps = {
  mt?: string | number
  mb?: string | number
  mr?: string | number
  ml?: string | number
  my?: string | number
  mx?: string | number
  m?: string | number
  pt?: string | number
  pb?: string | number
  pr?: string | number
  pl?: string | number
  py?: string | number
  px?: string | number
  p?: string | number
}

export type TextProps = React.HTMLProps<HTMLElement> & {
  variant?: TextVariant
  center?: boolean
  children?: React.ReactNode
  style?: React.CSSProperties
  onClick?: (ev: React.MouseEvent<HTMLElement>) => any
  color?: string
  mt?: string | number
  mb?: string | number
  mr?: string | number
  ml?: string | number
  my?: string | number
  mx?: string | number
} & SpacingProps

export const TextElements: Record<TextVariant, any> = {
  h1: styled.h1`
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 48px;
    color: ${({ theme }) => theme.colors.MidnightBlue};
    letter-spacing: -0.01em;

    @media only screen and (max-width: (${({ theme }) =>
        theme.media.maxExtraSmall})) {
      font-size: 28px;
      line-height: 36px;
    }
  `,
  h2: styled.h2`
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.MidnightBlue};
    letter-spacing: -0.01em;

    @media only screen and (max-width: (${({ theme }) =>
        theme.media.maxExtraSmall})) {
      font-size: 26px;
      line-height: 34px;
    }
  `,
  h3: styled.h3`
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${({ theme }) => theme.colors.MidnightBlue};
    letter-spacing: -0.004em;

    @media only screen and (max-width: (${({ theme }) =>
        theme.media.maxExtraSmall})) {
      font-size: 22px;
      line-height: 30px;
    }
  `,
  h4: styled.h4`
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.004em;
    color: ${({ theme }) => theme.colors.MidnightBlue};
  `,
  h5: styled.h5`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.004em;
    color: ${({ theme }) => theme.colors.MidnightBlue};
  `,
  h6: styled.h6`
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.MidnightBlue};
  `,
  h7: styled.h6`
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.MidnightBlue};
  `,
  h8: styled.h6`
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: ${({ theme }) => theme.colors.MidnightBlue};
  `,
  body1: styled.p`
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.004em;
    color: ${({ theme }) => theme.colors.MidnightBlue};
  `,
  body2: styled.p`
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.004em;
    color: #767676;
  `,
  body3: styled.p`
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.004em;
    color: #767676;
  `,
  link1: styled.p`
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.004em;
    color: ${({ theme }) => theme.colors.brand};
  `,
  link2: styled.h5`
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.004em;
    color: ${({ theme }) => theme.colors.brand};
  `,
  label: styled.label`
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.004em;
    color: #767676;
    margin-bottom: 8px;
  `,
  caption: styled.p`
    font-size: 10px;
    line-height: 16px;
    font-weight: 600;
    letter-spacing: 0.05em;
    color: ${({ theme }) => theme.colors.Grey60};
  `,
  sup: styled.sup`
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.004em;
    color: #767676;
    margin-left: 7px;
    top: 2px;
    position: absolute;
  `,
  sub: styled.sub`
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.004em;
    color: #767676;
    bottom: 0;
    position: absolute;
    margin-left: 7px;
  `,
  error: styled.p`
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.004em;
    color: #f20d59;
  `,
  inputError: styled.p`
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.004em;
    color: #f20d59;
  `,
  strikethrough: styled.p`
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.004em;
    color: #7a8a99;
    text-decoration-line: line-through;
  `,
  brand: styled.span`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.004em;
    color: ${({ theme }) => theme.colors.brand};
  `,
  money: styled.a`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.004em;
    text-decoration: none;
    color: #138656;
  `,
  link: styled.a`
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.004em;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.brand};
    cursor: pointer;
  `,
  linkUnderline: styled.a`
    cursor: pointer;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.004em;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.MidnightBlue};
    :hover {
      color: ${({ theme }) => theme.colors.brand};
    }
  `,
  month: styled.div`
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.Grey50};
    background-color: ${({ theme }) => theme.colors.Grey10};
    border-radius: ${({ theme }) => theme.border.radius};
    width: 100%;
    padding: 12px;
    text-align: center;
  `,
}

export const Text = forwardRef(
  (
    {
      className,
      variant = 'body1',
      center,
      color,
      mt,
      mb,
      mr,
      ml,
      my,
      mx,
      size,
      ...props
    }: TextProps,
    ref,
  ) => {
    const Component = TextElements[variant]

    return (
      <Component
        className={className}
        ref={ref}
        {...props}
        style={{
          ...(center ? { textAlign: 'center' } : {}),
          ...(color ? { color } : {}),
          ...(mt ? { marginTop: addPixelUnit(mt) } : {}),
          ...(mb ? { marginBottom: addPixelUnit(mb) } : {}),
          ...(mr ? { marginRight: addPixelUnit(mr) } : {}),
          ...(ml ? { marginLeft: addPixelUnit(ml) } : {}),
          ...(my ? { marginVertical: addPixelUnit(my) } : {}),
          ...(mx ? { marginHorizontal: addPixelUnit(mx) } : {}),
          ...(size ? { fontSize: addPixelUnit(size) } : {}),
          ...props.style,
        }}
      />
    )
  },
)
