import { makePlural } from '@traba/string-utils'
import {
  ForwardFillMax,
  ShiftInvitation,
  ShiftPayType,
  Shift,
  CreateShiftRequest,
  CreateShiftRequestMetadata,
  RoleInfoForCreateShiftRequest,
  Role,
} from '@traba/types'
import {
  getFirstShiftDateError,
  getRecurringShifts,
  getRepeatOnError,
  isRoleLocationNotMatchingShiftLocation,
} from '@traba/utils'
import {
  addDays,
  addHours,
  addMinutes,
  differenceInHours,
  endOfDay,
  isAfter,
  isToday,
} from 'date-fns'
import { calculateSingleWorkerPay } from 'src/utils/moneyUtils'

const MAX_DAYS_IN_FUTURE = 364 // For now, we only allow shifts set 1 year in the future
export const MAX_START_DATE = addDays(endOfDay(new Date()), MAX_DAYS_IN_FUTURE)
export const MAX_END_DATE = addDays(MAX_START_DATE, 1) // plus one to allow for overtime

export const MIN_WORKER_HOURLY_PAY_DEFAULT = 13
export const MIN_WORKER_UNIT_PAY = 50
export const MIN_SHIFT_HOURS_HOURLY_PAY = 4
export const MIN_SHIFT_HOURS_UNIT_PAY = 4

export function validateSelectRole(
  data: CreateShiftRequest,
  selectedRole?: Pick<Role, 'location'>,
) {
  if (!data.roleId) {
    return { message: 'You must select a role', title: 'Select Role Error' }
  }

  if (isRoleLocationNotMatchingShiftLocation(data.locationId, selectedRole)) {
    return {
      message:
        'The role you have selected does not match the location of the shift.',
      title: 'Location mismatch error',
    }
  }
}

export function getMinHourlyPayRate({
  companyMinHourlyPay,
  platformMinHourlyPay,
}: {
  companyMinHourlyPay: number | undefined
  platformMinHourlyPay: number
}) {
  return companyMinHourlyPay ?? platformMinHourlyPay
}

export function validatePayRate(
  data: CreateShiftRequest,
  minHourlyPay: number,
) {
  if (data.payType === ShiftPayType.HOURLY && data.payRate < minHourlyPay) {
    return {
      message: `The hourly rate must be $${minHourlyPay} or greater.`,
      title: 'Rate error',
    }
  }
  if (data.payType === ShiftPayType.UNIT) {
    const pbuPayPerWorker =
      calculateSingleWorkerPay(
        {
          ...data,
          ...data.schedules[0],
          payRate: data.payRate,
          payType: data.payType,
          numberOfUnits: data.numberOfUnits || 0,
        },
        undefined,
      ).amount / 100
    if (pbuPayPerWorker && pbuPayPerWorker < MIN_WORKER_UNIT_PAY) {
      return {
        message: `The pay per worker must be $${MIN_WORKER_UNIT_PAY} or greater.`,
        title: 'Rate error',
      }
    }
  }
}

export function validateShiftInvitations(
  data: CreateShiftRequest,
  existingShiftInvitations?: ShiftInvitation[],
) {
  if (
    (data.forwardFillMax === ForwardFillMax.INVITED_FIRST ||
      data.forwardFillMax === ForwardFillMax.INVITED_ONLY) &&
    !data.shiftInvitations?.length &&
    !existingShiftInvitations?.length
  ) {
    return {
      message: 'You must select some workers to invite.',
      title: 'Worker error',
    }
  }
}

export function validateLocation(
  data: CreateShiftRequest,
  metadata?: CreateShiftRequestMetadata,
) {
  if (!data.locationId) {
    return {
      message: 'You must select a location',
      title: 'Location error',
    }
  }
  // Rebooking specific validation
  if (!metadata) {
    // This case should only happen for an archived parking location
    if (data.parkingLocationId === '') {
      return {
        message: 'You must select a parking location',
        title: 'Location error',
      }
    }
  } else {
    if (!metadata.activeRegion) {
      return {
        message:
          'The location you‘ve selected is not in an area where Traba currently operates. Please contact our team at hello@traba.work.',
        title: 'Location error',
      }
    }
    if (metadata.parkingLocationExpanded && !data.parkingLocationId) {
      return {
        message: 'You must select a parking location',
        title: 'Location error',
      }
    }
    if (metadata.parkingLocationExpanded && !metadata.activeParkingRegion) {
      return {
        message:
          'The location you‘ve selected is not in an area where Traba currently operates. Please contact our team at hello@traba.work.',
        title: 'Location error',
      }
    }
  }
}

export function getTimeError(
  data: CreateShiftRequest,
  minutesAheadForShiftPosting: number,
) {
  const schedule = data.schedules[0]
  let shiftStartTime = schedule.startTime
  if (schedule.recurringSchedule) {
    const recurringShifts = getRecurringShifts(schedule)
    shiftStartTime = recurringShifts.allShiftDates[0]
  }
  if (
    isToday(shiftStartTime) &&
    shiftStartTime <= addMinutes(new Date(), minutesAheadForShiftPosting)
  ) {
    return {
      message: `Shift must start at least ${minutesAheadForShiftPosting} minutes in the future`,
      title: 'Schedule error',
    }
  }
  if (data.schedules[0].endTime <= data.schedules[0].startTime) {
    return {
      message: 'End time must be after start time',
      title: 'Schedule error',
    }
  }
  switch (data.payType) {
    case ShiftPayType.UNIT:
      if (
        addHours(data.schedules[0].startTime, MIN_SHIFT_HOURS_UNIT_PAY) >
        data.schedules[0].endTime
      ) {
        return {
          message: `Shifts cannot be shorter than ${MIN_SHIFT_HOURS_UNIT_PAY} hours`,
          title: 'Schedule error',
        }
      }
      break
    case ShiftPayType.HOURLY:
    default:
      if (
        addHours(data.schedules[0].startTime, MIN_SHIFT_HOURS_HOURLY_PAY) >
        data.schedules[0].endTime
      ) {
        return {
          message: `Shifts cannot be shorter than ${MIN_SHIFT_HOURS_HOURLY_PAY} hours`,
          title: 'Schedule error',
        }
      }
  }
  if (addHours(data.schedules[0].startTime, 24) < data.schedules[0].endTime) {
    return {
      message: 'Shifts cannot be longer than 24 hours',
      title: 'Schedule error',
    }
  }
}

export function getDateError(
  data: CreateShiftRequest,
  minutesAheadForShiftPosting: number,
) {
  if (
    !isToday(data.schedules[0].startTime) &&
    data.schedules[0].startTime <=
      addMinutes(new Date(), minutesAheadForShiftPosting) &&
    !minutesAheadForShiftPosting
  ) {
    return {
      message: `Shift must start at least ${minutesAheadForShiftPosting} minutes in the future`,
      title: 'Schedule error',
    }
  }
  if (
    isAfter(data.schedules[0].startTime, MAX_START_DATE) ||
    (data.schedules[0].recurringSchedule?.endDate &&
      isAfter(data.schedules[0].recurringSchedule.endDate, MAX_END_DATE))
  ) {
    return {
      message: 'Shifts cannot be scheduled more than one year in advance',
      title: 'Schedule error',
    }
  }
  if (
    data.schedules[0].recurringSchedule?.endDate &&
    data.schedules[0].recurringSchedule.endDate < data.schedules[0].endTime
  ) {
    return {
      message: 'Recurring end date must be after the first shift',
      title: 'Schedule error',
    }
  }
}

export const MAX_WORKER_COUNT = 200
export function validateWorkers(data: CreateShiftRequest) {
  if (data.slotsRequested < data.minSlotsRequested) {
    return {
      message: 'Max workers must be greater or equal to min workers',
      title: 'Role error',
    }
  }

  if (data.slotsRequested < 1 || data.minSlotsRequested < 1) {
    return {
      message: 'Must request at least one worker',
      title: 'Worker error',
    }
  }

  if (
    data.payType === ShiftPayType.UNIT &&
    (!Number.isInteger(data.numberOfUnits) ||
      data.numberOfUnits == null ||
      data.numberOfUnits < 1)
  ) {
    return {
      message: 'Must request at least one unit',
      title: 'Unit error',
    }
  }

  if (
    data.slotsRequested > MAX_WORKER_COUNT ||
    data.minSlotsRequested > MAX_WORKER_COUNT
  ) {
    return {
      message: `Please contact our support team to request more than ${MAX_WORKER_COUNT} workers.`,
      title: 'Worker error',
    }
  }
}

export function validateSelectPointOfContact(data: CreateShiftRequest) {
  if (!data.supervisorId) {
    return {
      message: 'You must select an onsite point of contact',
      title: 'Contact error',
    }
  }
}

type ShiftKeys = keyof Pick<
  Shift,
  | 'roleId'
  | 'locationId'
  | 'startTime'
  | 'endTime'
  | 'slotsRequested'
  | 'numberOfUnits'
  | 'payType'
  | 'payRate'
  | 'forwardFillMax'
>

export type AllShiftKeys = ShiftKeys &
  keyof Pick<Shift, 'supervisorId' | 'scheduledBreaks' | 'additionalEmails'>

const READABLE_KEY: { [key in ShiftKeys]: string } = {
  roleId: 'role',
  locationId: 'location',
  startTime: 'start time',
  endTime: 'end time',
  slotsRequested: 'number of workers',
  payRate: 'pay rate',
  payType: 'pay type',
  numberOfUnits: 'number of units',
  forwardFillMax: 'invitation type',
}

export const ALL_READABLE_KEYS: { [key in AllShiftKeys]: string } = {
  ...READABLE_KEY,
  supervisorId: 'onsite manager',
  scheduledBreaks: 'scheduled breaks',
  additionalEmails: 'additional emails',
}

const NO_LOCKS = [
  'supervisorId',
  'scheduledBreaks',
  'additionalEmails',
  'payRate',
  'slotsRequested',
  'minSlotsRequested',
]

export function validateShiftEdits(
  data: CreateShiftRequest,
  selectedShifts: Shift[],
  updates: Partial<CreateShiftRequest>,
) {
  const updateKeys = updates && (Object.keys(updates) as ShiftKeys[])
  if (!updateKeys?.length) {
    return
  }

  const isDecreasingPayRate =
    updateKeys.find((key) => key === 'payRate') &&
    updates.payRate &&
    data.payRate &&
    updates?.payRate < data.payRate

  const isDecreasingSlots =
    updateKeys.find((key) => key === 'slotsRequested') &&
    updates.slotsRequested &&
    data.slotsRequested &&
    updates?.slotsRequested < data.slotsRequested

  // Allowed to edit a few fields and allowed to increase hourly rate
  const hasLockedField = updateKeys.some((key) => !NO_LOCKS.includes(key))
  if (!hasLockedField && !isDecreasingPayRate && !isDecreasingSlots) {
    return
  }

  const now = new Date()
  const shiftsWithin18Hours = selectedShifts.filter((shift) => {
    if (
      differenceInHours(shift.businessStartTime ?? shift.startTime, now) <= 18
    ) {
      return true
    }
    return false
  })

  // Allow decreasing slots requested until 18 hours in advance
  if (
    !hasLockedField &&
    !isDecreasingPayRate &&
    isDecreasingSlots &&
    shiftsWithin18Hours.length === 0
  ) {
    return
  }

  const stringifiedFields = updateKeys
    .map((key) => READABLE_KEY[key])
    .filter((k) => !!k)
    .join(`, `)

  if (shiftsWithin18Hours.length) {
    return {
      message: `You have ${shiftsWithin18Hours.length} shift${makePlural(
        shiftsWithin18Hours,
      )} that start${
        shiftsWithin18Hours.length < 2 ? `s` : ``
      } within 18 hours. Cannot change ${stringifiedFields} of a shift within 18 hours.`,
      title: 'Edit error',
    }
  }
}

export function validateScheduleStep(
  data: CreateShiftRequest,
  metadata: CreateShiftRequestMetadata,
) {
  const minutesAheadForShiftPosting = metadata.minutesAheadForShiftPosting
  if (!metadata.scheduleExpanded) {
    return {
      message: "Please choose whether you'd like your shift to repeat.",
      title: 'Schedule error',
    }
  }
  return (
    getTimeError(data, minutesAheadForShiftPosting) ||
    getDateError(data, minutesAheadForShiftPosting) ||
    getRepeatOnError(data)
  )
}

export function validateScheduleStepShiftDataModel(
  data: CreateShiftRequest,
  metadata: CreateShiftRequestMetadata,
) {
  const minutesAheadForShiftPosting = metadata.minutesAheadForShiftPosting
  if (!metadata.scheduleExpanded) {
    return {
      message: "Please choose whether you'd like your shift to repeat.",
      title: 'Schedule error',
    }
  }
  const { isRecurringSchedule } = data.schedules[0]
  if (isRecurringSchedule && !metadata.shiftRequestParentTitle) {
    return {
      message: 'Please name your schedule',
      title: 'Schedule Name Error',
    }
  }
  return (
    getTimeError(data, minutesAheadForShiftPosting) ||
    getDateError(data, minutesAheadForShiftPosting) ||
    getRepeatOnError(data) ||
    getFirstShiftDateError(data)
  )
}

export function validateSiteStep(
  data: CreateShiftRequest,
  metadata?: CreateShiftRequestMetadata,
) {
  // TODO(gavin) - shift data model, remove validateRoleStep
  return (
    validateSelectRole(data, metadata?.role) ||
    validateLocation(data, metadata) ||
    validateSelectPointOfContact(data)
  )
}

export function validateSiteStepShiftDataModel(
  data: CreateShiftRequest,
  metadata?: CreateShiftRequestMetadata,
) {
  return validateLocation(data, metadata)
}

export function validateWorkersStep(
  data: CreateShiftRequest,
  minHourlyPay: number,
  existingShiftInvitations?: ShiftInvitation[],
) {
  return (
    validateWorkers(data) ||
    validatePayRate(data, minHourlyPay) ||
    validateShiftInvitations(data, existingShiftInvitations)
  )
}

export function validateWorkersStepShiftDataModel({
  data,
  minHourlyPay,
  existingShiftInvitations,
  recurringRoles,
  role,
}: {
  data: CreateShiftRequest
  minHourlyPay: number
  existingShiftInvitations?: ShiftInvitation[]
  recurringRoles?: RoleInfoForCreateShiftRequest[]
  role?: Pick<Role, 'location'>
}): { message: string; title: string } | undefined {
  if (!recurringRoles?.length) {
    return { message: 'No recurring roles found', title: 'Role error' }
  }
  for (const roleInfo of recurringRoles) {
    const createShiftRequestData = { ...data, ...roleInfo }

    const notValid =
      validateSelectRole(createShiftRequestData, role) ||
      validateWorkers(createShiftRequestData) ||
      validatePayRate(createShiftRequestData, minHourlyPay) ||
      validateSelectPointOfContact(createShiftRequestData) ||
      validateShiftInvitations(createShiftRequestData, existingShiftInvitations)

    if (notValid) {
      return notValid
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function validateInvoiceStep(data: CreateShiftRequest) {
  return undefined
}
