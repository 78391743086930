import { DEFAULT_TIMEZONE } from '@traba/consts'
import { Col, Row, Text } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Shift,
  ShiftPayType,
  ShiftStatus,
  UserRolePermission,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { useMemo } from 'react'
import { Divider } from 'src/components'
import { WorkerOnShiftTableHeader } from 'src/components/WorkersOnShiftTable/components/WorkerOnShiftTableHeader'
import { MobileWorkersOnShiftListItem } from 'src/components/WorkersOnShiftTable/MobileWorkersOnShiftListItem'
import {
  useWorkersOnShift,
  useWorkersOnShiftTable,
} from 'src/components/WorkersOnShiftTable/WorkersOnShiftTable.hooks'
import { useTimeToDestinations } from 'src/hooks/useTimeToDestinations'
import { useUserPermissions } from 'src/hooks/useUser'
import { useWorkerShifts } from 'src/hooks/workerShiftHooks'

interface Props {
  shift: Shift
  selectedRows: WorkerShiftWithWorkerDetails[]
  setSelectedRows: (
    value: React.SetStateAction<WorkerShiftWithWorkerDetails[]>,
  ) => void
  isFirstShift?: boolean
  dayViewRoleColor: string
}

export const ScheduleDailyViewWorkerTableRoleChunkMobile = ({
  shift,
  selectedRows,
  setSelectedRows,
  isFirstShift,
  dayViewRoleColor,
}: Props) => {
  const { data: workerShifts, refetch: refetchWorkerShifts } = useWorkerShifts(
    shift.shiftId,
  )
  const { timeToDestinations } = useTimeToDestinations(shift?.shiftId)
  const {
    workersOnShift: activeWorkerShifts,
    workersOnBackup,
    inactiveWorkerShifts,
  } = useWorkersOnShift({
    isUpcoming: true,
    workerShifts,
    isShiftCanceled: shift.status === ShiftStatus.CANCELED,
    slotsRequested: shift.slotsRequested,
  })
  const isUpcoming = shift.startTime > new Date()
  const shiftIsCanceled = shift && shift.status === ShiftStatus.CANCELED
  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])
  const showEarnedMoney = userCanViewWages && !isUpcoming && !shiftIsCanceled
  const timezone = shift?.timezone || DEFAULT_TIMEZONE
  const isPBUShift = shift?.payType === ShiftPayType.UNIT
  const logic = useWorkersOnShiftTable({
    shift,
    isUpcoming: true,
  })
  const allWorkers: Array<WorkerShiftWithWorkerDetails> = useMemo(
    () => [
      ...activeWorkerShifts,
      ...(workersOnBackup || []),
      ...inactiveWorkerShifts,
    ],
    [activeWorkerShifts, workersOnBackup, inactiveWorkerShifts],
  )
  const timeToDestinationsByWorkerId = new Map(
    timeToDestinations?.workerShiftTimeToDestinations?.map((ttd) => [
      ttd.workerId,
      ttd,
    ]) || [],
  )
  const backupWorkerIds = new Set(
    (workersOnBackup || []).map((w) => w.workerId),
  )
  const workersTotal =
    activeWorkerShifts.length +
    workersOnBackup.length +
    inactiveWorkerShifts.length

  return allWorkers.length > 0 ? (
    allWorkers.map((workerShift, index) => {
      return (
        <Col key={workerShift.workedShiftId}>
          {isFirstShift && (
            <WorkerOnShiftTableHeader
              totalChargedCents={undefined}
              totalTimeWorked={undefined}
              useTotalTitle={false}
              isEditSlotsEnabled={false}
              onClickEditWorkerSlots={undefined}
              onClickInviteWorker={undefined}
              workersTotal={workersTotal}
              isUpcoming={true}
              filteredWorkers={allWorkers}
              IsShiftInTheFuture={isUpcoming}
              shift={shift}
              numberOfBackupWorkers={workersOnBackup.length}
              userCanViewWages={userCanViewWages}
            />
          )}

          <MobileWorkersOnShiftListItem
            key={index}
            {...logic}
            workerShift={workerShift}
            timeToDestinationsByWorkerId={timeToDestinationsByWorkerId}
            shift={shift}
            isUpcoming={true}
            isBackup={backupWorkerIds.has(workerShift.workerId)}
            timezone={timezone}
            showEarnedMoney={showEarnedMoney}
            isPBUShift={isPBUShift}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            isFromTimesheetDetails={false}
            refetchWorkerShifts={refetchWorkerShifts}
            hideBottomDivider={false}
            dayViewRoleColor={dayViewRoleColor}
            isFirstItem={index === 0}
          />
        </Col>
      )
    })
  ) : (
    <Col>
      <Row
        pt={theme.space.sm}
        alignCenter
        justifyBetween
        pb={theme.space.sm}
        style={{
          borderLeft: dayViewRoleColor
            ? `4px solid ${dayViewRoleColor}`
            : 'none',
        }}
      >
        <Text variant="h7" ml={theme.space.xs} color={dayViewRoleColor}>
          {shift.shiftRole}
        </Text>
        <Text variant="body2" ml={theme.space.xs}>
          - No workers scheduled -
        </Text>
      </Row>
      <Divider />
    </Col>
  )
}
