import { RecurringSchedule, ShiftRequest } from '@traba/types'

export const isRecurringShiftRequest = (
  shiftRequest: ShiftRequest,
): shiftRequest is ShiftRequest & {
  schedules: {
    isRecurringSchedule: true
    recurringSchedule: RecurringSchedule
  }[]
} => {
  return shiftRequest.schedules.some(
    (schedule) => schedule.isRecurringSchedule && !!schedule.recurringSchedule,
  )
}
