import {
  Button,
  ButtonVariant,
  Card,
  Col,
  LoadingSpinner,
  MODAL_SIZE,
  Row,
  SvgIcon,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import { CostCenterType, UserRolePermission } from '@traba/types'
import { useCallback, useState } from 'react'
import { useCostCenters } from 'src/hooks/useCostCenters'
import { useUserPermissions } from 'src/hooks/useUser'
import { Modal } from '../base'
import { CostCenter } from '../CostCenter/CostCenter'
import { ArchiveCostCenterModal } from '../Modals/ArchiveCostCenterModal/ArchiveCostCenterModal'
import CreateOrEditCostCenterModal from '../Modals/CreateOrEditCostCenterModal/CreateOrEditCostCenterModal'

export function CostCentersProfile() {
  const userCanEditCostCenters = useUserPermissions([
    UserRolePermission.ViewPaymentSettings,
  ])

  const {
    data: costCentersResponse,
    isLoading: costCentersLoading,
    isError: error,
  } = useCostCenters()

  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const [costCenterToEdit, setCostCenterToEdit] = useState<
    CostCenterType | undefined
  >(undefined)
  const [showArchiveModal, setShowArchiveModal] = useState(false)
  const [costCenterToArchive, setCostCenterToArchive] = useState<
    CostCenterType | undefined
  >(undefined)
  const costCenters = costCentersResponse?.members

  const toggleEditModal = useCallback((costCenter?: CostCenterType) => {
    setShowEditModal((s) => !s)
    setCostCenterToEdit(costCenter)
  }, [])

  const toggleArchiveModal = useCallback((costCenter?: CostCenterType) => {
    setShowArchiveModal((s) => !s)
    setCostCenterToArchive(costCenter)
  }, [])

  return (
    <>
      <Row alignCenter justifyBetween mb={theme.space.xs}>
        <Col>
          <Text mb={theme.space.xxs} variant="h5">
            Cost Centers
          </Text>
          <Text variant="body2">
            {userCanEditCostCenters ? 'Manage' : 'View'} your company‘s cost
            centers.
          </Text>
        </Col>
        {userCanEditCostCenters && (
          <Button
            leftIcon={
              <SvgIcon
                name="plus"
                color={theme.colors.White}
                strokeWidth={4}
                size={16}
              />
            }
            onClick={() => setShowCreateModal(true)}
            variant={ButtonVariant.FILLED}
          >
            Create new cost center
          </Button>
        )}
      </Row>
      <Col gap={theme.space.xs}>
        {costCentersLoading ? (
          <LoadingSpinner />
        ) : error ? (
          <Text variant="error">There was an error loading cost centers</Text>
        ) : costCenters && costCenters.length > 0 ? (
          costCenters.map((costCenter) => (
            <Card key={costCenter.id}>
              <CostCenter
                costCenter={costCenter}
                edit={toggleEditModal}
                archive={toggleArchiveModal}
                userCanEdit={userCanEditCostCenters}
              />
            </Card>
          ))
        ) : (
          <Text variant="body2">No cost centers found</Text>
        )}
      </Col>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showCreateModal}
        handleClose={() => setShowCreateModal(false)}
      >
        <CreateOrEditCostCenterModal setShowModal={setShowCreateModal} />
      </Modal>
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showEditModal}
        handleClose={toggleEditModal}
      >
        {costCenterToEdit && (
          <CreateOrEditCostCenterModal
            setShowModal={setShowEditModal}
            costCenter={costCenterToEdit}
          />
        )}
      </Modal>
      <Modal
        size={MODAL_SIZE.MEDIUM}
        isOpen={showArchiveModal}
        handleClose={toggleArchiveModal}
      >
        {costCenterToArchive && (
          <ArchiveCostCenterModal
            costCenter={costCenterToArchive}
            handleClose={toggleArchiveModal}
          />
        )}
      </Modal>
    </>
  )
}
