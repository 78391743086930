import { useAlert } from '@traba/context'
import { useAuthProviderIds } from '@traba/hooks'
import { Anchor, CardTile, LoadingSpinner } from '@traba/react-components'
import { useForm } from '@traba/utils'
import { useFormik } from 'formik'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { trabaApi } from 'src/api/helpers'
import { AuthScreenWrapper, Divider } from 'src/components/auth'
import { AuthFullScreenContainer } from 'src/components/auth/AuthScreenWrapper/styles'
import { GoogleAuthButton } from 'src/components/auth/GoogleAuthButton'
import { LoginRightCol } from 'src/components/auth/LoginRightCol'
import { OktaAuthButton } from 'src/components/auth/OktaAuthButton'
import {
  Alert,
  Button,
  Input,
  InputPhone,
  Link,
  Row,
  Text,
} from 'src/components/base'
import { Logo } from 'src/components/svgs/Logo'
import { useQueryParams } from 'src/helpers'
import useAppAuth from 'src/hooks/authHook'
import { useInvitationValidation } from 'src/hooks/useMembers'
import { theme } from 'src/libs/theme'
import { CompanyInvitationAcceptanceDataWithSecret } from 'src/types'
import { email, name, password, phoneNumber } from 'src/utils/formUtils'
import * as yup from 'yup'

function mapErrorMessage(message: string) {
  switch (message) {
    case 'invitation/already-accepted':
      return 'This invitation has already been accepted. Please log in or contact your company’s admin if you believe a mistake has been made.'
    case 'invitation/already-rescinded':
      return 'This invitation is no longer valid. Contact your company’s admin for further details.'
    case 'invitation/expired':
      return 'This invitation is expired. Contact your company’s admin to request that they send you a new invitation.'
    default:
      return 'The invitation could not be found. Please contact your company’s admin or our support team if you believe a mistake has been made.'
  }
}

type AcceptInvitationForm = {
  invitationData: CompanyInvitationAcceptanceDataWithSecret
}

type CreateUserFromInvitation = {
  email: string
  password: string
  firstName: string
  lastName: string
  phoneNumber: string
  invitation: {
    invitationId: string
    companyId: string
    secret: string
  }
}

const AcceptInvitationForm = (props: AcceptInvitationForm) => {
  const { invitationData } = props
  const { handleLogin } = useAppAuth()
  const [isLoading, setIsLoading] = React.useState(false)
  const { handleOnSubmitError } = useForm()
  const { handleError } = useAlert()
  const { authProviderIds, updateProviderIdMap, providerIdMap } =
    useAuthProviderIds(invitationData.companyId)

  useEffect(() => {
    updateProviderIdMap()
  }, [authProviderIds])

  async function createUserFromInvitation(data: CreateUserFromInvitation) {
    try {
      await trabaApi.post('/users', data)
    } catch (error: any) {
      if (error.message === 'invitation/existing-user') {
        handleError(
          error,
          'AcceptInvitationForm -> createUserFromInvitation()',
          'This email address/phone number is already associated with another user.',
          'There was an error with your invitation',
          10000,
        )
      } else if (error.message === 'invitation/invalid-email') {
        handleError(
          error,
          'AcceptInvitationForm -> createUserFromInvitation()',
          'Please ensure that the email address matches the email on the invitation.',
          'There was an error with your invitation',
          10000,
        )
      }
      throw error
    }
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      phoneNumber: '',
    },
    validationSchema: yup.object({
      email: email(),
      password: password(),
      phoneNumber: phoneNumber(),
      firstName: name(),
      lastName: name(),
    }),
    onSubmit: async (values) => {
      try {
        formik.setStatus({})
        setIsLoading(true)
        window.analytics.track(`User Submitted Accept Invitation`, {
          invitationId: invitationData.invitationId,
          companyId: invitationData.companyId,
          email: values.email,
        })
        await createUserFromInvitation({
          ...values,
          invitation: {
            invitationId: invitationData.invitationId,
            companyId: invitationData.companyId,
            secret: invitationData.secret,
          },
        })
        await handleLogin(values.email, values.password)
      } catch (err) {
        handleOnSubmitError(err, formik)
        setIsLoading(false)
      }
    },
  })

  const { errors, touched } = formik

  return (
    <>
      <Row gap={theme.space.xs} alignCenter>
        {invitationData.companyLogo ? (
          <CardTile size="60px" style={{ flexShrink: 0 }}>
            <img
              width="100%"
              src={invitationData.companyLogo}
              alt="company logo"
            />
          </CardTile>
        ) : null}
        <Text variant="h2">
          You’ve been invited to join {invitationData.employerName}!
        </Text>
      </Row>
      <Row mt={theme.space.xs} mb={theme.space.xs}>
        <Text variant="body2">
          Traba connects the top 1% of workers with open shifts at warehouses,
          distribution centers, and event venues.
        </Text>
      </Row>
      {providerIdMap.google && <GoogleAuthButton />}
      {/*
      Apple Auth removed on login because apple hides emails and we
      validate user email matches the invitation email on the backend
      */}
      {/*
      <AppleAuthButton />
      */}
      {providerIdMap.okta && <OktaAuthButton providerId={providerIdMap.okta} />}
      {providerIdMap.email && (
        <>
          <Divider text="or sign up with email" />
          <form onSubmit={formik.handleSubmit}>
            <div style={{ display: 'flex', columnGap: 8 }}>
              <div style={{ flex: 1 }}>
                <Input
                  full
                  label="First Name"
                  {...formik.getFieldProps('firstName')}
                  inputStatus={touched.firstName && errors.firstName ? 3 : 1}
                  errorMessage={errors.firstName}
                />
              </div>
              <div style={{ flex: 1 }}>
                <Input
                  full
                  label="Last Name"
                  {...formik.getFieldProps('lastName')}
                  inputStatus={touched.lastName && errors.lastName ? 3 : 1}
                  errorMessage={errors.lastName}
                />
              </div>
            </div>
            <InputPhone
              value={formik.values.phoneNumber}
              onChange={(val) =>
                formik.handleChange({
                  target: { value: val, name: 'phoneNumber' },
                })
              }
              error={touched.phoneNumber ? errors.phoneNumber : null}
            />
            <Input
              full
              label="Email address"
              {...formik.getFieldProps('email')}
              inputStatus={touched.email && errors.email ? 3 : 1}
              errorMessage={errors.email}
            />
            <Input
              full
              type="password"
              label="Password"
              {...formik.getFieldProps('password')}
              inputStatus={touched.password && errors.password ? 3 : 1}
              errorMessage={formik.errors.password}
            />
            {formik.status && formik.status.error && (
              <Alert mt={24}>{formik.status.message}</Alert>
            )}
            <Row my={24}>
              <Button
                type="submit"
                disabled={isLoading}
                full
                loading={isLoading}
              >
                Join Traba
              </Button>
            </Row>
          </form>
        </>
      )}
      <Text variant="body2" center>
        Already have a Traba for Business account?{' '}
        <Link to={`/login?companyId=${invitationData.companyId}`}>Log in</Link>
      </Text>
      <Text variant="body2" center style={{ margin: '10px 0' }}>
        By signing up, you agree to our{' '}
        <Anchor href="https://www.traba.work/terms-of-use">Terms of Use</Anchor>{' '}
        and{' '}
        <Anchor href="https://www.traba.work/privacy-policy">
          Privacy Policy
        </Anchor>
      </Text>
    </>
  )
}

export function AcceptInvitationScreen() {
  const navigate = useNavigate()
  const query = useQueryParams()

  const { invalidParams, data, error } = useInvitationValidation(query)

  useEffect(() => {
    if (invalidParams) {
      navigate('/login')
    }
  }, [invalidParams, navigate])

  if (error) {
    return (
      <AuthFullScreenContainer>
        <Logo width={100} />
        <Text variant="h4">There was an issue with your invitation.</Text>
        <Text variant="body1">{mapErrorMessage(error.message)}</Text>
        <Button onClick={() => navigate('/login')}>Back to login</Button>
      </AuthFullScreenContainer>
    )
  }

  if (!data) {
    return (
      <AuthFullScreenContainer>
        <LoadingSpinner />
      </AuthFullScreenContainer>
    )
  }

  return (
    <AuthScreenWrapper
      helmetTitle={'Traba - Accept invitation'}
      leftCol={<AcceptInvitationForm invitationData={data} />}
      rightCol={<LoginRightCol />}
    />
  )
}
