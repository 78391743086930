import SearchIcon from '@mui/icons-material/Search'
import {
  FormControl,
  Select,
  MenuItem,
  ListSubheader,
  TextField,
  InputAdornment,
  SelectChangeEvent,
} from '@mui/material'
import { theme } from '@traba/theme'
import { InputStatus } from '@traba/types'
import { containsText } from '@traba/utils'
import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { Button, ButtonVariant } from '../Button/Button'
import { InputErrorIcon, InputErrorMessage } from '../Input/Input.styles'
import { MenuItemGroup, IMenuItem } from '../Select/Select'
import * as S from './SearchSelect.styles'
import { SearchSelectGroupTitle } from './SearchSelectGroupTitle'
import { SearchSelectItemContent } from './SearchSelectItemContent'

const getOptionsInGroup = (
  groupId: string,
  options: IMenuItem[],
): IMenuItem[] => {
  return options.filter((option) => option.groupId === groupId)
}

export interface BaseSearchSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  inputStatus?: InputStatus
  options: Array<IMenuItem>
  selectItem?: IMenuItem
  selectedItems?: IMenuItem[]
  preselectedItems?: IMenuItem[]
  handleSelect?: (value: IMenuItem | undefined) => void
  handleSelectMultiple?: (value: IMenuItem[]) => void
  errorMessage?: string
  multiple?: boolean
  onlyShowLabel?: boolean
  width?: number | string
  isLoading?: boolean
  disabled?: boolean
  shouldAlsoSearchSecondaryLabel?: boolean
  multipleNoneSelectedLabel?: string
  showClearButton?: boolean
  'aria-label'?: string
  selectedOnTop?: boolean
  groups?: MenuItemGroup[]
  groupByGroup?: boolean
  isGroupsSelectable?: boolean
  labelStyle?: React.CSSProperties
  selectStyle?: React.CSSProperties
  placeholder?: string
}
interface MultiSearchSelectProps extends BaseSearchSelectProps {
  multiple: true
  selectedItems: IMenuItem[]
  handleSelectMultiple: (value: IMenuItem[]) => void
  handleSelect?: never
}

interface StandardSearchSelectProps extends BaseSearchSelectProps {
  multiple?: false
  selectedItems?: IMenuItem[]
  handleSelect: (value: IMenuItem | undefined) => void
  handleSelectMultiple?: never
}

export type SearchSelectProps =
  | MultiSearchSelectProps
  | StandardSearchSelectProps

interface GroupedOptionsItem {
  title?: string
  groupId: string
  options: IMenuItem[]
  isGroupSelected?: boolean
  onSelectGroup?: (e: SelectChangeEvent<string>) => void
}

type AdditionalPropsForGroupedItem = Pick<
  SearchSelectProps,
  'multiple' | 'onlyShowLabel'
>

/**
 * We are setting up the group title & its grouped options as this ReactNode array
 * because if we wrap the list of MUI MenuItem components in a fragment (as we'd
 * prefer to do in the render function), the items become unselectable. This issue
 * is described here:
 * @see {@link https://stackoverflow.com/questions/75083605/mui-the-menu-component-doesnt-accept-a-fragment-as-a-child-consider-providing}
 */
function createComponentArrayForGroupedOptions({
  groupedOptionItem,
  selectedItemValues,
  preselectedItemValues,
  additionalProps = {},
}: {
  groupedOptionItem: GroupedOptionsItem
  selectItem?: IMenuItem
  selectedItemValues?: Set<string>
  preselectedItemValues?: Set<string>
  additionalProps: AdditionalPropsForGroupedItem
}): React.ReactNode[] {
  const { groupId, title, options, isGroupSelected, onSelectGroup } =
    groupedOptionItem
  if (options.length === 0) {
    return []
  }

  const componentForTitle = (
    <SearchSelectGroupTitle
      groupId={groupId}
      title={title}
      isGroupSelected={isGroupSelected}
      onSelectGroup={onSelectGroup}
    />
  )

  const optionsComponentArray = options.map((option) => {
    const isSelected = selectedItemValues?.has(option.value)
    const isPreselected = preselectedItemValues?.has(option.value)

    return (
      <MenuItem
        key={option.value}
        value={option.value}
        sx={{ fontFamily: 'Poppins' }}
        aria-label={option.label}
        disabled={isPreselected}
        style={{
          border: `1px ${theme.colors.Grey10} solid`,
          borderRight: 'none',
          borderLeft: 'none',
          paddingTop: theme.space.xxs,
          paddingBottom: theme.space.xxs,
          paddingLeft: additionalProps.multiple ? theme.space.xsmed : undefined,
          opacity: isPreselected ? 1 : undefined,
        }}
      >
        <SearchSelectItemContent
          option={option}
          isSelected={isSelected || isPreselected}
          disabled={isPreselected}
          {...additionalProps}
        />
      </MenuItem>
    )
  })

  return [componentForTitle, ...optionsComponentArray]
}

export function SearchSelect(props: SearchSelectProps) {
  const {
    options,
    multiple,
    selectItem,
    selectedItems = [],
    preselectedItems = [],
    handleSelect,
    handleSelectMultiple,
    onlyShowLabel,
    width,
    isLoading,
    disabled,
    shouldAlsoSearchSecondaryLabel = false,
    multipleNoneSelectedLabel,
    showClearButton = false,
    style,
    labelStyle,
    selectStyle,
    selectedOnTop,
    groups = [],
    groupByGroup,
    isGroupsSelectable,
    placeholder,
    onBlur,
  } = props

  const selectedItemValues = new Set(selectedItems.map((item) => item.value))
  const preselectedItemValues = new Set(
    preselectedItems.map((item) => item.value),
  )
  const allSelectedItemValues = new Set([
    ...preselectedItemValues,
    ...selectedItemValues,
  ])
  const optionsMap = new Map(options.map((item) => [item.value, item]))

  const shouldGroupOptions = groupByGroup && groups.length > 0

  const [searchText, setSearchText] = useState('')

  const displayedOptions = useMemo(() => {
    // First, filter options according to the search text
    const filteredOptions = options.filter((option) => {
      const labelHasText = containsText(option.label, searchText)
      let secondaryLabelHasText = false
      if (shouldAlsoSearchSecondaryLabel && option.secondaryLabel) {
        secondaryLabelHasText = containsText(option.secondaryLabel, searchText)
      }
      return labelHasText || secondaryLabelHasText
    })

    // If multiple selection is enabled and there are selected items, sort them to the top
    const shouldSortSelectedToTop =
      multiple &&
      selectedItems.length > 0 &&
      selectedOnTop &&
      !shouldGroupOptions
    if (shouldSortSelectedToTop) {
      filteredOptions.sort((a, b) => {
        const aIsSelected = allSelectedItemValues.has(a.value)
        const bIsSelected = allSelectedItemValues.has(b.value)
        if (aIsSelected && !bIsSelected) {
          return -1
        } else if (!aIsSelected && bIsSelected) {
          return 1
        }
        return 0 // Keep original order if both are selected or not selected
      })
    }

    return filteredOptions
  }, [
    searchText,
    options,
    shouldAlsoSearchSecondaryLabel,
    multiple,
    selectedItems,
    selectedOnTop,
    shouldGroupOptions,
  ])

  const canSelectGroup = isGroupsSelectable && multiple && handleSelectMultiple
  const getIsGroupSelected = useCallback(
    (groupId: string) => {
      return (
        canSelectGroup &&
        getOptionsInGroup(groupId, options).every((o) =>
          selectedItemValues.has(o.value),
        )
      )
    },
    [canSelectGroup, getOptionsInGroup, selectedItemValues, options],
  )

  const onSelectGroup = useCallback(
    (e: SelectChangeEvent<string>) => {
      const groupId = e.target.value

      if (canSelectGroup && groupId) {
        const optionsInGroup = getOptionsInGroup(groupId, options)
        const optionsInGroupValues = new Set(optionsInGroup.map((o) => o.value))

        if (getIsGroupSelected(groupId)) {
          // the group checkbox was already selected so deselect all (unless pre-selected)
          const selectedItemsWithoutGroupItems = selectedItems.filter(
            (selectedItem) =>
              !optionsInGroupValues.has(selectedItem.value) ||
              preselectedItemValues.has(selectedItem.value),
          )
          return handleSelectMultiple(selectedItemsWithoutGroupItems)
        } else {
          // the group checkbox was not selected so select all in group
          const unselectedOptionsInGroup = optionsInGroup.filter(
            (option) => !selectedItemValues.has(option.value),
          )
          return handleSelectMultiple([
            ...selectedItems,
            ...unselectedOptionsInGroup,
          ])
        }
      }
    },
    [
      canSelectGroup,
      handleSelectMultiple,
      getIsGroupSelected,
      selectedItems,
      options,
    ],
  )

  // group items if we want to group them, else make one group with no group title
  const groupedOptions: GroupedOptionsItem[] = shouldGroupOptions
    ? groups
        .map(({ id: groupId, title, hideTitle }) => ({
          groupId,
          title: hideTitle ? undefined : title,
          onSelectGroup: canSelectGroup ? onSelectGroup : undefined,
          isGroupSelected: canSelectGroup && getIsGroupSelected(groupId),
          options: getOptionsInGroup(groupId, displayedOptions),
        }))
        .filter((g) => g.options.length > 0)
    : [{ title: undefined, groupId: '', options: displayedOptions }]

  const flatComponentListForAllGroupedOptions = groupedOptions.flatMap(
    (groupedOptionItem) => {
      const additionalProps = { multiple, onlyShowLabel }

      return createComponentArrayForGroupedOptions({
        groupedOptionItem,
        selectedItemValues: multiple
          ? selectedItemValues
          : selectItem
            ? new Set<string>([selectItem.value])
            : new Set<string>(),
        preselectedItemValues: preselectedItemValues,
        additionalProps,
      })
    },
  )

  const hasError = props.inputStatus === InputStatus.error
  const onSelect = (e: SelectChangeEvent<string | string[]>) => {
    // Logic for multiple selection
    if (multiple) {
      const updatedValues = e.target.value as string[]
      // convert to and from set to not duplicate pre-selected items
      const updatedValueSet = new Set([
        ...preselectedItemValues,
        ...updatedValues,
      ])
      const uniqueUpdatedValues = Array.from(updatedValueSet)
      const updatedSelectedItems = uniqueUpdatedValues
        .map((v) => optionsMap.get(v))
        .filter((o) => typeof o !== 'undefined') as IMenuItem[]
      return handleSelectMultiple(updatedSelectedItems)
    }

    // Logic for simple selection
    if (!Array.isArray(e.target.value)) {
      const updatedSelectedItem = optionsMap.get(e.target.value)
      if (handleSelect && updatedSelectedItem) {
        handleSelect(updatedSelectedItem)
      }
    }
  }

  const renderValue = () => {
    if (multiple) {
      if (selectedItems?.length === 0) {
        return multipleNoneSelectedLabel
      }

      return selectedItems?.map((item) => item.label).join(', ')
    }
    return selectItem?.label || selectItem?.value || placeholder || '-'
  }

  const onClearSelection = useCallback(() => {
    if (multiple && handleSelectMultiple) {
      handleSelectMultiple(preselectedItems)
    } else if (handleSelect) {
      handleSelect(undefined)
    }
  }, [handleSelect, handleSelectMultiple, multiple])

  useEffect(() => {
    // select pre-selected items on component load
    if (multiple && handleSelectMultiple && preselectedItems.length > 0) {
      handleSelectMultiple(preselectedItems)
    }
  }, [])

  return (
    <>
      <S.SearchSelectContainer style={{ width, ...style }}>
        <S.SearchSelectBoxStyling />
        <FormControl style={{ borderColor: theme.colors.brand }} fullWidth>
          <Select
            // Disables auto focus on MenuItems and allows TextField to be in focus
            MenuProps={{ autoFocus: false }}
            labelId="search-select-label"
            id="search-select"
            value={
              multiple
                ? [...selectedItemValues, ...preselectedItemValues]
                : selectItem
                  ? selectItem?.value
                  : placeholder || '-'
            }
            aria-label={props['aria-label']}
            onChange={onSelect}
            onBlur={(e: any) => (onBlur ? onBlur(e) : undefined)}
            onClose={() => setSearchText('')}
            renderValue={renderValue}
            displayEmpty={!!(multiple && multipleNoneSelectedLabel)}
            multiple={multiple}
            disabled={isLoading || disabled}
            sx={{ width }}
            error={hasError}
            style={selectStyle}
            placeholder={placeholder}
          >
            {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
            <ListSubheader>
              <TextField
                id="search-text-field"
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: showClearButton ? (
                    <Button
                      style={{ padding: 0, paddingRight: '8px' }}
                      variant={ButtonVariant.TEXT}
                      onClick={onClearSelection}
                    >
                      Clear all
                    </Button>
                  ) : undefined,
                }}
                sx={{ margin: '8px 0px 16px 0px' }}
                onChange={(e) => setSearchText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== 'Escape') {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation()
                  }
                }}
              />
            </ListSubheader>
            {flatComponentListForAllGroupedOptions}
          </Select>
        </FormControl>
        {!!props.label && (
          <S.SearchSelectLabel style={labelStyle}>
            {props.label}
          </S.SearchSelectLabel>
        )}
      </S.SearchSelectContainer>
      {hasError && (
        <InputErrorMessage>
          <InputErrorIcon />
          {props.errorMessage}
        </InputErrorMessage>
      )}
    </>
  )
}
