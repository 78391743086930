import { Text } from '@traba/react-components'
import {
  Shift,
  WorkerShiftTimeToDestinationResponseDto,
  WorkerShift,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { getShiftTimeString } from '@traba/utils'
import { AxiosResponse } from 'axios'
import { isBefore } from 'date-fns'
import React, { useCallback } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { useUserContext } from 'src/context/user/UserContext'
import { useCompany } from 'src/hooks/useCompany'
import { BizToWorkerConnection } from 'src/hooks/useConnections'
import { useReviews } from 'src/hooks/useReviews'
import {
  BulkWorkerActionBasePayload,
  ClockInOutResult,
  ClockInWorkers,
  ClockOutWorkers,
  RejectWorkers,
} from 'src/hooks/workerShiftHooks'
import { theme } from 'src/libs/theme'
import { EarlyIncentiveBadge } from 'src/screens/TimeSheetDetailsScreen/components/EarlyIncentiveBadge'
import { ConnectionType, JobStatus, UserRolePermission } from 'src/types'
import { getWorkerShiftUpdatesWithoutEarlyArrival } from 'src/utils/moneyUtils'
import { hasPermissions } from 'src/utils/userUtils'
import {
  getIsCompleteStatus,
  getIsReviewableStatus,
} from 'src/utils/workerShiftUtils'
import { Row } from '../base'
import { Checkbox } from '../base/CheckboxThemed'
import { ReviewWorkerForm } from '../ReviewWorker/ReviewWorkerForm'
import { SlideOut } from '../Slideout/Slideout'
import { Td, Tr } from '../Table/Table'
import WorkerConnectionMenu, {
  WorkerConnectionActionButton,
} from '../WorkerConnectionMenu/WorkerConnectionMenu'
import { WorkerOnShiftBadge } from '../WorkerOnShiftBadge/WorkerOnShiftBadge'
import WorkersManagementMenu from '../WorkersManagementMenu/WorkersManagementMenu'
import { LiveWorkerEarning } from './components/liveWorkerEarning'
import { TimesheetBadge } from './components/TimeSheetBadge'
import { WorkerOnShiftTableBreaksField } from './components/WorkerOnShiftTableBreaksField'
import { WorkerOnShiftTableClockInField } from './components/WorkerOnShiftTableClockInField'
import { WorkerOnShiftTableClockOutField } from './components/WorkerOnShiftTableClockOutField'
import { WorkerOnShiftTableTotalWorked } from './components/WorkerOnShiftTableTotalWorked'
import { WorkerPhotoAndName } from './components/WorkerPhotoAndName'
import { useInReview } from './WorkersOnShiftTable.hooks'

interface Props {
  workerShift: WorkerShiftWithWorkerDetails
  timeToDestinationsByWorkerId: Map<
    string,
    WorkerShiftTimeToDestinationResponseDto
  >
  shift: Shift
  editMode?: boolean
  isUpcoming?: boolean
  isBackup: boolean
  connections?: BizToWorkerConnection
  isPBUShift: boolean
  clockInWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockInWorkers
  >
  editClockInWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockInWorkers
  >
  clockOutWorkers: UseMutateAsyncFunction<
    ClockInOutResult,
    Error,
    ClockOutWorkers
  >
  checked: boolean
  showEarnedMoney: boolean
  timezone: string
  onEditWorkerShift?: (
    newWorkerShift: Partial<WorkerShift> & {
      workerId: string
      shiftId: string
    },
  ) => void
  onCheckBoxClicked: () => void
  abandonWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    ClockOutWorkers
  >
  rejectWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    RejectWorkers
  >
  noShowWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    BulkWorkerActionBasePayload
  >
  bizCancelRemoveWorkers: UseMutateAsyncFunction<
    PromiseSettledResult<AxiosResponse>[],
    Error,
    BulkWorkerActionBasePayload
  >
  refetchWorkerShifts: () => void
  reviewColSpan: number
  isFromTimesheetDetails?: boolean
  tableRowStyle?: React.CSSProperties
  dayViewRoleColor?: string
  isFirstItem?: boolean
}

export function WorkerOnShiftListItem({
  workerShift,
  isBackup,
  clockInWorkers,
  clockOutWorkers,
  editClockInWorkers,
  isPBUShift,
  shift,
  showEarnedMoney,
  timeToDestinationsByWorkerId,
  timezone,
  editMode,
  isUpcoming,
  onEditWorkerShift,
  connections,
  reviewColSpan,
  onCheckBoxClicked,
  isFromTimesheetDetails,
  abandonWorkers,
  bizCancelRemoveWorkers,
  noShowWorkers,
  rejectWorkers,
  checked,
  refetchWorkerShifts,
  tableRowStyle,
  dayViewRoleColor,
  isFirstItem,
}: Props) {
  const userContext = useUserContext()
  const timeToDestination = timeToDestinationsByWorkerId.get(
    workerShift.workerId,
  )
  const worker = workerShift.worker
  const isWorkerShiftComplete = getIsCompleteStatus(workerShift.jobStatus)

  const userCanManageWorkers = hasPermissions(userContext.state.userProfile, [
    UserRolePermission.ManageWorkers,
  ])

  const { inReview, toggleReview } = useInReview()
  const { company } = useCompany()
  const workerShiftCharge = getWorkerShiftUpdatesWithoutEarlyArrival(
    shift,
    workerShift,
  )

  const selectionDisallowed = editMode

  const earlyTime =
    !!shift?.businessStartTime &&
    !!workerShift.clockInTime &&
    isBefore(workerShift.clockInTime, shift.businessStartTime) &&
    !company?.hoursToClockInEarlyBy
      ? true
      : false

  const renderEarlyTimeBadge = () => {
    return earlyTime && <EarlyIncentiveBadge />
  }
  const { reviews, refetchReviews } = useReviews({ shiftId: shift.shiftId })
  const isWorkerReviewed = !!reviews?.find(
    (r) => r.workerId === workerShift.workerId,
  )

  const shouldShowReview =
    userCanManageWorkers &&
    !editMode &&
    getIsReviewableStatus(workerShift.jobStatus)

  const onFavorite = useCallback(
    (workerShift: WorkerShift) => {
      connections?.favoriteWorker(workerShift.workerId).then((connection) => {
        toggleReview(
          [workerShift.workerId],
          'OPEN',
          ConnectionType.FAVORITE,
          connection.id,
        )
      })
    },
    [toggleReview, connections],
  )

  return (
    <React.Fragment key={workerShift.workerId}>
      <Tr style={{ ...tableRowStyle, height: 66 }}>
        {dayViewRoleColor && (
          <Td
            style={{
              borderLeft: `3px solid ${dayViewRoleColor}`,
              position: 'relative',
            }}
          >
            {isFirstItem && (
              <Text
                color={theme.colors.White}
                style={{
                  borderBottomRightRadius: theme.space.ms,
                  backgroundColor: dayViewRoleColor,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  zIndex: 1,
                  padding: theme.space.us,
                }}
                variant="caption"
              >
                {getShiftTimeString(
                  workerShift.shiftInfo.startTime,
                  workerShift.shiftInfo.endTime,
                  workerShift.shiftInfo.timezone,
                )}
              </Text>
            )}
            <Text variant="h7" ml={theme.space.xs} color={dayViewRoleColor}>
              {workerShift.shiftInfo.shiftRole}
            </Text>
          </Td>
        )}
        <Td
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.space.xxs,
          }}
        >
          {!dayViewRoleColor && (
            <Checkbox
              selected={checked}
              disabled={selectionDisallowed}
              hidden={editMode}
              onClick={onCheckBoxClicked}
              style={{ justifyContent: 'center' }}
            />
          )}

          <WorkerPhotoAndName
            worker={worker}
            isFirstTimeWorkerShiftWithCompany={
              workerShift.isFirstTimeWorkerShiftWithCompany
            }
          />
          {!isUpcoming ? <TimesheetBadge workerShift={workerShift} /> : null}
        </Td>

        {isUpcoming ? (
          <Td>
            <WorkerOnShiftBadge
              workerShift={workerShift}
              timeToDestination={timeToDestination}
              isBackup={isBackup}
            />
          </Td>
        ) : null}

        <Td>
          <WorkerOnShiftTableClockInField
            showEditableTimeInput={!!(editMode && isWorkerShiftComplete)}
            clockInWorkers={clockInWorkers}
            clockOutWorkers={clockOutWorkers}
            editClockInWorkers={editClockInWorkers}
            workerShift={workerShift}
            timezone={timezone}
            onEditWorkerShift={onEditWorkerShift}
            refetchWorkerShifts={refetchWorkerShifts}
            isFromTimesheetDetails={isFromTimesheetDetails}
          />
          {renderEarlyTimeBadge()}
        </Td>
        <Td>
          <WorkerOnShiftTableClockOutField
            showEditableTimeInput={!!(editMode && isWorkerShiftComplete)}
            clockInWorkers={clockInWorkers}
            clockOutWorkers={clockOutWorkers}
            editClockInWorkers={editClockInWorkers}
            workerShift={workerShift}
            timezone={timezone}
            onEditWorkerShift={onEditWorkerShift}
            refetchWorkerShifts={refetchWorkerShifts}
            isFromTimesheetDetails={isFromTimesheetDetails}
          />
        </Td>

        <Td>
          <WorkerOnShiftTableBreaksField
            workerShift={workerShift}
            onEditWorkerShift={onEditWorkerShift}
            editMode={editMode}
            timezone={timezone}
            scheduledBreaks={shift.scheduledBreaks}
            refetchWorkerShifts={refetchWorkerShifts}
            isFromTimesheetDetails={isFromTimesheetDetails}
          />
        </Td>
        {isPBUShift ? <Td>{workerShift.unitsWorked || 0} units</Td> : null}
        <Td>
          <WorkerOnShiftTableTotalWorked
            isWorkerShiftComplete={isWorkerShiftComplete}
            editMode={editMode}
            workerShift={workerShift}
          />
        </Td>
        {showEarnedMoney ? (
          <Td key={workerShift.workerId}>
            <LiveWorkerEarning
              workerShift={workerShift}
              workerShiftCharge={workerShiftCharge}
              editMode={editMode}
              shiftMarkup={shift.calculatedMarkup}
            />
          </Td>
        ) : null}
        {!editMode && (
          <>
            <Td style={{ paddingRight: 0 }}>
              <WorkerConnectionMenu
                worker={workerShift.worker}
                isBlocked={
                  connections?.isBlocked(workerShift.workerId) || false
                }
                isFavorited={
                  connections?.isFavorite(workerShift.workerId) || false
                }
                isIneligible={
                  connections?.isIneligible(workerShift.workerId) || false
                }
                isScheduledBlock={
                  connections?.isScheduledBlock(workerShift.workerId) || false
                }
                hidden={!(userCanManageWorkers && connections) || !!editMode}
                onFavorite={() => onFavorite(workerShift)}
                workerShift={workerShift}
                isReviewed={isWorkerReviewed}
                onReview={() => {
                  toggleReview([workerShift.workerId], 'TOGGLE')
                }}
                buttonsToShow={
                  workerShift.jobStatus === JobStatus.ToDo
                    ? [
                        WorkerConnectionActionButton.AddToRoster,
                        WorkerConnectionActionButton.MessageWorker,
                      ]
                    : [
                        WorkerConnectionActionButton.AddToRoster,
                        WorkerConnectionActionButton.Block,
                        WorkerConnectionActionButton.Favorite,
                        WorkerConnectionActionButton.MessageWorker,
                        ...(shouldShowReview
                          ? [WorkerConnectionActionButton.ReviewWorker]
                          : []),
                      ]
                }
                alignEnd={true}
              />
            </Td>
            <Td style={{ paddingRight: 0 }}>
              <Row center style={{ paddingRight: theme.space.xs }}>
                {!editMode && connections && (
                  <WorkersManagementMenu
                    shiftId={shift.shiftId}
                    workers={[workerShift]}
                    connections={connections}
                    onFavorite={(workerIds) => {
                      for (const w of workerIds) {
                        connections.favoriteWorker(w)
                      }
                      toggleReview(workerIds, 'OPEN', ConnectionType.FAVORITE)
                    }}
                    shiftRole={shift.shiftRole}
                    shiftStartTime={shift.businessStartTime ?? shift.startTime}
                    shiftEndTime={shift.endTime}
                    timezone={timezone}
                    clockInWorkers={clockInWorkers}
                    editClockInWorkers={editClockInWorkers}
                    clockOutWorkers={clockOutWorkers}
                    minimumPaidTime={shift?.minimumPaidTime || 0}
                    abandonWorkers={abandonWorkers}
                    rejectWorkers={rejectWorkers}
                    noShowWorkers={noShowWorkers}
                    workerRowContext={true}
                    bizCancelRemoveWorkers={bizCancelRemoveWorkers}
                  />
                )}
              </Row>
            </Td>
          </>
        )}
      </Tr>

      {inReview.has(workerShift.workerId) ? (
        <tr>
          <td colSpan={reviewColSpan}>
            <SlideOut>
              <div
                style={{
                  padding: theme.space.sm,
                }}
              >
                <ReviewWorkerForm
                  variation={inReview.get(workerShift.workerId)?.variation}
                  workerName={worker.firstName}
                  workerId={workerShift.workerId}
                  shiftId={workerShift.shiftId}
                  requiredAttributes={
                    workerShift.shiftInfo?.requiredAttributes || []
                  }
                  onSaveReview={() => refetchReviews()}
                  connectionId={
                    inReview.get(workerShift.workerId)?.connectionId
                  }
                  onClose={() => {
                    toggleReview(
                      [workerShift.workerId],
                      'CLOSE',
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      inReview.get(workerShift.workerId)!.variation,
                    )
                  }}
                />
              </div>
            </SlideOut>
          </td>
        </tr>
      ) : null}
    </React.Fragment>
  )
}
