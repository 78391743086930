export type CostCenterType = {
  id: string
  name: string
  status: CostCenterStatus
  locations: CostCenterLocation[]
}

export type CostCenterResponse = {
  members: CostCenterType[]
  page: number
  pageCount: number
  pageSize: number
}

export type CostCenterLocation = {
  id: string
  name: string
}

export type CreateCostCenter = {
  name: string
  locationIds?: string[]
}

export type UpdateCostCenter = {
  id: string
  name?: string
  status?: CostCenterStatus
  locationIds?: string[]
}

export type CostCenterFormData = {
  name?: string
  status?: CostCenterStatus
  locationIds?: string[]
}

export enum CostCenterStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
