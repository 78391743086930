import * as Sentry from '@sentry/react'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { trabaApi } from '@traba/api-utils'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  CostCenterResponse,
  CreateCostCenter,
  UpdateCostCenter,
} from '@traba/types'
import { AxiosError } from 'axios'

const COST_CENTER_QUERY_KEY = 'costCenterQueryKey'

async function getCostCenters() {
  try {
    const response = await trabaApi.get(`/my-company/cost-center`)
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export const useCostCenters = () => {
  // Implementation here
  const { isLoading, isError, data, error, refetch } = useQuery<
    CostCenterResponse,
    Error
  >({
    queryKey: [COST_CENTER_QUERY_KEY],
    queryFn: getCostCenters,
    staleTime: FIVE_MINUTES_IN_MS,
  })
  return {
    isLoading,
    isError,
    data,
    error,
    refetch,
  }
}

async function createCostCenter(costCenterData: CreateCostCenter) {
  try {
    const response = await trabaApi.post(
      `/my-company/cost-center`,
      costCenterData,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

async function updateCostCenter(costCenterData: UpdateCostCenter) {
  try {
    const response = await trabaApi.patch(
      `/my-company/cost-center/${costCenterData.id}`,
      costCenterData,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

async function deleteCostCenter(costCenterId: string) {
  try {
    await trabaApi.delete(`/my-company/cost-center/${costCenterId}`)
  } catch (error) {
    Sentry.captureException(error)
    console.error(error)
    throw error
  }
}

export function useCostCentersMutations() {
  const { showSuccess, showError } = useAlert()
  const queryClient = useQueryClient()

  const createCostCenterMutation = useMutation<
    void,
    AxiosError,
    CreateCostCenter
  >({
    mutationFn: (costCenterData) => createCostCenter(costCenterData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COST_CENTER_QUERY_KEY] })
      showSuccess('Cost center created successfully')
    },
    onError: (error) => {
      showError(error.message, 'Failed to create cost center')
    },
  })

  const updateCostCenterMutation = useMutation<
    void,
    AxiosError,
    UpdateCostCenter
  >({
    mutationFn: (costCenterData) => updateCostCenter(costCenterData),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COST_CENTER_QUERY_KEY] })
      showSuccess('Cost center updated successfully')
    },
    onError: (error) => {
      showError(error.message, 'Failed to update cost center')
    },
  })

  const deleteCostCenterMutation = useMutation<void, AxiosError, string>({
    mutationFn: (costCenterId) => deleteCostCenter(costCenterId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [COST_CENTER_QUERY_KEY] })
      showSuccess('Cost center deleted successfully')
    },
    onError: (error) => {
      showError(error.message, 'Failed to delete cost center')
    },
  })

  return {
    createCostCenter: createCostCenterMutation.mutateAsync,
    updateCostCenter: updateCostCenterMutation.mutateAsync,
    deleteCostCenter: deleteCostCenterMutation.mutateAsync,
    isCreatingCostCenter: createCostCenterMutation.isPending,
    isUpdatingCostCenter: updateCostCenterMutation.isPending,
    isDeletingCostCenter: deleteCostCenterMutation.isPending,
  }
}
