import { useAlert } from '@traba/context'
import { useHotSettings } from '@traba/hooks'
import { Anchor } from '@traba/react-components'
import {
  ShiftPayType,
  CreateShiftRequest,
  CreateShiftRequestMetadata,
  RecurringShiftsOutput,
  RoleInfoForCreateShiftRequest,
} from '@traba/types'
import {
  combineRecurringShifts,
  getRecurringShifts,
  recurringSchedulesEnabled,
  roundToDecimalPlaces,
} from '@traba/utils'
import { useMemo, useState } from 'react'
import { useQueryParams } from 'src/helpers'
import { useCompany } from 'src/hooks/useCompany'
import { theme } from 'src/libs/theme'
import {
  calculateSingleWorkerPay,
  numberToCurrency,
} from 'src/utils/moneyUtils'
import { truncateString } from 'src/utils/stringUtils'
import { Button, Checkbox, Divider, Row, Text } from '../base'
import * as S from './EstimatedTotal.styles'

const AGREEMENT_LINE_HEIGHT = '22px'

interface EstimatedTotalProps {
  shiftRequest: CreateShiftRequest
  shiftRequestMetadata: CreateShiftRequestMetadata
  setShiftRequestMetadata: (d: Partial<CreateShiftRequestMetadata>) => void
  onBook: ({ isRebooking }: { isRebooking: boolean }) => Promise<void>
  userCanViewPay: boolean
  selectedSingleShiftDates?: Date[] | null
  recurringRoles: RoleInfoForCreateShiftRequest[]
}

export function EstimatedTotal(props: EstimatedTotalProps) {
  const {
    shiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    onBook,
    userCanViewPay,
    selectedSingleShiftDates,
    recurringRoles,
  } = props
  const { scheduledBreaks, schedules } = shiftRequest
  const { company, markup } = useCompany()
  const { isLoading: isLoadingHotSettings, hotSettings } = useHotSettings()
  const [loading, setLoading] = useState(false)
  const { showError } = useAlert()
  const query = useQueryParams()
  const shiftRequestId = query.get('rebookingId')

  const { shiftCount, shiftCountWeekly } =
    useMemo((): RecurringShiftsOutput => {
      const [scheduleA, scheduleB] = schedules
      if (!scheduleA.isRecurringSchedule) {
        return {
          shiftCount: selectedSingleShiftDates?.length || 1,
          shiftCountWeekly: 1,
          allShiftDates: [scheduleA.startTime],
        }
      }
      const recurringShiftsA = getRecurringShifts(scheduleA)
      if (scheduleB) {
        const recurringShiftsB = getRecurringShifts(scheduleB)
        return combineRecurringShifts(recurringShiftsA, recurringShiftsB)
      }
      return recurringShiftsA
    }, [schedules])

  if (isLoadingHotSettings) {
    return null
  }

  async function onClick() {
    try {
      setLoading(true)
      await onBook({ isRebooking: !!shiftRequestId })
    } catch {
      showError('There was an error booking your shift. Please try again.')
    } finally {
      setLoading(false)
    }
  }

  const isInfiniteSchedule = schedules.some(
    (s) => s.isRecurringSchedule && !s.recurringSchedule?.endDate,
  )

  let workerPay = 0

  if (
    recurringSchedulesEnabled({ companyId: company?.companyId, hotSettings })
  ) {
    for (const roleInfo of recurringRoles) {
      // Get worker pay for one single shift, calculate total pay based on number of shifts (shiftCountWeekly is total count of shifts in a week for weekly, and two weeks for biweekly)
      const { startTime, endTime } = schedules[0]
      if (roleInfo.payType === ShiftPayType.UNIT) {
        if (roleInfo.payRate && roleInfo.numberOfUnits) {
          workerPay += roleInfo.numberOfUnits * roleInfo.payRate
        }
      } else {
        const singleWorkerPay = calculateSingleWorkerPay(
          {
            startTime,
            endTime,
            payRate: roleInfo.payRate,
            scheduledBreaks,
            breakType: company?.breakType,
            payType: roleInfo.payType,
            numberOfUnits: roleInfo.numberOfUnits || 0,
            slotsRequested: roleInfo.slotsRequested,
          },
          undefined,
        )
        workerPay += (singleWorkerPay.amount * roleInfo.slotsRequested) / 100
      }
    }
  } else {
    // Get worker pay for one single shift, calculate total pay based on number of shifts (shiftCountWeekly is total count of shifts in a week for weekly, and two weeks for biweekly)
    const { startTime, endTime } = schedules[0]
    if (shiftRequest.payType === ShiftPayType.UNIT) {
      if (shiftRequest.payRate && shiftRequest.numberOfUnits) {
        workerPay += shiftRequest.numberOfUnits * shiftRequest.payRate
      }
    } else {
      const singleWorkerPay = calculateSingleWorkerPay(
        {
          startTime,
          endTime,
          payRate: shiftRequest.payRate,
          scheduledBreaks,
          breakType: company?.breakType,
          payType: shiftRequest.payType,
          numberOfUnits: shiftRequest.numberOfUnits || 0,
          slotsRequested: shiftRequest.slotsRequested,
        },
        undefined,
      )
      workerPay += (singleWorkerPay.amount * shiftRequest.slotsRequested) / 100
    }
  }

  const workerPayText = numberToCurrency(
    schedules.length === 1 ? workerPay : workerPay * shiftCountWeekly,
  )
  const markupText = numberToCurrency(
    (schedules.length === 1 ? workerPay : workerPay * shiftCountWeekly) *
      markup,
  )
  const totalPayText = numberToCurrency(
    workerPay *
      (1 + markup) *
      (isInfiniteSchedule ? shiftCountWeekly : shiftCount),
  )

  // truncate markup string to 5 characters, e.g. 28.99%, after rounding to 2
  // decimal places
  const truncatedMarkupPercent = truncateString(
    roundToDecimalPlaces(markup * 100, 2).toString(),
    5,
  )

  const lines = [
    {
      label: `${schedules.length === 1 ? '' : 'Bi-weekly '}Worker pay`,
      value: workerPayText,
    },
    { label: `Markup (${truncatedMarkupPercent}%)`, value: markupText },
  ]

  const customerAgreementLink = (
    <Anchor
      openInNewTab={true}
      href="https://www.traba.work/customer-agreement"
      style={{ fontSize: 12, lineHeight: AGREEMENT_LINE_HEIGHT }}
    >
      Customer Agreement
    </Anchor>
  )

  const businessCancellationLink = (
    <Anchor
      openInNewTab={true}
      href="https://www.traba.work/business-faq#faq_question9"
      style={{ fontSize: 12, lineHeight: AGREEMENT_LINE_HEIGHT }}
    >
      Business Cancellation Policy
    </Anchor>
  )

  const agreementText = (
    <Text
      variant="body2"
      style={{
        margin: `${userCanViewPay ? theme.space.xxs : 0}px 0 ${
          theme.space.xs
        }px `,
        fontSize: 12,
        lineHeight: AGREEMENT_LINE_HEIGHT,
      }}
    >
      {company?.agreements?.acceptedFeesAndTerms ? (
        <>
          By clicking "Confirm and book" I agree to the {customerAgreementLink},
          and the {businessCancellationLink}.
        </>
      ) : (
        <>
          By checking the box below, I agree to the Fees (including mark-up of
          Wages, as applicable), payment terms, and other relevant terms set
          forth on this webpage, which constitutes an Order Form pursuant to the{' '}
          {customerAgreementLink}. I also agree to the{' '}
          {businessCancellationLink}.
        </>
      )}
    </Text>
  )

  const totalPayHeader = isInfiniteSchedule
    ? schedules.length === 1
      ? 'Est. Weekly Total'
      : 'Est. Bi-Weekly Total'
    : 'Total'

  const title = isInfiniteSchedule
    ? schedules.length === 1
      ? 'Est. Weekly Total'
      : 'Est. Bi-Weekly Total'
    : 'Estimated Total'

  return (
    <S.EstimatedTotalContainer>
      {userCanViewPay && (
        <>
          <Text variant="h5">{title}</Text>
          <Divider
            wrapperStyle={{ margin: `${theme.space.xxs}px 0`, width: '100%' }}
          />
          {lines.map((l) => (
            <Row
              key={l.label}
              alignCenter
              justifyBetween
              style={{ marginBottom: theme.space.xxs }}
            >
              <Text variant="body1">{l.label}</Text>
              <Text variant="body1">{l.value}</Text>
            </Row>
          ))}
          {!isInfiniteSchedule && shiftCount > 1 ? (
            <Row alignCenter style={{ justifyContent: 'flex-end' }}>
              <Text variant="body1">x {shiftCount} shifts</Text>
            </Row>
          ) : null}
          <Divider
            wrapperStyle={{ margin: `${theme.space.xxs}px 0`, width: '100%' }}
          />
          <Row alignCenter justifyBetween>
            <Text variant="h7">{totalPayHeader}</Text>
            <Text variant="h7">{totalPayText}</Text>
          </Row>
        </>
      )}
      {agreementText}
      {company?.agreements?.acceptedFeesAndTerms ? null : (
        <Checkbox
          value={!!shiftRequestMetadata.acceptedFeesAndTerms}
          defaultChecked={!!shiftRequestMetadata.acceptedFeesAndTerms}
          onChange={() => {
            setShiftRequestMetadata({
              acceptedFeesAndTerms: shiftRequestMetadata.acceptedFeesAndTerms
                ? undefined
                : new Date(),
            })
            const action = !shiftRequestMetadata.acceptedFeesAndTerms
              ? 'Accepted'
              : 'Denied'
            window.analytics.track(`Business ${action} Cancellation Policy`)
          }}
          label={<Text variant="body1">I agree to the terms above.</Text>}
          labelStyle={{ margin: `${theme.space.xs}px 0` }}
        />
      )}
      <Button
        onClick={onClick}
        loading={loading}
        full
        aria-label={`confirm-and-book`}
        disabled={
          !company?.agreements?.acceptedFeesAndTerms &&
          !shiftRequestMetadata.acceptedFeesAndTerms
        }
      >
        Confirm and book
      </Button>
    </S.EstimatedTotalContainer>
  )
}
