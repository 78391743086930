import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { CreateSchedule, WorkerShiftAvailabilityResponse } from '@traba/types'
import { getErrorMessage } from '@traba/utils'
import { trabaApi } from 'src/api/helpers'

async function getWorkerAvailabilities(
  workerIds: string[],
  shiftInfo?: { schedules: CreateSchedule[] } | { shiftId: string },
): Promise<WorkerShiftAvailabilityResponse> {
  if (!shiftInfo) {
    return { availabilities: [] }
  }
  try {
    const res = await trabaApi.post('/my-company/worker-availabilities', {
      workerIds,
      shiftInfo,
    })
    return res.data
  } catch (error) {
    const errorMessage = getErrorMessage(error)
    const fullErrorMessage = `useCompanyConnections ERROR: ${errorMessage}`
    Sentry.captureException(fullErrorMessage)
    throw error
  }
}

export function useWorkerAvailabilities({
  workerIds,
  shiftInfo,
}: {
  workerIds: string[]
  shiftInfo?: { shiftId: string } | { schedules: CreateSchedule[] }
}) {
  const {
    isLoading,
    isError,
    isFetched,
    data: response,
    error,
    refetch,
  } = useQuery<WorkerShiftAvailabilityResponse, Error>({
    queryKey: ['worker_availabilities', workerIds, shiftInfo],
    queryFn: () => getWorkerAvailabilities(workerIds, shiftInfo),
    staleTime: FIVE_MINUTES_IN_MS,
  })

  const availabilities = response?.availabilities || []
  const workerAvailabilitiesByWorkerId = new Map(
    availabilities.map((a) => [a.workerId, a]),
  )

  return {
    isLoading,
    isError,
    isFetched,
    workerAvailabilitiesByWorkerId,
    error,
    refetch,
  }
}
