import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'
import { CoordinatesDto } from '@traba/types'
import { trabaApi } from 'src/api/helpers'

const WORKER_DISTANCES_QUERY_KEY = 'workerDistances'

export type WorkerZipCodes = {
  workerId: string
  zipCode?: string
}[]

const getWorkerDistances = async (
  workerZipCodes: WorkerZipCodes,
  shiftCoords: CoordinatesDto | undefined,
): Promise<Record<string, number>> => {
  if (!shiftCoords || workerZipCodes.length === 0) {
    return {}
  }
  try {
    const { data } = await trabaApi.post(`/google/query-geocode-for-business`, {
      workerZipCodes,
      shiftCoords,
    })
    return data
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
    throw error
  }
  return {}
}

export const useWorkerDistances = (
  workerZipCodes: WorkerZipCodes,
  shiftCoords: CoordinatesDto | undefined,
) => {
  const {
    isLoading,
    isError,
    data: workerDistances,
    error,
    isFetched,
  } = useQuery({
    queryKey: [
      WORKER_DISTANCES_QUERY_KEY,
      shiftCoords?.latitude,
      shiftCoords?.longitude,
      workerZipCodes.map((w) => `${w.workerId}:${w.zipCode}`).join(','),
    ],
    queryFn: () => getWorkerDistances(workerZipCodes, shiftCoords),
  })

  return {
    isLoading,
    isError,
    error,
    isFetched,
    workerDistances,
  }
}
