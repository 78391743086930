import { useAlert } from '@traba/context'
import { Anchor } from '@traba/react-components'
import { Company, CompanyCategoryValues } from '@traba/types'
import { InputStatus } from '@traba/types'
import { useForm } from '@traba/utils'
import { useFormik } from 'formik'
import Cookies from 'js-cookie'
import { useState } from 'react'
import { Location } from 'react-router-dom'
import { trabaApi } from 'src/api/helpers'
import { BackButton } from 'src/components/auth'
import {
  Button,
  Checkbox,
  Input,
  InputPhone,
  Row,
  Text,
} from 'src/components/base'
import { SelectDropdown } from 'src/components/base/Select/Select'
import BorderBox from 'src/components/BorderBox'
import PlacesAutocomplete from 'src/components/PlacesAutocomplete/PlacesAutocomplete'
import { useUserContext } from 'src/context/user/UserContext'
import useAppAuth from 'src/hooks/authHook'
import { useBilling } from 'src/hooks/useBilling'
import { useCompany } from 'src/hooks/useCompany'
import useMobile from 'src/hooks/useMobile'
import {
  PREFILLED_INFO_BUSINESS_NAME,
  PREFILLED_INFO_CITY,
  REQUIRED_FIELD_MESSAGE,
  VALID_ADDRESS,
} from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import { Address, ApiError, BusinessReferral } from 'src/types'
import { WebToRNEventName } from 'src/types/events'
import { email, phoneNumber } from 'src/utils/formUtils'
import * as yup from 'yup'

type CompanyDetailsFormProps = {
  windowLocation: Location
}

export const CompanyDetailsForm = (props: CompanyDetailsFormProps) => {
  const { windowLocation } = props
  const { isReactNativeApp } = useMobile()
  const [isLoading, setIsLoading] = useState(false)
  const { handleOnSubmitError } = useForm()
  const userContext = useUserContext()
  const { company, patchCompany, refetch } = useCompany(
    !!userContext.state.userProfile?.companyId,
  )
  const { showSuccess, showError } = useAlert()
  const { startStripeSetupIntent } = useBilling()
  const { handleLogout } = useAppAuth()

  async function completeOnboarding() {
    // Trigger this when signup is complete
    if (isReactNativeApp) {
      try {
        const { data: token } = await trabaApi.get(`/me/custom-token`)
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            event: WebToRNEventName.SIGN_UP_COMPLETE,
            data: {
              token,
            },
          }),
        )
      } catch (error) {
        console.log(error)
      }
    }

    const { data } = await trabaApi.patch('/me', {
      onboarding: { hasCompleted: true },
    })
    localStorage.removeItem(PREFILLED_INFO_BUSINESS_NAME)
    localStorage.removeItem(PREFILLED_INFO_CITY)
    userContext.dispatch({
      type: 'EDIT_USER',
      value: data,
    })
  }

  const initialFormValues = {
    employerName:
      company?.employerName ||
      localStorage.getItem(PREFILLED_INFO_BUSINESS_NAME) ||
      '',
    category: (company?.category as string) || '',
    referralCode: company?.referralCode || Cookies.get('referralCode'),
    // values we want them to fill in each time, no defaults
    agreements: {
      acceptedCustomerAgreement: undefined,
    },
    address: {
      city: '',
      street1: '',
      street2: '',
      postalCode: '',
      state: '',
    },
    coords: {
      latitude: 0,
      longitude: 0,
    },
    shortLocation: '',
    billingPhoneNumber: '',
    billingEmail: '',
  }

  const validationSchema = yup.object({
    billingPhoneNumber: phoneNumber(),
    billingEmail: email(),
    employerName: yup.string().required(REQUIRED_FIELD_MESSAGE),
    category: yup.string().required(REQUIRED_FIELD_MESSAGE),
    address: yup.object({
      city: yup.string().required(REQUIRED_FIELD_MESSAGE),
      street1: yup.string().required(REQUIRED_FIELD_MESSAGE),
      street2: yup.string(),
      postalCode: yup.string().required(REQUIRED_FIELD_MESSAGE),
      state: yup.string().required(REQUIRED_FIELD_MESSAGE),
    }),
    coords: yup.object({
      latitude: yup
        .number()
        .required(VALID_ADDRESS)
        .notOneOf([0], VALID_ADDRESS),
      longitude: yup
        .number()
        .required(VALID_ADDRESS)
        .notOneOf([0], VALID_ADDRESS),
    }),
    agreements: yup.object({
      acceptedCustomerAgreement: yup
        .date()
        .required('You must accept the customer agreement to continue.')
        .nullable()
        .default(undefined),
    }),
  })
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema,
    onSubmit: async (values, actions) => {
      try {
        setIsLoading(true)

        values = {
          ...values,
          referralCode: values.referralCode?.toUpperCase(),
        }

        const companyChangedReferralCode =
          company?.referralCode !== values.referralCode

        if (
          (!company?.referralCode || companyChangedReferralCode) &&
          values.referralCode
        ) {
          try {
            const referralCodeResponse = await trabaApi.get<BusinessReferral>(
              '/users/referralCode',
              {
                params: {
                  referralCode: values.referralCode,
                },
              },
            )
            if (company && referralCodeResponse.data) {
              company.referralCode = values.referralCode
              showSuccess(
                'Referral code has been successfully applied to your account.',
              )
            }
          } catch (err) {
            const error = err as ApiError
            const errorMessage: string =
              error.message || error.error || 'Error applying referral code'

            actions.setFieldError('referralCode', errorMessage)
            handleOnSubmitError(error, formik)
            setIsLoading(false)
            return
          }
        }

        const { billingEmail, billingPhoneNumber, ...companyDetails } = values
        // If the company already exists, update it. Else, create the company
        // and refresh the current user to get appropriate companyId, role, and
        // permissions.
        if (company?.companyId) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          try {
            await submitWithoutPaymentMethod({
              email: billingEmail,
              phoneNumber: billingPhoneNumber,
            })
          } catch (err) {
            showError(
              'Error submitting billing information, please check if your email and phone number are correct',
            )
            handleOnSubmitError(err, formik)
            setIsLoading(false)
            return
          }
          const patchWithoutAddress = companyDetails as Partial<Company>
          delete patchWithoutAddress.address
          await patchCompany(patchWithoutAddress)
        } else {
          await trabaApi.post('/companies', companyDetails)
          const user = (await trabaApi.get('/me')).data
          userContext.dispatch({
            type: 'EDIT_USER',
            value: user,
          })
          try {
            await submitWithoutPaymentMethod({
              email: billingEmail,
              phoneNumber: billingPhoneNumber,
            })
          } catch (err) {
            showError(
              'Error submitting billing information, please check if your email and phone number are correct',
            )
            handleOnSubmitError(err, formik)
            setIsLoading(false)
            return
          }
        }

        await completeOnboarding()
        // navigate('/billing-setup')
      } catch (err) {
        showError('Error creating company, please try again')
        handleOnSubmitError(err, formik)
        await refetch()
        setIsLoading(false)
      }
    },
  })

  function handleChangePlacesAutocomplete(val: Address) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { location, ...rest } = val
    formik.setFieldValue('address', rest)
    formik.setFieldValue('coords', val.location)
    formik.setFieldValue('shortLocation', val.shortLocation)
    setTimeout(formik.validateForm, 0) // Delay validation until after state update
  }

  async function createStripeBilling({
    values,
  }: {
    values: { email: string; phoneNumber: string }
  }) {
    const checkoutSessionId = await startStripeSetupIntent({
      email: values.email,
      phoneNumber: values.phoneNumber,
      returnPath: '/billing-setup',
    })
    return checkoutSessionId
  }

  async function submitWithoutPaymentMethod(values: {
    email: string
    phoneNumber: string
  }) {
    await createStripeBilling({
      values,
    })
  }

  const { errors, touched, values } = formik
  const pageTitle = 'Almost there! A few company details so we can set you up.'

  return (
    <form onSubmit={formik.handleSubmit}>
      <Row mb={theme.space.xxs}>
        {windowLocation.state?.isFromBasicDetailOrEmailVerification && (
          <BackButton buttonText="" style={{ marginRight: theme.space.xxs }} />
        )}
        <Text variant="h6">
          {windowLocation.state?.isFromBasicDetailOrEmailVerification
            ? 'Step 2/2'
            : ' Step 3/3'}
        </Text>
      </Row>
      <Text variant={isReactNativeApp ? 'h5' : 'h2'}>{pageTitle}</Text>
      <Input
        full
        label="Company name"
        {...formik.getFieldProps('employerName')}
        inputStatus={touched.employerName && errors.employerName ? 3 : 1}
        errorMessage={errors.employerName}
      />
      <SelectDropdown
        label="Business Type"
        menuItems={Object.entries(CompanyCategoryValues).map(
          ([value, label]) => {
            return { value, label }
          },
        )}
        value={formik.values?.category}
        handleSelect={(val) => {
          formik.setFieldValue('category', val)
        }}
        inputStatus={
          touched.category && errors.category
            ? InputStatus.error
            : InputStatus.default
        }
        errorMessage={errors.category}
        style={{ marginTop: theme.space.sm }}
      />
      <Row mt={24}>
        <PlacesAutocomplete
          onSelect={handleChangePlacesAutocomplete}
          onChange={(val) => formik.setFieldValue('address.street1', val)}
          value={formik.values?.address?.street1}
          label="Street Address"
          errorMessage={
            touched.address?.street1
              ? errors.address?.street1 ||
                errors.coords?.latitude ||
                errors.coords?.longitude
              : null
          }
        />
      </Row>
      <Input
        full
        label="Floor/Suite (optional)"
        {...formik.getFieldProps('address.street2')}
        inputStatus={
          touched.address?.street2 && errors.address?.street2 && formik.touched
            ? 3
            : 1
        }
        errorMessage={errors.address?.street2}
      />
      <Input
        full
        label="City"
        {...formik.getFieldProps('address.city')}
        inputStatus={
          touched.address?.city && errors.address?.city && formik.touched
            ? 3
            : 1
        }
        errorMessage={errors.address?.city}
      />
      <Input
        full
        label="State / Province"
        {...formik.getFieldProps('address.state')}
        inputStatus={touched.address?.state && errors.address?.state ? 3 : 1}
        errorMessage={errors.address?.state}
      />
      <Input
        type="text"
        pattern="\d*"
        maxLength={5}
        full
        label="ZIP Code"
        {...formik.getFieldProps('address.postalCode')}
        inputStatus={
          touched.address?.postalCode && errors.address?.postalCode ? 3 : 1
        }
        errorMessage={errors.address?.postalCode}
      />
      <Input
        full
        label="Business Referral Code (optional)"
        {...formik.getFieldProps('referralCode')}
        inputStatus={touched.referralCode && errors.referralCode ? 3 : 1}
        errorMessage={errors.referralCode}
      />
      <Input
        full
        label="Billing email address"
        {...formik.getFieldProps('billingEmail')}
        inputStatus={touched.billingEmail && errors.billingEmail ? 3 : 1}
        errorMessage={errors.billingEmail}
      />
      <InputPhone
        specialLabel="Billing phone number"
        value={formik.values.billingPhoneNumber}
        onChange={(val) =>
          formik.handleChange({
            target: { value: val, name: 'billingPhoneNumber' },
          })
        }
        error={touched.billingPhoneNumber ? errors.billingPhoneNumber : null}
      />
      <Row pb={theme.space.xs}>
        <BorderBox
          id="onboarding-consent-checkbox"
          onChange={() => {
            formik.setErrors({
              agreements: { acceptedCustomerAgreement: undefined },
            })
            if (values.agreements.acceptedCustomerAgreement) {
              // this is also toggling the value, as a checkbox change event would be mean setting the Date back to undefined
              formik.setFieldValue(
                'agreements.acceptedCustomerAgreement',
                undefined,
              )
            } else {
              // this is also toggling the value, as a checkbox change event would be mean setting undefined back to a new Date
              formik.setFieldValue(
                'agreements.acceptedCustomerAgreement',
                new Date(),
              )
            }
          }}
          error={
            !!errors.agreements?.acceptedCustomerAgreement &&
            touched.agreements?.acceptedCustomerAgreement
          }
          helperText={
            touched.agreements?.acceptedCustomerAgreement &&
            errors.agreements?.acceptedCustomerAgreement
              ? errors.agreements?.acceptedCustomerAgreement
              : ''
          }
        >
          <Checkbox
            value={formik.values.agreements?.acceptedCustomerAgreement}
            label={
              <>
                By checking this box and creating an account, I agree that I
                have read and fully understand and agree to be bound by the{' '}
                <Anchor
                  openInNewTab={true}
                  href="https://www.traba.work/customer-agreement"
                >
                  Customer Agreement
                </Anchor>
                .
              </>
            }
          />
        </BorderBox>
      </Row>
      <Row mb={theme.space.sm}>
        <Button disabled={isLoading} full loading={isLoading}>
          Finish sign up
        </Button>
      </Row>
      {!isReactNativeApp && (
        <Row mb={20} center>
          <Text variant="body2">
            Already have a business account?{' '}
            <Text variant="link" onClick={handleLogout}>
              Log in
            </Text>
          </Text>
        </Row>
      )}
    </form>
  )
}
