import { Skeleton } from '@mui/material'
import { useHotSettings } from '@traba/hooks'
import { Button, ButtonVariant, Card, CardTile } from '@traba/react-components'
import { makePlural } from '@traba/string-utils'
import { BrandColors } from '@traba/theme'
import {
  CompanyCategory,
  ForwardFillMax,
  GenderPreference,
  RequiredMultiShiftType,
  ShiftPayType,
} from '@traba/types'
import { Dispatch, SetStateAction, useState } from 'react'
import React from 'react'
import { Col, Divider, InlineBanner, Row, SvgIcon, Text } from 'src/components'
import { GenderPreferenceSelector } from 'src/components/GenderPreferenceSelector'
import { RadioButton } from 'src/components/RadioButton'
import { useCompany } from 'src/hooks/useCompany'
import { useMembers } from 'src/hooks/useMembers'
import { useRoles } from 'src/hooks/useRoles'
import { BULLET_CHAR } from 'src/libs/constants'
import { theme } from 'src/libs/theme'
import RoleSelector from 'src/screens/AddToExistingSchedule/components/RoleSelector'
import { calculateSingleWorkerPay } from 'src/utils/moneyUtils'
import { BookShiftsProps } from '../BookShiftsScreen'
import { BookShiftStepSection } from '../components/BookShiftSection'
import { BookShiftsRMSASection } from '../components/BookShiftsRMSASection'
import { BookShiftsInvitationsSectionShiftDataModel } from '../steps/sections/BookShiftsInvitationsSectionShiftDataModel'
import { BookShiftsSiteContactSection } from '../steps/sections/BookShiftsSiteContactSection'
import { BookShiftsWorkersContentModal } from '../types'
import {
  MIN_WORKER_HOURLY_PAY_DEFAULT,
  getMinHourlyPayRate,
  validateShiftEdits,
} from '../validation'

interface BookShiftRoleSectionProps extends BookShiftsProps {
  setModalType: Dispatch<SetStateAction<BookShiftsWorkersContentModal | null>>
}

export function BookShiftRoleSection(props: BookShiftRoleSectionProps) {
  const {
    shiftRequest,
    setShiftRequest,
    isEdit,
    existingShiftInvitations,
    getWorkerById,
    selectedShifts,
    defaultShiftRequest,
    shiftUpdates,
    role,
    recurringRoles,
    updateRoleInfoForCreateShiftRequest,
    setModalType,
    userHasLimitedShiftManagementAbility,
    removeRole,
    selectedSingleShiftDates,
  } = props

  const { requiredMultiShiftType, schedules, locationId } = shiftRequest
  const { forwardFillMax, payType, payRate, numberOfUnits, slotsRequested } =
    role

  const { company } = useCompany()
  const { roles, isLoading: isLoadingRoles } = useRoles()
  const { members, isLoading: isLoadingMembers } = useMembers()
  const { hotSettings } = useHotSettings()
  const [genderPreference, setGenderPreference] = useState<
    GenderPreference | undefined
  >(role.genderPreference || shiftRequest.genderPreference || undefined)
  const [RMSAEnabled, setRMSAEnabled] = useState<boolean>(
    requiredMultiShiftType === RequiredMultiShiftType.ALL_IN_REQUEST,
  )

  function toggleRMSA(required: boolean) {
    setRMSAEnabled(required)
    if (!hotSettings?.allowRequiredMultiShiftInShiftRequest) {
      return
    }
    if (required) {
      setShiftRequest({
        requiredMultiShiftType: RequiredMultiShiftType.ALL_IN_REQUEST,
      })
    } else {
      setShiftRequest({
        requiredMultiShiftType: RequiredMultiShiftType.None,
      })
    }
  }

  const minHourlyPay = getMinHourlyPayRate({
    companyMinHourlyPay: company?.minHourlyPayRate,
    platformMinHourlyPay:
      hotSettings?.platformMinHourlyPayRate ?? MIN_WORKER_HOURLY_PAY_DEFAULT,
  })

  const editsError =
    selectedShifts &&
    defaultShiftRequest &&
    validateShiftEdits(defaultShiftRequest, selectedShifts, shiftUpdates)

  const isEventCompany = company?.category === CompanyCategory.EVENTS

  const pbuPayPerWorker =
    payType === ShiftPayType.UNIT && payRate && numberOfUnits && slotsRequested
      ? calculateSingleWorkerPay(
          {
            ...shiftRequest,
            ...schedules[0], // TODO(gavin): support multiple schedules
            payRate,
            payType,
            numberOfUnits: numberOfUnits || 0,
          },
          undefined,
        ).amount / 100
      : 0

  const { roleName } = roles.find((r) => r.roleId === role.roleId) ?? {}
  const [isExpanded, setIsExpanded] = useState<boolean>(!roleName)
  const supervisor = members.find((m) => m.uid === role.supervisorId)
  const supervisorLabel = supervisor
    ? `${BULLET_CHAR} Supervisor: ${supervisor.firstName} ${supervisor.lastName}`
    : ''
  const label = `${role.slotsRequested} ${roleName || 'Unselected Role'}${makePlural(role.slotsRequested)} @ $${payRate}/${payType === ShiftPayType.HOURLY ? 'hr' : 'unit'} ${supervisorLabel}`
  const isMissingField = !roleName || !role.slotsRequested || !supervisor
  if (isLoadingRoles || isLoadingMembers) {
    return (
      <>
        {Array.from(Array(4)).map((_, index) => (
          <React.Fragment key={`bookshiftrolesection_${index}`}>
            <Skeleton animation="pulse" width="100%" height="60px" />
            <Skeleton animation="pulse" width="100%" height="120px" />
          </React.Fragment>
        ))}
      </>
    )
  }

  if (isExpanded) {
    return (
      <Col
        style={{
          borderRadius: theme.space.ms,
          border: `1px solid ${theme.colors.MidnightBlue}`,
          padding: theme.space.sm,
          marginBottom: theme.space.sm,
        }}
      >
        <Row justifyBetween alignCenter>
          <Text variant="h4">Role & Workers</Text>
          <Button
            style={{ alignSelf: 'flex-end' }}
            variant={ButtonVariant.TEXT}
            onClick={() => {
              setIsExpanded(false)
            }}
          >
            - Collapse
          </Button>
        </Row>
        <RoleSelector
          key={role.roleId}
          selectedRole={role}
          recurringRoles={recurringRoles}
          updateRoleInfoForCreateShiftRequest={
            updateRoleInfoForCreateShiftRequest
          }
          roles={roles}
          minHourlyPay={minHourlyPay}
          schedules={schedules}
          setModalType={setModalType}
          userHasLimitedShiftManagementAbility={
            userHasLimitedShiftManagementAbility
          }
          isEdit={isEdit}
          isEventCompany={isEventCompany}
          pbuPayPerWorker={pbuPayPerWorker}
          locationId={locationId}
        />
        <Text variant="h5" style={{ marginBottom: theme.space.xxs }}>
          Which workers would you like to invite?
        </Text>

        <Text variant="body2" style={{ marginBottom: theme.space.xs }}>
          All Traba workers go through a vetting process and background checks.
        </Text>
        <Card
          isClickable
          onClick={() => {
            updateRoleInfoForCreateShiftRequest(
              {
                ...role,
                forwardFillMax: ForwardFillMax.NONE,
                shiftInvitations: undefined,
              },
              role.roleId,
            )
          }}
        >
          <Row alignCenter fullWidth justifyBetween>
            <Row style={{ marginRight: theme.space.xxs }}>
              <CardTile
                size={`${theme.space.xxl}px`}
                style={{
                  minWidth: theme.space.xxl,
                  marginRight: theme.space.xs,
                }}
              >
                <SvgIcon
                  name="twoUser"
                  size={theme.space.sm}
                  color={theme.colors.Grey50}
                />
              </CardTile>
              <Col>
                <Text color={theme.colors.MidnightBlue} variant="h5">
                  All
                </Text>
                <Text variant="body2">
                  This option will give you access to the largest number of
                  workers in your area who fit the requirements of your shift.
                </Text>
              </Col>
            </Row>
            <RadioButton
              selected={
                !forwardFillMax || forwardFillMax === ForwardFillMax.NONE
              }
            />
          </Row>
        </Card>
        <BookShiftsInvitationsSectionShiftDataModel
          updateRoleInfoForCreateShiftRequest={
            updateRoleInfoForCreateShiftRequest
          }
          role={role}
          locationId={shiftRequest.locationId}
          existingShiftInvitations={existingShiftInvitations}
          getWorkerById={getWorkerById}
          shiftRequest={shiftRequest}
          selectedSingleShiftDates={selectedSingleShiftDates}
        />

        {isEdit && editsError && (
          <InlineBanner
            style={{ marginTop: theme.space.xs }}
            severity={'error'}
            text={editsError.message}
          />
        )}
        {company?.allowGenderPreference && (
          <>
            <Divider
              wrapperStyle={{ margin: `${theme.space.sm}px 0`, width: '100%' }}
            />
            <GenderPreferenceSelector
              setSelectedGenderPreference={(gp) => {
                setGenderPreference(gp)
                updateRoleInfoForCreateShiftRequest(
                  { ...role, genderPreference: gp },
                  role.roleId,
                )
              }}
              selectedGenderPreference={genderPreference}
            />
          </>
        )}
        {schedules.some((s) => s.isRecurringSchedule) &&
          !isEdit &&
          company?.showRequiredMultiShiftToggle &&
          !!hotSettings?.allowRequiredMultiShiftInShiftRequest && (
            <>
              <Divider
                wrapperStyle={{
                  margin: `${theme.space.sm}px 0`,
                  width: '100%',
                }}
              />
              <BookShiftsRMSASection
                selection={RMSAEnabled}
                onClick={toggleRMSA}
              />
            </>
          )}
        <Divider
          wrapperStyle={{ margin: `${theme.space.sm}px 0`, width: '100%' }}
        />
        <BookShiftStepSection
          section={{
            title: 'Onsite contact',
            description:
              "Onsite contact will also receive text messages with shift clock in & out codes 30min before shift start time, unless they've opted out of Traba messages.",
            singular: 'contact',
            plural: 'contacts',
            options: members.reduce<{ value: string; label: string }[]>(
              (acc, m) => {
                if (m.uid) {
                  acc.push({
                    value: m.uid,
                    label: `${m.firstName} ${m.lastName}`,
                  })
                }
                return acc
              },
              [],
            ),
            selected: role.supervisorId,
            onChange: (v: string) => {
              updateRoleInfoForCreateShiftRequest(
                { ...role, supervisorId: v },
                role.roleId,
              )
              window.analytics.track(`User Selected Contact`, {
                supervisorId: v,
                isEdit,
              })
            },
            onCreate: () => {
              setModalType('CONTACT')
              window.analytics.track(`User Clicked Create New Contact`, {
                isEdit,
              })
            },
            optional: false,
            contentExpanded: true,
            hide: false,
            errorMessage:
              'The contact in the previous shift request was archived. Please select a new contact.',
            Content: BookShiftsSiteContactSection,
          }}
          bookShiftProps={props}
        />
      </Col>
    )
  }

  return (
    <Col
      style={{
        borderRadius: theme.space.ms,
        ...(isMissingField && { background: '#F7F7F8' }),
        border: `1px solid ${theme.colors.MidnightBlue}`,
        padding: theme.space.xs,
        marginBottom: theme.space.xs,
      }}
    >
      <Row alignCenter justifyBetween>
        <Row alignCenter>
          <Text variant="body1">{label}</Text>
        </Row>
        <Row alignCenter>
          <Button
            style={{ color: BrandColors.Violet }}
            rightIcon={<SvgIcon name="trash" color={BrandColors.Violet} />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={() => removeRole(role.roleId)}
          >
            Remove
          </Button>
          <Button
            variant={ButtonVariant.TEXT}
            style={{ color: theme.colors.Violet }}
            onClick={() => {
              setIsExpanded(true)
            }}
          >
            + Expand
          </Button>
        </Row>
      </Row>
    </Col>
  )
}
