import { AlertColor } from '@mui/material'
import { LoadingSpinner } from '@traba/react-components'
import {
  ShiftInvitation,
  ScheduledBreak,
  Shift,
  ShiftRequest,
  Worker,
  CreateShiftRequest,
  CreateShiftRequestMetadata,
  RoleInfoForCreateShiftRequest,
} from '@traba/types'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { trabaApi } from 'src/api/helpers'
import { ButtonVariant, MainLayout, Row, Text } from 'src/components'
import { EstimatedTotal } from 'src/components/EstimatedTotal/EstimatedTotal'
import useNavigationGuard from 'src/components/NavigationGuard/NavigationGuard'
import { useQueryParams } from 'src/helpers'
import { useCompany } from 'src/hooks/useCompany'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { useLocations } from 'src/hooks/useLocations'
import { useMembers } from 'src/hooks/useMembers'
import { useRoles } from 'src/hooks/useRoles'
import { useHotSettings } from 'src/hooks/useSystem'
import { useUser } from 'src/hooks/useUser'
import { theme } from 'src/libs/theme'
import { JobStatus, UserRole } from 'src/types'
import EditShiftsSelectedShiftsButton from '../EditShifts/EditShiftsSelectedShiftsButton'
import { EditShiftsSelectShiftsModal } from '../EditShifts/EditShiftsSelectShiftsContent'
import {
  EditWorkerSlotsEntryPoint,
  EditWorkerSlotsModal,
} from '../EditShifts/EditWorkerSlotsModal'
import { useBookShiftsScreen } from './BookShiftsScreen.hooks'
import * as S from './BookShiftsScreen.styles'
import { StartOverButton } from './components/StartOverButton'
import { SelectShiftRequestModal } from './SelectShiftRequestModal'
import { EditOperation } from './types'

export interface BookShiftsProps {
  shiftRequest: CreateShiftRequest
  setShiftRequest: Dispatch<React.SetStateAction<Partial<CreateShiftRequest>>>
  shiftRequestMetadata: CreateShiftRequestMetadata
  selectedSingleShiftDates: Date[] | null
  setSelectSingleShiftDates: Dispatch<React.SetStateAction<Date[] | null>>
  setShiftRequestMetadata: (d: Partial<CreateShiftRequestMetadata>) => void
  onContinue: () => void
  onPrevious: () => void
  onNavigate: (step: number) => void
  onSaveChanges: () => Promise<void>
  isEdit?: boolean
  isRebook?: boolean
  selectedShifts?: Shift[]
  setShowSelectModal?: (show: boolean) => void
  shiftUpdates: Partial<CreateShiftRequest>
  editsResult?: { title: string; severity: AlertColor; subTitle?: string }
  defaultShiftRequest?: CreateShiftRequest
  userHasLimitedShiftManagementAbility: boolean
  showRequiredMultiShiftToggle: boolean
  showGenderPreferenceSelection: boolean
  existingShiftInvitations?: ShiftInvitation[]
  getWorkerById: (uid: string) => Worker
  defaultBreaks?: ScheduledBreak
  initialShift?: Shift
  role: RoleInfoForCreateShiftRequest
  recurringRoles: RoleInfoForCreateShiftRequest[]
  setRecurringRoles: Dispatch<SetStateAction<RoleInfoForCreateShiftRequest[]>>
  addNewRole: () => void
  updateRoleInfoForCreateShiftRequest: (
    updatedRoleInfoForCreateShiftRequest: RoleInfoForCreateShiftRequest,
    originalRoleId: string,
  ) => void
  removeRole: (roleId: string) => void
}

export function BookShiftsScreen() {
  const query = useQueryParams()
  const { isLoading: isLoadingMembers } = useMembers()
  const { isLoading: isLoadingRoles } = useRoles()
  const { isLoading: isLoadingLocations } = useLocations()
  const { isLoading: isLoadingHotSettings } = useHotSettings()
  const { getActiveInvoiceGroupById } = useInvoiceGroups()

  const shiftRequestId = query.get('rebookingId')
  const { data: shiftRequestTemplate, isLoading } = useQuery<
    ShiftRequest,
    Error
  >(
    ['shiftRequestById', shiftRequestId],
    async () => {
      const shiftRequestResult = await trabaApi.get(
        `my-company/shift-requests/${shiftRequestId}`,
      )
      const shiftRequest: ShiftRequest = shiftRequestResult?.data

      const invoiceGroup = shiftRequest.parentInvoiceGroupId
        ? await getActiveInvoiceGroupById(shiftRequest.parentInvoiceGroupId)
        : undefined

      // Only set the default invoice group if it's not a one time group
      return {
        ...shiftRequest,
        parentInvoiceGroupId:
          invoiceGroup?.recurring === true
            ? shiftRequest.parentInvoiceGroupId
            : undefined,
        shiftRequestParentId: undefined,
      }
    },
    { enabled: !!shiftRequestId, retry: false, staleTime: Infinity },
  )

  const { data: shiftRequests, isLoading: isLoadingShiftRequests } = useQuery<
    ShiftRequest[] | undefined,
    Error
  >(['shiftRequestsByCreator'], () =>
    trabaApi
      .get(`my-company/shift-requests?byCreator=true`)
      .then((r) => r.data),
  )

  return (
    <MainLayout hideSidebar title="Book Shifts">
      {isLoading ||
      isLoadingShiftRequests ||
      isLoadingMembers ||
      isLoadingRoles ||
      isLoadingLocations ||
      isLoadingHotSettings ? (
        <LoadingSpinner />
      ) : (
        <BookShiftsScreenUI
          shiftRequests={shiftRequests}
          shiftRequestTemplate={shiftRequestTemplate}
        />
      )}
    </MainLayout>
  )
}

export function BookShiftsScreenUI({
  shiftRequestTemplate,
  shiftTemplate,
  shiftRequests,
  isEdit,
  editOperation,
}: {
  shiftRequestTemplate?: ShiftRequest
  shiftTemplate?: Shift
  shiftRequests?: ShiftRequest[]
  isEdit?: boolean
  editOperation?: EditOperation
}) {
  const navigate = useNavigate()
  const { user } = useUser()
  const { company } = useCompany()
  const {
    activeStep,
    activeStepRef,
    onBook,
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    isBookingInDraft,
    isBookingInDraftRef,
    completedThroughStep,
    onNavigate,
    onContinue,
    onPrevious,
    onSaveChanges,
    scrollContainer,
    steps,
    selectedShifts,
    setSelectedShifts,
    showSelectModal,
    setShowSelectModal,
    shiftUpdates,
    editsResult,
    defaultShiftRequest,
    isSelectShiftRequestModalOpen,
    setIsSelectShiftRequestModalOpen,
    isCreatingOrUpdating,
    editSlotsModalProps,
    existingShiftInvitations,
    getWorkerById,
    setSelectedSingleShiftDates,
    selectedSingleShiftDates,
    recurringRoles,
    setRecurringRoles,
    updateRoleInfoForCreateShiftRequest,
    addNewRole,
    removeRole,
  } = useBookShiftsScreen({
    shiftRequestTemplate,
    shiftTemplate,
    isEdit,
    editOperation: editOperation,
    shiftRequests,
  })

  const {
    isEditSlotsModalOpen,
    setIsEditSlotsModalOpen,
    workersOnShift,
    workersOnBackup,
    workers,
    handleUpdateSlotsRequested,
    shiftsRequiringWorkerRemoval,
  } = editSlotsModalProps

  const [selectedShiftRequest, setSelectedShiftRequest] = useState<
    string | undefined
  >()

  useNavigationGuard({
    when: !!isBookingInDraft && !isCreatingOrUpdating,
    message: isEdit
      ? "You haven't saved the changes. Are you sure you want to leave?"
      : "You haven't completed booking your shift. Are you sure you want to leave?",
  })

  const location = useLocation()

  // runs the abandon flow track event effect when react-router location changes
  useEffect(() => {
    return () => {
      isBookingInDraftRef?.current &&
        window.analytics.track(`User Abandoned Book Shifts`, {
          step: steps[activeStepRef?.current || 0].title,
        })
    }
  }, [location.pathname])

  const isLimitedShiftCreator = user?.role === UserRole.LimitedShiftCreator

  const confirmContent =
    activeStep !== steps.length - 1 || isEdit ? null : (
      <EstimatedTotal
        onBook={onBook}
        shiftRequest={shiftRequest}
        shiftRequestMetadata={shiftRequestMetadata}
        setShiftRequestMetadata={setShiftRequestMetadata}
        userCanViewPay={!isLimitedShiftCreator} // only limited shift creators can be on book shifts page but cant see pay
        selectedSingleShiftDates={selectedSingleShiftDates}
        recurringRoles={recurringRoles}
      />
    )

  const multipleShiftsSelected = selectedShifts.length > 1
  return (
    <>
      {isEdit && (
        <EditShiftsSelectShiftsModal
          shiftRequestId={shiftRequestTemplate?.shiftRequestId}
          initialShift={shiftTemplate}
          selectedShifts={selectedShifts}
          setSelectedShifts={setSelectedShifts}
          showSelectModal={showSelectModal}
          setShowSelectModal={setShowSelectModal}
        />
      )}

      {/* STEP TITLE */}
      <S.BookShiftsContainer>
        <div className="xs-hide md-show md-3">
          <S.BookShiftsNavContainer>
            <S.BookShiftsNav>
              {steps.map((s, i) => (
                <S.BookShiftsNavTitle
                  key={i}
                  active={i === activeStep}
                  disabled={!isEdit && i > completedThroughStep + 1}
                  onClick={() => onNavigate(i)}
                  style={{ ...(s.hideInSideBar ? { display: 'none' } : {}) }}
                >
                  {i === activeStep ? '• ' : ''}
                  {s.title}
                </S.BookShiftsNavTitle>
              ))}
            </S.BookShiftsNav>
          </S.BookShiftsNavContainer>
        </div>

        {/* STEP CONTENT */}
        <div className="xs-12 md-9" style={{ position: 'relative' }}>
          <div
            ref={scrollContainer}
            style={{ width: '100%', overflow: 'auto' }}
          >
            <S.BookShiftsContent>
              {steps.map(({ Component }, i) =>
                Component ? (
                  <S.BookShiftsComponentContainer
                    key={i}
                    active={activeStep === i}
                  >
                    {isEdit && (
                      <div
                        style={{
                          marginBottom: theme.space.sm,
                          display: 'flex',
                          gap: theme.space.xs,
                        }}
                      >
                        <Text
                          variant="h4"
                          style={{ marginBottom: theme.space.xxs }}
                        >
                          Edit shift
                          {multipleShiftsSelected ? `s` : null}
                        </Text>
                        {i === 0 && (
                          <EditShiftsSelectedShiftsButton
                            selectedShifts={selectedShifts}
                            setShowSelectModal={setShowSelectModal}
                          />
                        )}
                      </div>
                    )}

                    {/* MOBILE ONLY */}
                    {!isEdit && activeStep !== steps.length - 1 ? (
                      <Row justifyBetween alignCenter mb={theme.space.xs}>
                        <Text variant="h4" className="xs-show md-hide">
                          Book shifts
                        </Text>
                        <StartOverButton />
                      </Row>
                    ) : null}

                    {/* MAIN CONTENT */}
                    <Component
                      shiftRequest={shiftRequest}
                      setShiftRequest={setShiftRequest}
                      selectedSingleShiftDates={selectedSingleShiftDates}
                      setSelectSingleShiftDates={setSelectedSingleShiftDates}
                      shiftRequestMetadata={shiftRequestMetadata}
                      setShiftRequestMetadata={setShiftRequestMetadata}
                      onContinue={onContinue}
                      onPrevious={onPrevious}
                      onNavigate={onNavigate}
                      onSaveChanges={onSaveChanges}
                      selectedShifts={selectedShifts}
                      setShowSelectModal={setShowSelectModal}
                      isEdit={isEdit}
                      isRebook={!!shiftRequestTemplate}
                      shiftUpdates={shiftUpdates}
                      editsResult={editsResult}
                      defaultShiftRequest={defaultShiftRequest}
                      userHasLimitedShiftManagementAbility={
                        isLimitedShiftCreator
                      }
                      showRequiredMultiShiftToggle={
                        company?.showRequiredMultiShiftToggle
                      }
                      showGenderPreferenceSelection={
                        company?.allowGenderPreference
                      }
                      existingShiftInvitations={existingShiftInvitations}
                      getWorkerById={getWorkerById}
                      defaultBreaks={company?.defaultBreaks}
                      initialShift={shiftTemplate}
                      recurringRoles={recurringRoles}
                      setRecurringRoles={setRecurringRoles}
                      updateRoleInfoForCreateShiftRequest={
                        updateRoleInfoForCreateShiftRequest
                      }
                      addNewRole={addNewRole}
                      removeRole={removeRole}
                    />

                    {/* MEDIUM DOWN */}
                    {confirmContent ? (
                      <S.BookShiftsConfirmBlockBottom>
                        {confirmContent}
                      </S.BookShiftsConfirmBlockBottom>
                    ) : null}

                    {isEdit && activeStep === 0 ? (
                      <S.EditShiftsReturnButton
                        onClick={() => {
                          navigate(`/calendar/${shiftTemplate?.shiftId}`)
                        }}
                        variant={ButtonVariant.OUTLINED}
                      >
                        Return to Shift Details
                      </S.EditShiftsReturnButton>
                    ) : null}
                  </S.BookShiftsComponentContainer>
                ) : null,
              )}
            </S.BookShiftsContent>
          </div>

          {/* LARGE */}
          {confirmContent ? (
            <S.BookShiftsConfirmBlockRight>
              {confirmContent}
            </S.BookShiftsConfirmBlockRight>
          ) : null}
        </div>
        <SelectShiftRequestModal
          showSelectModal={isSelectShiftRequestModalOpen}
          setShowSelectModal={setIsSelectShiftRequestModalOpen}
          shiftRequests={shiftRequests}
          selected={selectedShiftRequest}
          setSelected={setSelectedShiftRequest}
          userHasLimitedShiftManagementAbility={isLimitedShiftCreator}
        />
        {!!selectedShifts.length && isEdit && (
          <EditWorkerSlotsModal
            shift={selectedShifts[0]}
            isOpen={isEditSlotsModalOpen}
            setIsOpen={setIsEditSlotsModalOpen}
            onClose={() => {
              setIsEditSlotsModalOpen(false)
            }}
            workerShifts={workersOnShift.filter(
              // Filter out canceled worker shifts from list of workers to remove
              (ws) => ws.jobStatus !== JobStatus.Canceled,
            )}
            workersOnBackup={workersOnBackup.filter(
              (ws) => ws.jobStatus !== JobStatus.Canceled,
            )}
            companyWorkers={workers}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClickInviteWorker={() => {}} // Invite more is disabled from this screen
            handleUpdateSlotsRequested={handleUpdateSlotsRequested}
            entryPoint={EditWorkerSlotsEntryPoint.EDIT_SHIFT_SCREEN}
            isMultiShiftEdit={selectedShifts.length > 1}
            shiftsRequiringWorkerRemoval={shiftsRequiringWorkerRemoval}
            newSlotsRequested={shiftUpdates?.slotsRequested}
          />
        )}
      </S.BookShiftsContainer>
    </>
  )
}
