import { Tooltip } from '@mui/material'
import { MODAL_SIZE } from '@traba/react-components'
import { DotMenu, DotMenuProps } from '@traba/react-components'
import { WorkerShift, Worker } from '@traba/types'
import { useState } from 'react'
import { ButtonIcon } from 'src/components/PersonalProfile/PersonalProfile.styles'
import { useAppContext } from 'src/context/appContext/AppContext'
import { useDownloadResume } from 'src/hooks/useDownloadResume'
import useMobile from 'src/hooks/useMobile'
import { theme } from 'src/libs/theme'

import AddToRosterMenu from '../AddToRosterMenu/AddToRosterMenu'
import { Button, ButtonVariant, Modal, Row, SvgIcon } from '../base'
import { BlockWorkerForm } from '../BlockWorker/BlockWorker'
import { RosterModal } from '../Modals/RosterModal/RosterModal'
import {
  RemoveConnectionConfirmationModal,
  RemoveConnectionConfirmationModalProps,
} from '../RemoveConnectionConfirmationModal/RemoveConnectionConfirmationModal'

type DotMenuItem = DotMenuProps['menuItems'][0]

export enum WorkerConnectionActionButton {
  DownloadResume = 'DownloadResume',
  ReviewWorker = 'ReviewWorker',
  Favorite = 'Favorite',
  Block = 'Block',
  AddToRoster = 'AddToRoster',
  MessageWorker = 'MessageWorker',
}

interface WorkerConnectionMenuProps {
  worker: Worker
  isFavorited: boolean
  isBlocked: boolean
  isIneligible: boolean
  isScheduledBlock: boolean
  alignEnd?: boolean
  onFavorite: () => void
  hidden: boolean
  workerShift?: WorkerShift
  isReviewed?: boolean
  onReview?: () => void
  buttonsToShow: WorkerConnectionActionButton[]
  displayAsDotMenu?: boolean
}

const DEFAULT_BUTTON_ORDER = [
  WorkerConnectionActionButton.DownloadResume,
  WorkerConnectionActionButton.MessageWorker,
  WorkerConnectionActionButton.ReviewWorker,
  WorkerConnectionActionButton.Favorite,
  WorkerConnectionActionButton.Block,
  WorkerConnectionActionButton.AddToRoster,
]

const shouldExcludeActionButtonFromReactNative = (
  action: WorkerConnectionActionButton,
  isReactNative: boolean,
) => {
  if (!isReactNative) {
    return false
  }
  return (
    action === WorkerConnectionActionButton.MessageWorker ||
    action === WorkerConnectionActionButton.AddToRoster
  )
}

export default function WorkerConnectionMenu({
  worker,
  isFavorited,
  isBlocked,
  isIneligible,
  isScheduledBlock,
  alignEnd,
  onFavorite,
  hidden,
  workerShift,
  isReviewed,
  onReview,
  buttonsToShow,
  displayAsDotMenu,
}: WorkerConnectionMenuProps) {
  const appContext = useAppContext()
  const {
    state: { isChatWidgetOpen, selectedWorkerId },
    setIsChatWidgetOpen,
  } = appContext
  const { isMobileViewOrReactNative, isReactNativeApp } = useMobile()

  const [showBlockModal, setShowBlockModal] = useState<boolean>(false)
  const [isRosterModalOpen, setIsRosterModalOpen] = useState(false)
  const [removeConnectionConfirmation, setRemoveConnectionConfirmation] =
    useState<RemoveConnectionConfirmationModalProps['type'] | undefined>()
  const { downloadResume, isLoading: resumeIsLoading } = useDownloadResume(
    worker.id || worker.uid,
  )

  if (hidden || isIneligible) {
    return null
  }
  if (buttonsToShow.length === 0) {
    return (
      <Row alignCenter style={{ justifyContent: 'center' }}>
        --
      </Row>
    )
  }

  function onClickDownloadResume() {
    if (resumeIsLoading) {
      return
    }
    downloadResume()
  }

  function onClickMessageWorker() {
    setIsChatWidgetOpen(true, worker.uid)
  }

  function onClickReviewWorker() {
    window.analytics.track(`User Clicked Review Worker`, {
      worker,
    })
    onReview && onReview()
  }

  function onClickFavoriteWorker() {
    if (isFavorited) {
      window.analytics.track(`User Clicked Unfavorite Worker`, {
        worker,
      })
      setRemoveConnectionConfirmation('favorite')
    } else {
      // Keeping both events for consistency w/ other connection changes.
      window.analytics.track(`User Clicked Favorite Worker`, {
        worker,
      })
      window.analytics.track(`Business Favorited Worker`, worker)
      // Favorite the worker and perform any side effects.
      onFavorite()
    }
  }

  function onClickBlock() {
    if (isBlocked || isScheduledBlock) {
      const analytics_message = isBlocked
        ? 'User Clicked Unblock Worker'
        : 'User Clicked Cancel Scheduled Block'
      window.analytics.track(analytics_message, {
        worker,
      })
      setRemoveConnectionConfirmation('blocked')
    } else {
      window.analytics.track(`User Clicked Block Worker`, {
        worker,
      })
      setShowBlockModal(true)
    }
  }

  function onClickNewRoster() {
    setIsRosterModalOpen(true)
  }

  function mapActionToButton(action: WorkerConnectionActionButton) {
    switch (action) {
      case WorkerConnectionActionButton.DownloadResume:
        return (
          <Button
            title="Download Resume"
            reverse
            variant={ButtonVariant.OUTLINED}
            style={{ marginRight: '4px' }}
            onClick={onClickDownloadResume}
            loading={resumeIsLoading}
            key={action}
          >
            Download Resume
          </Button>
        )
      case WorkerConnectionActionButton.MessageWorker:
        return (
          !isMobileViewOrReactNative && (
            <Tooltip
              title={
                isBlocked || isScheduledBlock
                  ? `Blocked workers can't be messaged`
                  : 'Message worker'
              }
              key={action}
            >
              <div style={{ display: 'flex' }}>
                <SvgIcon
                  name="message"
                  size={16}
                  onClick={onClickMessageWorker}
                  fill={
                    isChatWidgetOpen && selectedWorkerId === worker.uid
                      ? theme.colors.Violet
                      : undefined
                  }
                />
              </div>
            </Tooltip>
          )
        )
      case WorkerConnectionActionButton.ReviewWorker:
        return (
          <Tooltip title={'Review worker'} key={action}>
            <SvgIcon
              name="star"
              stroke={isReviewed ? theme.colors.brand : theme.colors.Grey50}
              fill={isReviewed ? theme.colors.brand : 'transparent'}
              size={16}
              strokeWidth={1.5}
              onClick={onClickReviewWorker}
            />
          </Tooltip>
        )
      case WorkerConnectionActionButton.Favorite:
        return isFavorited ? (
          <Tooltip title={'Remove from favorite workers list'} key={action}>
            <div style={{ display: 'flex' }}>
              <ButtonIcon name="heart_active" onClick={onClickFavoriteWorker} />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title={'Favorite worker'} key={action}>
            <div style={{ display: 'flex' }}>
              <ButtonIcon name="heart" onClick={onClickFavoriteWorker} />
            </div>
          </Tooltip>
        )
      case WorkerConnectionActionButton.Block:
        return isBlocked || isScheduledBlock ? (
          <Tooltip
            title={
              isBlocked
                ? 'Remove from blocked workers list'
                : 'Cancel Scheduled Block'
            }
            key={action}
          >
            <div style={{ display: 'flex' }}>
              <ButtonIcon
                onClick={onClickBlock}
                name={isBlocked ? 'block_active' : 'scheduled_block_active'}
              />
            </div>
          </Tooltip>
        ) : (
          <Tooltip title={'Block worker'} key={action}>
            <div style={{ display: 'flex' }}>
              <ButtonIcon name="block" onClick={onClickBlock} />
            </div>
          </Tooltip>
        )
      case WorkerConnectionActionButton.AddToRoster:
        return (
          !isReactNativeApp && (
            <Tooltip title={'Add worker to roster'}>
              <div style={{ display: 'flex' }}>
                <AddToRosterMenu
                  menuKey={worker.uid}
                  worker={worker}
                  onClickNewRoster={onClickNewRoster}
                  disabled={isBlocked}
                  key={action}
                  buttonVariant={ButtonVariant.TEXT}
                  buttonStyle={{ paddingLeft: 0 }}
                />
              </div>
            </Tooltip>
          )
        )
    }
  }

  function mapActionToDotMenuItem(
    action: WorkerConnectionActionButton,
  ): DotMenuItem | undefined {
    switch (action) {
      case WorkerConnectionActionButton.MessageWorker: {
        const title = 'Message worker'
        return {
          title: title,
          toolTip: title,
          onClick: onClickMessageWorker,
          color: theme.colors.MidnightBlue,
          iconName: 'message',
        }
      }
      case WorkerConnectionActionButton.Favorite: {
        const title = isFavorited
          ? `Remove from favorite workers list`
          : `Favorite worker`
        return {
          title: title,
          toolTip: title,
          onClick: onClickFavoriteWorker,
          color: theme.colors.MidnightBlue,
          iconName: 'heart',
          iconProps: {
            color: isFavorited ? theme.colors.Violet : undefined,
            fillColor: isFavorited ? theme.colors.Violet : undefined,
          },
        }
      }
      case WorkerConnectionActionButton.Block: {
        const title = isBlocked
          ? 'Remove from blocked workers list'
          : isScheduledBlock
            ? 'Cancel Scheduled Block'
            : 'Block worker'
        return {
          title: title,
          toolTip: title,
          onClick: onClickBlock,
          color: theme.colors.MidnightBlue,
          iconName: isScheduledBlock ? 'scheduledBlock' : 'block',
          iconProps: {
            color:
              isBlocked || isScheduledBlock ? theme.colors.Red60 : undefined,
          },
        }
      }
      case WorkerConnectionActionButton.ReviewWorker: {
        const title = isReviewed ? 'Edit review for worker' : 'Review worker'
        return {
          title: title,
          toolTip: title,
          onClick: onClickReviewWorker,
          color: theme.colors.MidnightBlue,
          iconName: 'star',
          iconProps: {
            color: isReviewed ? theme.colors.brand : theme.colors.Grey50,
            fillColor: isReviewed ? theme.colors.brand : undefined,
          },
        }
      }
      case WorkerConnectionActionButton.AddToRoster:
        return {
          title: 'Add to Roster',
          onClick: onClickNewRoster,
          color: theme.colors.MidnightBlue,
          iconName: 'menu',
          disabled: isBlocked,
          toolTip: isBlocked
            ? `This action is disabled for ${worker.firstName} because they are blocked`
            : 'Add to Roster',
        }
      default:
        return
    }
  }

  const orderedButtons = DEFAULT_BUTTON_ORDER.filter((button) =>
    buttonsToShow.includes(button),
  )

  const actions = orderedButtons.filter(
    (button) =>
      buttonsToShow.includes(button) &&
      !shouldExcludeActionButtonFromReactNative(button, isReactNativeApp),
  )

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: alignEnd ? 'end' : 'start',
      }}
    >
      {removeConnectionConfirmation && (
        <RemoveConnectionConfirmationModal
          isOpen={!!removeConnectionConfirmation}
          handleClose={() => setRemoveConnectionConfirmation(undefined)}
          type={removeConnectionConfirmation}
          worker={worker}
        />
      )}
      {displayAsDotMenu ? (
        <DotMenu
          type="worker-connection"
          key={'worker-connection'}
          menuItems={actions
            .map((action) => mapActionToDotMenuItem(action))
            .filter((item): item is DotMenuItem => !!item)}
          style={{
            height: isReactNativeApp ? 30 : undefined,
          }}
          showToolTipWhenDisabled={true}
        />
      ) : (
        <Row alignCenter gap={theme.space.xs}>
          {actions.map((action) => mapActionToButton(action))}
        </Row>
      )}
      <Modal
        size={MODAL_SIZE.LARGE}
        isOpen={showBlockModal}
        handleClose={() => setShowBlockModal(false)}
      >
        <BlockWorkerForm
          onClose={() => setShowBlockModal(false)}
          worker={worker}
          workerShift={workerShift}
        />
      </Modal>
      <RosterModal
        isOpen={isRosterModalOpen}
        setIsOpen={() => setIsRosterModalOpen(false)}
        workers={[worker]}
        initialSelectedWorkers={new Set([worker.uid])}
      />
    </div>
  )
}
