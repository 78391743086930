import { RecordStatus } from './base'
import { KioskModeType, WorkerMedia, WorkerMediaType } from './companies'

export const COMPANY_WIDE_ID = 'company-wide'
export const COMPANY_WIDE_TEXT = 'Company Wide'

export type Location = {
  latitude: number
  longitude: number
}

export interface AddressDto {
  city: string
  postalCode: string
  state: string
  street1: string
  street2?: string
}

export interface CoordinatesDto {
  latitude: number
  longitude: number
}

// TODO: move media type in Node server to shared-types and reuse here
export interface LocationMedia {
  imageUrl: string
  type: WorkerMediaType.IMAGE
  order: number
  visibility: 'ALL'
}

export interface OpsCreateLocationDto {
  name?: string
  shortLocation: string
  address: AddressDto
  locationInstructions: string
  coords: CoordinatesDto
  media?: string[]
  bypassRegionCheck?: boolean
  opsLocationDetails?: string
  neighborhoodName?: string
  disableLeftFencePromptOverride: boolean
  enableLeftFencePromptOverride: boolean
  opsOverride?: boolean
  kioskModeType?: KioskModeType
}

export interface UpdateLocationDto {
  name?: string
  address?: AddressDto
  coords?: CoordinatesDto
  shortLocation?: string
  locationInstructions?: string
  media?: string[]
}

export interface OpsUpdateLocationDto extends UpdateLocationDto {
  opsLocationDetails?: string
  opsOverride?: boolean
}

export interface UpdateLocationResponseDto {
  updatedLocation?: LocationResponse
  archivedLocationId?: string
}

export interface Locations {
  companyId: string
  address: AddressDto
  coords: CoordinatesDto
  locationId: string
  locationInstructions: string
  opsLocationDetails?: string
  shortLocation: string
  geohash: string
  timezone: string
  createdAt: Date
  name?: string
  neighborhoodName?: string
  regionId?: string
  media?: LocationMedia[]
  disableLeftFencePromptOverride: boolean
  enableLeftFencePromptOverride: boolean
  recordStatus?: RecordStatus
  kioskModeType?: KioskModeType
}

export type LocationResponse = {
  companyId: string
  shortLocation: string
  address: FullAddress
  locationInstructions: string
  coords: CoordinatesDto
  locationId: string
  regionId: string
  timezone: string
  name?: string
  media?: WorkerMedia[]
  recordStatus: RecordStatus
  createdAt: Date
  activeLocationId?: string
}

export type Address = {
  city: string
  postalCode: string
  state: string
  street1: string
  street2?: string
}

export interface FullAddress extends Address {
  location?: Location
  shortLocation?: string
}
