import * as Sentry from '@sentry/react'
import { FIVE_MINUTES_IN_MS } from '@traba/consts'
import { useHotSettings } from '@traba/hooks'
import {
  CompanyCategory,
  BehaviorAttribute,
  RoleAttribute,
  RoleAttributeStatus,
} from '@traba/types'
import { useQuery } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { useCompany } from './useCompany'

const getRoleAttributes = async (
  status?: RoleAttributeStatus,
  companyCategory?: CompanyCategory,
) => {
  try {
    const response = await trabaApi.get(
      `/role-attributes?locale=en&visibility=VISIBLE${
        status ? `&status=${status}` : ''
      }${companyCategory ? `&companyCategory=${companyCategory}` : ''}`,
    )
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

const getBehaviorAttributes = async (queryFromRoleAttributes = false) => {
  try {
    let response
    if (queryFromRoleAttributes) {
      response = await trabaApi.get(
        `/role-attributes/traits?locale=en&includeDeprecated=true`,
      )
    } else {
      response = await trabaApi.get(`/behavior-attributes`)
    }
    return response.data
  } catch (error) {
    Sentry.captureException(error)
  }
}

export function useRoleAttributes(
  status?: RoleAttributeStatus,
  enabled?: boolean,
) {
  const { company } = useCompany()

  const {
    isLoading,
    isError,
    data: roleAttributes,
    error,
    isFetched,
  } = useQuery<RoleAttribute[], Error>(
    ['role-attributes', status],
    () => getRoleAttributes(status, company?.category),
    { enabled },
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    roleAttributes,
  }
}

export function useBehaviorAttributes() {
  const { hotSettings } = useHotSettings()
  const queryFromRoleAttributes =
    !!hotSettings?.useBehaviorAttributesFromRoleAttributes
  const {
    isLoading,
    isError,
    data: behaviorAttributes,
    error,
    isFetched,
  } = useQuery<BehaviorAttribute[] | RoleAttribute[], Error>(
    [
      'behavior-attributes',
      {
        queryFromRoleAttributes,
      },
    ],
    () => getBehaviorAttributes(queryFromRoleAttributes),
    { staleTime: FIVE_MINUTES_IN_MS },
  )

  return {
    isLoading,
    isError,
    error,
    isFetched,
    behaviorAttributes,
  }
}
