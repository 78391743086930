import { makePlural } from '@traba/string-utils'
import { BrandColors } from '@traba/theme'
import {
  CreateShiftRequest,
  RoleInfoForCreateShiftRequest,
  ShiftPayType,
} from '@traba/types'
import { useState } from 'react'
import {
  Button,
  ButtonVariant,
  Text,
  Col,
  Row,
  SvgIcon,
} from 'src/components/base'
import { RoleData } from 'src/types'
import RoleSelector from './RoleSelector'

export default function RoleCheckbox({
  shiftRequest,
  selectedRole,
  recurringRoles,
  roles,
  updateRoleInfoForCreateShiftRequest,
  removeRole,
}: {
  shiftRequest: CreateShiftRequest
  selectedRole: RoleInfoForCreateShiftRequest
  recurringRoles: RoleInfoForCreateShiftRequest[]
  roles: RoleData[]
  updateRoleInfoForCreateShiftRequest: (
    updatedRoleInfoForCreateShiftRequest: RoleInfoForCreateShiftRequest,
    originalRoleId: string,
  ) => void
  removeRole: (roleId: string) => void
}) {
  const { slotsRequested, payRate, payType, roleId } = selectedRole
  const { roleName } = roles.find((r) => r.roleId === roleId) ?? {}

  const [isEdit, setIsEdit] = useState<boolean>(!roleName)

  const label = `${slotsRequested} ${roleName}${makePlural(slotsRequested)} @ $${payRate}/${payType === ShiftPayType.HOURLY ? 'hr' : 'unit'}`

  if (isEdit || !roleName) {
    return (
      <Col>
        <RoleSelector
          selectedRole={selectedRole}
          minHourlyPay={1}
          roles={roles}
          updateRoleInfoForCreateShiftRequest={
            updateRoleInfoForCreateShiftRequest
          }
          recurringRoles={recurringRoles}
          schedules={shiftRequest.schedules}
          setModalType={() => true}
          locationId={shiftRequest.locationId}
        />
        <Row>
          <Button
            variant={ButtonVariant.OUTLINED}
            onClick={() => setIsEdit(false)}
          >
            Save
          </Button>
        </Row>
      </Col>
    )
  }

  return (
    <Col>
      <Row alignCenter justifyBetween>
        <Row alignCenter>
          <Text variant="body1">{label}</Text>
        </Row>
        <Row alignCenter>
          <Button
            style={{ color: BrandColors.Violet }}
            rightIcon={<SvgIcon name="edit" color={BrandColors.Violet} />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={() => setIsEdit(true)}
          >
            Edit
          </Button>
          <Button
            style={{ color: BrandColors.Violet }}
            rightIcon={<SvgIcon name="trash" color={BrandColors.Violet} />}
            reverse
            variant={ButtonVariant.TEXT}
            onClick={() => removeRole(roleId)}
          >
            Delete
          </Button>
        </Row>
      </Row>
    </Col>
  )
}
