import { IMenuItem, MenuItemGroup, SearchSelect } from '@traba/react-components'
import {
  COMPANY_WIDE_ID,
  COMPANY_WIDE_TEXT,
  InputStatus,
  LocationResponse,
} from '@traba/types'
import {
  getAddressString,
  getLocationNameOrTruncatedAddress,
} from '@traba/utils'
import { useCallback, useMemo, useState } from 'react'
import { useValidRegionsMap } from 'src/hooks/useLocationRegions'
import { useRegionLocationMap } from 'src/hooks/useLocations'

interface Props {
  selectedLocationId?: string
  // If filterLocationId is provided, the options will be limited to the company-wide option
  // and the location with the provided filterLocationId
  filterLocationId?: string
  disabled?: boolean
  onChange?: (locationId: string | undefined) => void
  errorMessage?: string
  onBlur?: () => void
}

export function LocationSingleSearchSelector({
  selectedLocationId,
  filterLocationId,
  disabled,
  onChange,
  errorMessage,
  onBlur,
}: Props) {
  const { isLoading: isLocationsLoading, activeRegionsWithLocationsMap } =
    useRegionLocationMap()
  const { isLoading: isRegionsLoading, regionMap } = useValidRegionsMap()

  const regionGroups: MenuItemGroup[] = Object.keys(
    activeRegionsWithLocationsMap,
  ).map((regionId) => ({
    id: regionId,
    title: regionMap[regionId].displayName,
  }))

  const allGroups: MenuItemGroup[] = [
    { id: COMPANY_WIDE_ID, title: '', hideTitle: true },
    ...regionGroups,
  ]

  const companyWideOption = useMemo(
    () => ({
      value: COMPANY_WIDE_ID,
      label: COMPANY_WIDE_TEXT,
      groupId: COMPANY_WIDE_ID,
      secondaryLabel: 'Default Setting. The role can be used in any location.',
    }),
    [],
  )

  let filteredLocation: LocationResponse | undefined = undefined

  // We don't need this when we are showing all locations as options
  if (filterLocationId) {
    const allLocations = Object.values(activeRegionsWithLocationsMap).flat()
    filteredLocation = allLocations.find(
      (location) => location.locationId === filterLocationId,
    )
  }

  const locationOptions: IMenuItem[] = filteredLocation
    ? [
        {
          value: filteredLocation.locationId,
          label: getLocationNameOrTruncatedAddress(filteredLocation),
          secondaryLabel: getAddressString(filteredLocation.address),
          groupId: filteredLocation.regionId,
        },
      ]
    : regionGroups.flatMap(({ id: regionId }) =>
        activeRegionsWithLocationsMap[regionId].map((loc) => ({
          value: loc.locationId,
          label: getLocationNameOrTruncatedAddress(loc),
          secondaryLabel: getAddressString(loc.address),
          groupId: regionId,
        })),
      )
  const allOptions: IMenuItem[] = [companyWideOption, ...locationOptions]

  const [selectedItem, setSelectedItem] = useState(
    allOptions.find((item) => item.value === selectedLocationId) || undefined,
  )

  const onSelectLocation = useCallback(
    (newItem: IMenuItem | undefined) => {
      if (onChange) {
        onChange(newItem?.value)
      }
      setSelectedItem(newItem ?? companyWideOption)
    },
    [onChange, companyWideOption, setSelectedItem],
  )

  return (
    <SearchSelect
      label="Location"
      handleSelect={onSelectLocation}
      selectItem={selectedItem}
      isLoading={isLocationsLoading || isRegionsLoading}
      disabled={disabled || !onChange}
      style={{ width: '100%', maxWidth: '480px' }}
      labelStyle={{ fontSize: '12px' }}
      selectStyle={{ minHeight: '48px' }}
      options={allOptions}
      groupByGroup
      shouldAlsoSearchSecondaryLabel
      groups={allGroups}
      inputStatus={errorMessage ? InputStatus.error : undefined}
      errorMessage={errorMessage}
      onBlur={onBlur}
    />
  )
}
