import { useAlert } from '@traba/context'
import {
  RoleInfoForCreateShiftRequest,
  ShiftRequestParentWithShiftRequest,
} from '@traba/types'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useMobile from 'src/hooks/useMobile'
import {
  useBookShiftRequest,
  useShiftRequests,
} from 'src/hooks/useShiftRequests'
import { WebToRNEventName } from 'src/types/events'
import AddToExistingScheduleConfirmContent from './steps/AddToExistingScheduleConfirmContent'
import AddToExistingScheduleContent from './steps/AddToExistingScheduleContent'
import SelectAddToExistingScheduleOptions from './steps/SelectAddToExistingScheduleOptions'
import { getRoleInfoForCreateShiftRequest } from './utils'

type CreateShiftRequestParent = Omit<
  ShiftRequestParentWithShiftRequest,
  'shiftsRequestParentId' | 'companyId' | 'createdAt'
>

interface CreateShiftRequestParentMetadata {
  scheduleExpanded: boolean
}

type Step = {
  title: string
  Component: any
  validate?: (
    data: CreateShiftRequestParent,
    metadata: CreateShiftRequestParentMetadata,
  ) =>
    | {
        message: string
        title: string
      }
    | undefined
  hideInSideBar?: boolean
  type: StepType
}

enum StepType {
  SELECT_OPTIONS = 'SELECT_OPTIONS',
  SCHEDULE = 'SCHEDULE',
  CONFIRM = 'CONFIRM',
}

function getAddToExistingScheduleSteps(): Step[] {
  return [
    {
      title: 'Select Options',
      Component: SelectAddToExistingScheduleOptions,
      type: StepType.SELECT_OPTIONS,
    },
    {
      title: 'Add to Schedule',
      Component: AddToExistingScheduleContent,
      // TODO(gavin): add validation
      // validate: () => ({
      //   message: 'message',
      //   title: 'title',
      // }),
      type: StepType.SCHEDULE,
    },
    {
      title: 'Confirm',
      Component: AddToExistingScheduleConfirmContent,
      type: StepType.CONFIRM,
    },
  ]
}

export const useAddToExistingSchedulesScreen = ({
  shiftRequestParent,
}: {
  shiftRequestParent: ShiftRequestParentWithShiftRequest
}) => {
  const {
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
  } = useBookShiftRequest({
    shiftRequestTemplate: shiftRequestParent.shiftRequests[0],
    ignoreRecurrence: true,
  })

  const scrollContainer = useRef<HTMLDivElement>(null)
  const { showError } = useAlert()
  const navigate = useNavigate()
  const { isReactNativeApp } = useMobile()
  const { bulkCreateShiftRequest } = useShiftRequests()

  const steps = getAddToExistingScheduleSteps()

  const [activeStep, setActiveStep] = useState(0)
  const [completedThroughStep, setCompletedThroughStep] = useState(-1)
  const [recurringRoles, setRecurringRoles] = useState<
    RoleInfoForCreateShiftRequest[]
  >(
    shiftRequestParent.shiftRequests
      .filter((sr) => sr.schedules.some((s) => s.isRecurringSchedule))
      .map((sr) => ({
        roleId: sr.roleId,
        payType: sr.payType,
        payRate: sr.payRate,
        numberOfUnits: sr.numberOfUnits,
        slotsRequested: sr.slotsRequested,
        minSlotsRequested: sr.minSlotsRequested,
        supervisorId: sr.supervisorId,
      })),
  )

  function onNavigate(step: number) {
    // Too far back
    if (step < 0) {
      return
    }
    // Too far forward
    if (step >= steps.length) {
      return
    }
    // Validate if advancing completion or active step already completed
    const shouldValidate =
      step > activeStep || activeStep <= completedThroughStep
    if (shouldValidate) {
      const { validate } = steps[activeStep]
      if (validate) {
        const error = validate(
          shiftRequestParent as ShiftRequestParentWithShiftRequest,
          {} as CreateShiftRequestParentMetadata,
        )
        if (error) {
          showError(error.message, error.title)
          return
        }
      }
    }
    const newCompletedThroughStep = Math.max(step - 1, completedThroughStep)
    // Past completion point
    if (step > newCompletedThroughStep + 1) {
      return
    }
    // Update the step and completion point, if needed
    setActiveStep(step)
    setCompletedThroughStep(newCompletedThroughStep)
    // Scroll to top on step change
    scrollContainer.current?.scroll(0, 0)
    // Track the user's progress
    window.analytics.track(
      `User Navigated to Add To Existing Schedule ${steps[step].title} Step`,
    )
  }

  function onContinue() {
    onNavigate(activeStep + 1)
    window.scrollTo(0, 0)
  }

  function onPrevious() {
    setActiveStep(activeStep - 1)
    window.scrollTo(0, 0)
  }

  async function onBook() {
    // TODO(gavin): add analytics and validation
    // window.analytics.track(`User Submitted Shift Booking Request`, {
    //   ...shiftRequest,
    // })

    // const errors = steps
    //   .map((s) =>
    //     s.validate ? s.validate(shiftRequest, shiftRequestMetadata) : undefined,
    //   )
    //   .filter((e) => !!e)

    // if (errors[0]) {
    //   showError(errors[0].message, errors[0].title)
    //   return
    // }

    const response = await bulkCreateShiftRequest(
      recurringRoles.map((role) => ({ ...shiftRequest, ...role })),
    )

    if (response?.status === 201) {
      if (isReactNativeApp) {
        return window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            event: WebToRNEventName.SHIFT_BOOKED,
          }),
        )
      }
      navigate(`/calendar/${response.data.firstShiftId}`, {
        state: {
          modalType: 'create-shift-success',
          shiftCount: 1,
        },
      })
    } else {
      console.error(
        'Not all shift requests were created successfully',
        JSON.stringify(response, null, 2),
      )
    }
  }

  function addNewRole() {
    const newRole = getRoleInfoForCreateShiftRequest({ shiftRequest })
    setRecurringRoles((prev) => [...prev, newRole])
  }

  function removeRole(roleId: string) {
    setRecurringRoles((prev) => prev.filter((r) => r.roleId !== roleId))
  }

  function updateRoleInfoForCreateShiftRequest(
    updatedRoleInfoForCreateShiftRequest: RoleInfoForCreateShiftRequest,
    originalRoleId: string,
  ) {
    setRecurringRoles((prevRecurringRoles) => {
      if (originalRoleId) {
        return prevRecurringRoles.map((r) =>
          r.roleId === originalRoleId
            ? updatedRoleInfoForCreateShiftRequest
            : r,
        )
      } else {
        let replaced = false
        return prevRecurringRoles.map((r) => {
          if (!r.roleId && !replaced) {
            replaced = true
            return updatedRoleInfoForCreateShiftRequest
          }
          return r
        })
      }
    })
  }

  return {
    steps,
    activeStep,
    completedThroughStep,
    scrollContainer,
    onContinue,
    onPrevious,
    onNavigate,
    onBook,
    shiftRequest,
    setShiftRequest,
    shiftRequestMetadata,
    setShiftRequestMetadata,
    recurringRoles,
    setRecurringRoles,
    addNewRole,
    updateRoleInfoForCreateShiftRequest,
    removeRole,
  }
}
