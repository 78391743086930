import { useAlert } from '@traba/context'
import { CreateRosterData, Roster } from '@traba/types'
import { getQueryParams } from '@traba/utils'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { trabaApi } from 'src/api/helpers'

import { UpdateRosterDto } from './useRoster'

const getRosters = async (locationId?: string, roleId?: string) => {
  try {
    const queryParams = getQueryParams([
      ['locationId', locationId],
      ['roleId', roleId],
    ])
    const response = await trabaApi.get(`/my-company/rosters${queryParams}`)
    return response.data
  } catch (error) {
    console.log('Error retrieving rosters', error)
  }
}

const deleteRoster = async (rosterId: string) => {
  const response = await trabaApi.delete(`/my-company/rosters/${rosterId}`)
  return response.data
}

export const useRosters = (params?: {
  locationId?: string
  roleId?: string
}) => {
  const queryClient = useQueryClient()
  const { locationId, roleId } = params || {}
  const {
    isLoading,
    isError,
    data: rosters,
    error,
  } = useQuery<Roster[], Error>(['rosters', locationId, roleId], () =>
    getRosters(locationId, roleId),
  )
  const { handleError } = useAlert()

  const createRosterMutation = useMutation<
    Roster,
    AxiosError,
    CreateRosterData
  >(
    async (newRoster) => {
      const res = await trabaApi.post(`/my-company/rosters`, newRoster)
      return res.data
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData('rosters', (currentRosters: any) => [
          ...currentRosters,
          data,
        ])
      },
    },
  )

  const addWorkersToRostersMutation = useMutation<
    Roster[],
    AxiosError,
    { rosterIds: string[]; workerIds: string[] }
  >(
    async (req) => {
      const res = await trabaApi.patch(`/my-company/rosters`, req)
      return res.data
    },
    {
      onSuccess: (response) => {
        queryClient.setQueryData('rosters', (): Roster[] => {
          return (
            rosters?.map((roster) => {
              const updatedRoster = response.find((r) => r.id === roster.id)
              if (updatedRoster) {
                return updatedRoster
              }
              return roster
            }) || []
          )
        })
      },
    },
  )

  const deleteRosterMutation = useMutation<Roster, AxiosError, string, Roster>(
    deleteRoster,
    {
      onSuccess: (response: Roster) => {
        queryClient.setQueryData('rosters', (rosters: any) =>
          rosters.filter((roster: Roster) => roster.id !== response.id),
        )
      },
      onError: (error) => {
        handleError(
          error,
          'useRosters -> deleteRoster()',
          'There was an error deleting the roster. Please try again or contact support if the issue persists.',
          'Error deleting roster',
        )
      },
    },
  )

  const updateRosterMutation = useMutation<Roster, AxiosError, UpdateRosterDto>(
    async (req) => {
      const { rosterId, ...rest } = req
      const res = await trabaApi.patch(`/my-company/rosters/${rosterId}`, rest)
      return res.data
    },
    {
      onSuccess: (response) => {
        queryClient.setQueryData('rosters', (currentRosters: any) =>
          currentRosters.map((currentRoster: { id: string }) =>
            currentRoster.id === response.id ? response : currentRoster,
          ),
        )
      },
      onError: (error) => {
        handleError(
          error,
          'useRosters -> updateRoster()',
          'There was an error updating the roster. Please try again or contact support if the issue persists.',
          'Error updating roster',
        )
      },
    },
  )

  return {
    isLoading,
    isError,
    error,
    rosters: rosters || [],
    createRoster: createRosterMutation.mutate,
    addWorkersToRosters: addWorkersToRostersMutation.mutate,
    removeRoster: deleteRosterMutation.mutate,
    updateRoster: updateRosterMutation.mutate,
  }
}
