import { Card, LoadingSpinner } from '@traba/react-components'
import { recurringSchedulesEnabled } from '@traba/utils'
import { Dispatch, SetStateAction } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Col, Row, Text } from 'src/components'
import { RadioButton } from 'src/components/RadioButton'
import { useCompany } from 'src/hooks/useCompany'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'

export enum AddToExistingScheduleOptions {
  ADD_SINGLE_DAY_TO_SCHEDULE = 'ADD_SINGLE_DAY_TO_SCHEDULE',
  ADD_ROLE_OR_WORKERS_TO_SCHEDULE = 'ADD_ROLE_OR_WORKERS_TO_SCHEDULE',
  ADD_RECURRING_DAY_TO_SCHEDULE = 'ADD_RECURRING_DAY_TO_SCHEDULE',
}

type AddToExistingScheduleOptionsProps = {
  onContinue: () => void
  selectedOption: AddToExistingScheduleOptions
  setSelectedOption: Dispatch<SetStateAction<AddToExistingScheduleOptions>>
  shiftRequestParentId: string
}

export default function SelectAddToExistingScheduleOptions(
  props: AddToExistingScheduleOptionsProps,
) {
  const {
    onContinue,
    shiftRequestParentId,
    selectedOption,
    setSelectedOption,
  } = props
  const navigate = useNavigate()

  const { hotSettings, isLoading: isLoadingHotSettings } = useHotSettings()
  const { company, isLoading: isLoadingCompany } = useCompany()

  if (isLoadingHotSettings || isLoadingCompany) {
    return <LoadingSpinner />
  }

  return (
    <Col gap={theme.space.xs}>
      <Card
        isClickable
        onClick={() =>
          setSelectedOption(
            AddToExistingScheduleOptions.ADD_SINGLE_DAY_TO_SCHEDULE,
          )
        }
      >
        <Row alignCenter fullWidth gap={theme.space.xs}>
          <RadioButton
            selected={
              selectedOption ===
              AddToExistingScheduleOptions.ADD_SINGLE_DAY_TO_SCHEDULE
            }
          />
          <Row style={{ marginLeft: theme.space.xs }}>
            <Col>
              <Text color={theme.colors.MidnightBlue} variant="h5">
                Add a single day to the schedule (one-off shift)
              </Text>
              <Text variant="body2">
                Select if you need to add a one-off shift to this schedule.
              </Text>
              <Text variant="caption1">
                eg: Add a single thursday shift to the schedule
              </Text>
            </Col>
          </Row>
        </Row>
      </Card>

      <Card
        isClickable
        onClick={() =>
          setSelectedOption(
            AddToExistingScheduleOptions.ADD_ROLE_OR_WORKERS_TO_SCHEDULE,
          )
        }
      >
        <Row alignCenter fullWidth gap={theme.space.xs}>
          <RadioButton
            selected={
              selectedOption ===
              AddToExistingScheduleOptions.ADD_ROLE_OR_WORKERS_TO_SCHEDULE
            }
          />
          <Row style={{ marginLeft: theme.space.xs }}>
            <Col>
              <Text color={theme.colors.MidnightBlue} variant="h5">
                Add{' '}
                {recurringSchedulesEnabled({
                  companyId: company?.companyId,
                  hotSettings,
                })
                  ? 'another role or '
                  : ''}
                workers to the whole schedule
              </Text>
              <Text variant="body2">
                {recurringSchedulesEnabled({
                  companyId: company?.companyId,
                  hotSettings,
                })
                  ? 'Select if you need another role and more workers for that role on the schedule. You can select which days of the schedule you want this change to apply to.'
                  : 'Select if you need more workers on your schedule. You can select which days of the schedule you want this change to apply to.'}
              </Text>
              <Text variant="caption1">
                eg: Add 2 pickers to all days in the schedule
              </Text>
            </Col>
          </Row>
        </Row>
      </Card>

      <Text variant="h6" width="100%">
        If you just need to extend the existing schedule, you can do that by
        going to the Schedule page and editing the end date.
        <Text
          ml={theme.space.xxs}
          variant="h6LinkUnderline"
          onClick={() => navigate(`/schedule/${shiftRequestParentId}`)}
        >
          Link to page
        </Text>
      </Text>
      <Row style={{ justifyContent: 'flex-end', marginTop: theme.space.lg }}>
        <Button onClick={onContinue}>Continue</Button>
      </Row>
    </Col>
  )
}
