import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useAlert } from '@traba/context'
import { AxiosError } from 'axios'
import { trabaApi } from 'src/api/helpers'
import {
  CreateRoleData,
  RoleData,
  UpdateRoleDto,
  UpdateRoleResponseDto,
} from 'src/types'

const ROLES_QUERY_KEY = 'roles'

function formatRoleData(roleData: UpdateRoleDto | CreateRoleData) {
  return { ...roleData, roleName: roleData.roleName?.trim() }
}

const getRoles = async () => {
  try {
    const response = await trabaApi.get(`/my-company/roles`)
    return response.data.roles
  } catch (error) {
    // will catch later
  }
}

interface EditRoleParams {
  roleId: string
  updatedRole: UpdateRoleDto
}

const editRole = async ({ roleId, updatedRole }: EditRoleParams) => {
  const formattedRoleData = formatRoleData(updatedRole)
  const response = await trabaApi.patch(
    `my-company/roles/${roleId}`,
    formattedRoleData,
  )
  return response.data
}

const archiveRole = async (roleId: string) => {
  const response = await trabaApi.patch(`my-company/roles/${roleId}/archive`)
  return response.data
}

const createRole = async (newRole: CreateRoleData) => {
  const formattedRoleData = formatRoleData(newRole)
  const response = await trabaApi.post(`/my-company/roles`, formattedRoleData)
  return response.data
}

export const useRoles = () => {
  const queryClient = useQueryClient()
  const { showSuccess, showError } = useAlert()
  const {
    isLoading,
    isError,
    data: roles,
    error,
    isFetched: isRolesFetched,
    refetch,
  } = useQuery<RoleData[], Error>({
    queryKey: [ROLES_QUERY_KEY],
    queryFn: getRoles,
  })
  const createRoleMutation = useMutation<RoleData, AxiosError, CreateRoleData>({
    mutationFn: createRole,
    onSuccess: (data) => {
      queryClient.setQueryData(
        [ROLES_QUERY_KEY],
        (currentRoles: RoleData[] | undefined) => {
          return currentRoles ? [...currentRoles, data] : [data]
        },
      )
      showSuccess('New role created successfully!')
    },
    onError: (error: any) => {
      showError(error.message, 'Error during role creation')
    },
  })

  const editRoleMutation = useMutation<
    UpdateRoleResponseDto,
    AxiosError,
    EditRoleParams
  >({
    mutationFn: editRole,
    onSuccess: (data) => {
      const updatedRole = data.role
      queryClient.setQueryData(
        [ROLES_QUERY_KEY],
        (currentRoles: RoleData[] | undefined) => {
          return currentRoles?.map((role) =>
            role.roleId === updatedRole?.roleId ? updatedRole : role,
          )
        },
      )
      showSuccess('Role successfully updated!')
    },
    onError: (error: any) => {
      showError(error.message, 'Error saving role')
    },
  })

  const archiveRoleMutation = useMutation<RoleData, AxiosError, string>({
    mutationFn: archiveRole,
    onSuccess: (response) => {
      queryClient.setQueryData(
        [ROLES_QUERY_KEY],
        (currentRoles: RoleData[] | undefined) => {
          return currentRoles
            ? currentRoles.filter(
                (role: RoleData) => role.roleId !== response.roleId,
              )
            : []
        },
      )
      showSuccess(
        'Role will no longer be suggested when creating shifts.',
        'Role archived!',
      )
    },
  })

  const getRoleById = (id: string) => roles?.find((role) => role.roleId === id)

  return {
    isLoading,
    isError,
    error,
    isRolesFetched,
    roles: roles || [],
    getRoleById,
    createRole: createRoleMutation.mutate,
    archiveRole: archiveRoleMutation.mutate,
    editRole: editRoleMutation.mutate,
    refetchRoles: refetch,
  }
}
