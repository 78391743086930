import { DEFAULT_TIMEZONE, MINIMUM_MINUTES_BEFORE_SHIFT } from '@traba/consts'
import { ToText } from '@traba/react-components'
import { RequiredMultiShiftType, ShiftPayType } from '@traba/types'
import {
  addDays,
  addMinutes,
  differenceInMinutes,
  format,
  isValid,
  startOfDay,
  subMinutes,
} from 'date-fns'
import { useState } from 'react'
import { WeekdayStr } from 'rrule'
import {
  Button,
  ButtonVariant,
  Col,
  InlineBanner,
  Row,
  Text,
} from 'src/components/base'
import DatePicker from 'src/components/base/AriaDatePicker/DatePicker'
import TimeField from 'src/components/base/AriaDatePicker/TimeField'
import { MultiSelector } from 'src/components/base/MultiSelector/MultiSelector'
import { RadioButton } from 'src/components/RadioButton'
import { ShiftSchedule } from 'src/components/ShiftSchedule/ShiftSchedule'
import { useCompany } from 'src/hooks/useCompany'
import { useLocations } from 'src/hooks/useLocations'
import { useHotSettings } from 'src/hooks/useSystem'
import { theme } from 'src/libs/theme'
import {
  getTimeAfterTimeWithin24Hours,
  getTimeZoneAbbreviation,
} from 'src/shared/utils/dateUtils'
import { getEarlyArrivalTimeBufferInMinutes } from 'src/utils/earlyArrivalTimeUtils'
import styled from 'styled-components'

import { BookShiftsProps } from '../../BookShiftsScreen'
import { BookShiftsRMSASection } from '../../components/BookShiftsRMSASection'
import {
  getDateError,
  getTimeError,
  MAX_END_DATE,
  MAX_START_DATE,
} from '../../validation'

export const REPEAT_ON_OPTIONS: { value: WeekdayStr; label: string }[] = [
  { value: 'MO', label: 'Mon' },
  { value: 'TU', label: 'Tue' },
  { value: 'WE', label: 'Wed' },
  { value: 'TH', label: 'Thu' },
  { value: 'FR', label: 'Fri' },
  { value: 'SA', label: 'Sat' },
  { value: 'SU', label: 'Sun' },
]

const REPEAT_ON_OPTIONS_XS: { value: WeekdayStr; label: string }[] = [
  { value: 'MO', label: 'Mo' },
  { value: 'TU', label: 'Tu' },
  { value: 'WE', label: 'We' },
  { value: 'TH', label: 'Th' },
  { value: 'FR', label: 'Fr' },
  { value: 'SA', label: 'Sa' },
  { value: 'SU', label: 'Su' },
]

const MobileRow = styled(Row)`
  column-gap: ${({ theme }) => theme.space.med}px;
  @media only screen and (max-width: (${({ theme }) =>
      theme.media.maxExtraSmall})) {
    justify-content: flex-start;
    column-gap: ${({ theme }) => theme.space.xs}px;
  }
`

export function BookShiftsScheduleDateSection({
  shiftRequest,
  setShiftRequest,
  shiftRequestMetadata,
  setShiftRequestMetadata,
  isEdit,
  showRequiredMultiShiftToggle,
  initialShift,
}: BookShiftsProps) {
  const { scheduleExpanded } = shiftRequestMetadata
  const { locationId, requiredMultiShiftType } = shiftRequest
  const schedule = shiftRequest.schedules[0]
  const { startTime, endTime, isRecurringSchedule, recurringSchedule } =
    schedule
  const { getLocationById } = useLocations()
  const location = getLocationById(locationId)
  const timezone = location?.timezone || DEFAULT_TIMEZONE
  const recurring = isRecurringSchedule && !!recurringSchedule
  const isOvernight = startTime.getDate() !== endTime.getDate()
  const [RMSAEnabled, setRMSAEnabled] = useState<boolean>(
    requiredMultiShiftType === RequiredMultiShiftType.ALL_IN_REQUEST,
  )
  const { hotSettings } = useHotSettings()
  const { company } = useCompany()

  const minutesAheadForShiftPosting =
    company?.minutesAheadForShiftPosting ?? MINIMUM_MINUTES_BEFORE_SHIFT

  const endTextArr: string[] = []
  const timeZoneAbrv = getTimeZoneAbbreviation(startTime, timezone)
  const shiftStartDateText = `${timeZoneAbrv ? ` (${timeZoneAbrv})` : ''}`
  if (timeZoneAbrv) {
    endTextArr.push(timeZoneAbrv)
  }
  const overnightDate = isOvernight ? `${format(endTime, 'MM/dd/yy')}` : ''
  if (overnightDate) {
    endTextArr.push(overnightDate)
  }
  const shiftEndDateText = ` (${endTextArr.join(' ')})`
  const eatBuffer =
    (initialShift && getEarlyArrivalTimeBufferInMinutes(initialShift)) ?? 0
  const businessStartTime = eatBuffer
    ? addMinutes(startTime, eatBuffer)
    : undefined

  function setToRecurring() {
    if (!scheduleExpanded) {
      setShiftRequestMetadata({ scheduleExpanded: true })
    }
    if (recurring) {
      return
    }
    window.analytics.track(`User Selected Shift Recurring`, { recurring: true })
    const newEndDate = addDays(schedule.endTime, 13)
    setShiftRequest({
      schedules: [
        {
          ...schedule,
          isRecurringSchedule: true,
          recurringSchedule: {
            interval: 1,
            freq: 'WEEKLY',
            repeatOn: [],
            endDate: newEndDate,
          },
        },
      ],
    })
  }

  function setToOneTime() {
    if (!scheduleExpanded) {
      setShiftRequestMetadata({ scheduleExpanded: true })
    }
    setRMSAEnabled(false)
    if (!recurring) {
      return
    }
    window.analytics.track(`User Selected Shift Recurring`, {
      recurring: false,
    })
    setShiftRequest({
      schedules: [
        {
          ...schedule,
          isRecurringSchedule: false,
          recurringSchedule: undefined,
        },
      ],
      ...(!!hotSettings?.allowRequiredMultiShiftInShiftRequest && {
        requiredMultiShiftType: RequiredMultiShiftType.None,
      }),
    })
  }

  function toggleRMSA(required: boolean) {
    setRMSAEnabled(required)
    if (!hotSettings?.allowRequiredMultiShiftInShiftRequest) {
      return
    }
    if (required) {
      setShiftRequest({
        requiredMultiShiftType: RequiredMultiShiftType.ALL_IN_REQUEST,
      })
    } else {
      setShiftRequest({
        requiredMultiShiftType: RequiredMultiShiftType.None,
      })
    }
  }

  function setRepeatOn(repeatOn: WeekdayStr[]) {
    if (!recurring) {
      return
    }
    window.analytics.track(`User Selected Repeat on Days`, { repeatOn })
    setShiftRequest({
      schedules: [
        {
          ...schedule,
          recurringSchedule: {
            ...recurringSchedule,
            repeatOn,
          },
        },
      ],
    })
  }

  function setStartTime(newStartTime: Date) {
    if (!isValid(newStartTime)) {
      return
    }
    if (newStartTime.getTime() > MAX_START_DATE.getTime()) {
      return
    }
    if (newStartTime.getTime() < startOfDay(new Date()).getTime()) {
      return
    }
    // make sure that end time is after start time
    const newEndTime = getTimeAfterTimeWithin24Hours(endTime, newStartTime)

    // Update the recurring schedule's end date if it exists
    let newRecurringSchedule = recurringSchedule
    if (recurringSchedule && recurringSchedule.endDate) {
      const newEndDate = new Date(
        recurringSchedule.endDate.getFullYear(),
        recurringSchedule.endDate.getMonth(),
        recurringSchedule.endDate.getDate(),
        newStartTime.getHours(),
        newStartTime.getMinutes(),
        newStartTime.getSeconds(),
      )
      newRecurringSchedule = { ...recurringSchedule, endDate: newEndDate }
    }

    setShiftRequest({
      schedules: [
        {
          ...schedule,
          startTime: subMinutes(newStartTime, eatBuffer),
          endTime: newEndTime,
          recurringSchedule: newRecurringSchedule,
        },
      ],
    })
  }

  function setEndTime(time: Date) {
    const newEndTime = getTimeAfterTimeWithin24Hours(time, startTime)
    if (!isValid(newEndTime)) {
      return
    }
    if (newEndTime.getTime() > MAX_END_DATE.getTime()) {
      return
    }
    if (newEndTime.getTime() < startOfDay(new Date()).getTime()) {
      return
    }
    setShiftRequest({ schedules: [{ ...schedule, endTime: newEndTime }] })
  }

  function setEndDate(newEndDate: Date) {
    const newEndDateWithStartTime = new Date(
      newEndDate.getFullYear(),
      newEndDate.getMonth(),
      newEndDate.getDate(),
      schedule.startTime.getHours(),
      schedule.startTime.getMinutes(),
      schedule.startTime.getSeconds(),
    )

    if (!recurring) {
      return
    }
    if (!isValid(newEndDateWithStartTime)) {
      return
    }
    if (newEndDateWithStartTime.getTime() > MAX_END_DATE.getTime()) {
      return
    }
    if (newEndDateWithStartTime.getTime() < Date.now()) {
      return
    }

    setShiftRequest({
      schedules: [
        {
          ...schedule,
          recurringSchedule: {
            ...recurringSchedule,
            endDate: newEndDateWithStartTime,
          },
        },
      ],
    })
  }

  const timeError = getTimeError(
    shiftRequest,
    minutesAheadForShiftPosting,
  )?.message
  const dateError = getDateError(
    shiftRequest,
    minutesAheadForShiftPosting,
  )?.message
  const durationMinutes = differenceInMinutes(
    endTime,
    businessStartTime ?? startTime,
  )
  const durationHours = Math.floor(durationMinutes / 60)
  const durationMinutesRemainder = durationMinutes % 60
  const timeSection = (
    <>
      <Text
        variant="h5"
        style={{
          margin: isEdit
            ? `0 0 ${theme.space.xxs}px`
            : `${theme.space.lg}px 0 ${theme.space.sm}px`,
        }}
      >
        {recurring && !isEdit
          ? 'At what time?'
          : 'When does your shift start and end?'}
      </Text>
      {shiftRequest.payType === ShiftPayType.HOURLY ? (
        <Text
          style={{ marginBottom: theme.space.sm, marginTop: theme.space.xs }}
        >
          We have a 4 hour minimum paid time policy for shifts.{' '}
          <Text
            variant="link"
            href="https://www.traba.work/business-faq#faq_question13"
            target="_blank"
          >
            Learn more
          </Text>
          .
        </Text>
      ) : (
        <Text
          variant="body2"
          style={{ marginBottom: theme.space.xs, marginTop: theme.space.xxs }}
        >
          While we understand that some shifts might take longer than expected,
          we use the end time to be able to better schedule workers' times.
        </Text>
      )}
      <MobileRow gap={theme.space.xxs} alignEnd>
        <Col gap={theme.space.xxs}>
          <Text variant="body3">Start Time {shiftStartDateText}</Text>
          <TimeField
            aria-label="Start time"
            time={businessStartTime ?? startTime}
            setTime={(newDate) => {
              newDate && setStartTime(newDate)
              window.analytics.track(`User Updated Start Time`, {
                time: newDate,
                schedule,
                isEdit,
              })
            }}
            timezone={timezone}
          />
        </Col>

        <ToText />

        <Col gap={theme.space.xxs}>
          <Text variant="body3">End Time {shiftEndDateText}</Text>
          <TimeField
            aria-label="End time"
            time={endTime}
            setTime={(newDate) => {
              newDate && setEndTime(newDate)
              window.analytics.track(`User Updated End Time`, {
                time: newDate,
                schedule,
                isEdit,
              })
            }}
            timezone={timezone}
          />
        </Col>

        <Text variant="body2">
          {durationHours} hours{' '}
          {durationMinutesRemainder
            ? `${durationMinutesRemainder} minutes`
            : null}
        </Text>
      </MobileRow>

      {timeError ? (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity="error"
          text={timeError}
        />
      ) : isOvernight ? (
        <InlineBanner
          style={{ marginTop: theme.space.xs }}
          severity="warning"
          text={`This shift is overnight. It ends on ${overnightDate}.`}
        />
      ) : null}
    </>
  )

  return (
    <>
      {!isEdit && (
        <>
          <Text variant="h5" style={{ margin: `0 0 ${theme.space.sm}px 0` }}>
            Do you want your shift to repeat?
          </Text>
          <Row alignCenter style={{ columnGap: theme.space.xl }}>
            <Col>
              <Row
                alignCenter
                style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
                onClick={setToOneTime}
              >
                <RadioButton
                  selected={!scheduleExpanded ? false : !recurring}
                />
                <Text variant="body1">No</Text>
              </Row>
            </Col>
            <Col>
              <Row
                alignCenter
                style={{ columnGap: theme.space.xs, cursor: 'pointer' }}
                onClick={setToRecurring}
              >
                <RadioButton selected={!scheduleExpanded ? false : recurring} />
                <Text variant="body1">Yes</Text>
              </Row>
            </Col>
          </Row>
        </>
      )}

      {!scheduleExpanded ? null : recurring && !isEdit ? (
        <>
          <Text
            variant="h5"
            style={{ margin: `${theme.space.lg}px 0 ${theme.space.sm}px` }}
          >
            Between which dates?
          </Text>
          <MobileRow gap={theme.space.xxs} alignCenter>
            <DatePicker
              aria-label="Start date input"
              date={startTime}
              setDate={(d) => {
                d && setStartTime(d)
                window.analytics.track(`User Updated Start Date`, {
                  date: d,
                  schedule,
                })
              }}
              isClearable={false}
              granularity="day"
              minDate={new Date(new Date().setHours(0, 0, 0, 0))}
              maxDate={MAX_START_DATE}
              timezone={timezone}
            />
            <ToText />
            <DatePicker
              aria-label="End date input"
              date={recurringSchedule.endDate}
              setDate={(d) => {
                d && setEndDate(d)
                window.analytics.track(`User Updated End Date`, {
                  date: d,
                  schedule,
                })
              }}
              isClearable={false}
              granularity="day"
              minDate={startTime}
              maxDate={MAX_END_DATE}
              timezone={timezone}
            />
          </MobileRow>
          {dateError ? (
            <InlineBanner
              style={{ marginTop: theme.space.xs }}
              severity="error"
              text={dateError}
            />
          ) : null}
          <Text
            variant="h5"
            style={{ margin: `${theme.space.lg}px 0 ${theme.space.sm}px` }}
          >
            On which days?
          </Text>
          <MultiSelector
            className="xs-hide sm-show"
            options={REPEAT_ON_OPTIONS}
            selectedOptions={recurringSchedule.repeatOn}
            onChange={setRepeatOn}
          />
          <MultiSelector
            className="xs-show sm-hide"
            options={REPEAT_ON_OPTIONS_XS}
            selectedOptions={recurringSchedule.repeatOn}
            onChange={setRepeatOn}
          />
          <Button
            variant={ButtonVariant.TEXT}
            onClick={() => {
              setRepeatOn(['MO', 'TU', 'WE', 'TH', 'FR'])
              window.analytics.track(`User Clicked Select All Weekdays`)
            }}
            style={{
              color: theme.colors.brand,
              padding: 0,
              marginTop: theme.space.xs,
              alignSelf: 'flex-start',
              fontSize: 12,
            }}
          >
            Select all weekdays
          </Button>

          {timeSection}
        </>
      ) : (
        <>
          {!isEdit && (
            <>
              <Text
                variant="h5"
                style={{
                  margin: `${theme.space.lg}px 0 ${theme.space.sm}px`,
                }}
              >
                What is your shift‘s date?
              </Text>
              <DatePicker
                aria-label="What is your shift‘s date?"
                date={startTime}
                setDate={(d) => {
                  d && setStartTime(d)
                  window.analytics.track(`User Updated Start Date`, {
                    date: d,
                    schedule,
                  })
                }}
                isClearable={false}
                granularity="day"
                minDate={new Date(new Date().setHours(0, 0, 0, 0))}
                maxDate={MAX_START_DATE}
              />
              {dateError ? (
                <InlineBanner
                  style={{ marginTop: theme.space.xs }}
                  severity="error"
                  text={dateError}
                />
              ) : null}
            </>
          )}
          {timeSection}
        </>
      )}
      {recurring && !!recurringSchedule.repeatOn.length && !isEdit ? (
        <>
          <Text
            variant="h5"
            style={{ margin: `${theme.space.lg}px 0 ${theme.space.sm}px` }}
          >
            Review your schedule
          </Text>
          <ShiftSchedule shiftRequest={shiftRequest} shiftTimeZone={timezone} />
        </>
      ) : null}
      {recurring &&
        !isEdit &&
        showRequiredMultiShiftToggle &&
        !!hotSettings?.allowRequiredMultiShiftInShiftRequest && (
          <BookShiftsRMSASection
            selection={RMSAEnabled}
            onClick={(selection) => {
              toggleRMSA(selection)
            }}
          />
        )}
    </>
  )
}
