import { CardTile } from '@traba/react-components'
import { DotMenu, DotMenuProps } from '@traba/react-components'
import { LocationResponse } from '@traba/types'
import { Col, Icon, Row, Text } from 'src/components/base'
import { theme } from 'src/libs/theme'
import { getAddressString } from 'src/utils/stringUtils'

// This is where the logic meets the UI. This separation allows use to pass the data directly to the UI in StoryBook

export type LocationProps = {
  location: LocationResponse
  archive?: (location: LocationResponse) => void
  edit?: (location: LocationResponse) => void
  userCanEdit?: boolean
}

export const Location = (props: LocationProps) => {
  const { location, archive, edit } = props

  const menuItems: DotMenuProps['menuItems'] = []
  if (edit) {
    menuItems.push({
      title: 'Edit location',
      iconName: 'edit',
      onClick: () => {
        edit(location)
        window.analytics.track(`User Clicked Edit Location`, {})
      },
      color: theme.colors.MidnightBlue,
    })
  }

  if (archive) {
    menuItems.push({
      title: 'Archive location',
      iconName: 'trash',
      onClick: () => {
        archive(location)
        window.analytics.track(`User Clicked Archive Location`, {})
      },
      color: theme.colors.Red60,
    })
  }

  const actionableCard = props.userCanEdit && menuItems.length > 0

  return (
    <Row alignCenter gap={theme.space.xxs}>
      <CardTile size="56px">
        <Icon name="location" />
      </CardTile>
      <Col pl={24} width={actionableCard ? '90%' : '100%'}>
        <Text variant="h5">
          {getAddressString(location.address, location.name)}
        </Text>
        {menuItems.length > 0 && (
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            {location.locationInstructions.length > 0 && (
              <Text variant="body2">{`Arrival Instructions: ${location.locationInstructions}`}</Text>
            )}
          </div>
        )}
      </Col>
      {actionableCard && (
        <Col width="10%">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <DotMenu
              type="locations"
              dotMenuKey={location.locationId}
              menuItems={menuItems}
            />
          </div>
        </Col>
      )}
    </Row>
  )
}
