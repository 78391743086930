import { ARCHIVED_REGION_ID } from '@traba/consts'
import { useAlert } from '@traba/context'
import {
  LocationResponse,
  UpdateLocationDto,
  UpdateLocationResponseDto,
} from '@traba/types'
import {
  isLocationActive,
  isLocationArchived,
  isLocationValid,
} from '@traba/utils'
import { AxiosError } from 'axios'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { trabaApi } from 'src/api/helpers'
import { LocationRequest } from 'src/types'
import { useRegionalFilter } from './useRegionalFilter'

export async function getActiveRegion(postalCode: string) {
  try {
    const response = await trabaApi.get(
      `/regions/postal-code/${postalCode}/active-region?consumer=USER`,
    )
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const getLocations = async () => {
  try {
    const response = await trabaApi.get(`/my-company/locations`)
    return response.data
  } catch (error) {
    console.log(error)
  }
}

const createLocation = async (newLocation: LocationRequest) => {
  const { locationMedia, ...rest } = newLocation
  try {
    const response = await trabaApi.post(`my-company/locations`, {
      ...rest,
      media: locationMedia,
    })
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

const editLocation = async ({
  locationId,
  updatedLocation,
}: {
  locationId: string
  updatedLocation: UpdateLocationDto
}) => {
  const response = await trabaApi.patch(
    `my-company/location/${locationId}`,
    updatedLocation,
  )
  return response.data
}

const archiveLocation = async (locationId: string) => {
  const response = await trabaApi.patch(
    `my-company/location/${locationId}/archive`,
  )
  return response.data
}

/*
 * Locations of most recent active ones (if the location was edited, it will contain most recent one
 * and if it was archived, it will contain the location that was archived  (archived not by edit)
 * For instance, if location1.0 was edited to location1.1, location1.1 will be included
 * If location1.1 was archived, location1.1 will be included
 */
const getUniqueLocations = (locations: LocationResponse[] | undefined) => {
  return (
    locations?.filter(
      (location) => location.activeLocationId === location.locationId,
    ) ?? []
  )
}

export const useLocations = () => {
  const queryClient = useQueryClient()
  const { showSuccess, showError, handleError } = useAlert()
  const { reloadRegionalFilter } = useRegionalFilter()
  const {
    isLoading,
    isError,
    data: locations,
    error,
    isFetched: isLocationsFetched,
    refetch,
  } = useQuery<LocationResponse[], Error>('locations', getLocations, {
    onSuccess: (locations) => {
      reloadRegionalFilter(getUniqueLocations(locations))
    },
  })

  const createLocationMutation = useMutation<
    LocationResponse,
    AxiosError,
    LocationRequest
  >(createLocation, {
    onSuccess: (response: any) => {
      queryClient.setQueryData(
        'locations',
        (currentLocations: LocationResponse[] | undefined) => {
          return currentLocations ? [response, ...currentLocations] : [response]
        },
      )
      refetch()
    },
    onError: (error) => {
      const message =
        'There was an error creating the location. Please try again or contact support if the issue persists.'
      handleError(
        error,
        'CreateLocationUI -> createLocation',
        message,
        'Error creating location',
      )
    },
  })

  const editLocationMutation = useMutation<
    UpdateLocationResponseDto,
    AxiosError,
    any
  >(editLocation, {
    onSuccess: (response: UpdateLocationResponseDto) => {
      if (!response.updatedLocation) {
        showError(
          'If you meant to update the role, double check the fields you wish to update.',
          'No changes detected',
        )
        return
      }
      let successMessage =
        'Changes were successfully saved. Future shifts tied to this location have been updated.'
      if (response.archivedLocationId) {
        successMessage +=
          ' Shifts that were in the past, are ongoing, or start within 2 hours were not updated. If you need these shifts updated, please contact support.'
      }
      showSuccess(successMessage, 'Edit location successful.')
      refetch()
    },
    onError: (error) => {
      if (error.message === 'edit-location/exceeds-radius-threshold') {
        showError(
          'Unfortunately, the new location you entered is farther than the allowed distance from your previous location. If you need this location updated, please contact support.',
          'Invalid location edit.',
        )
      } else {
        showError(
          'There was an error editing the location. Please try again or contact support if the issue persists.',
          'Error editing location',
        )
      }
    },
  })

  const archiveLocationMutation = useMutation<
    LocationResponse,
    AxiosError,
    string
  >(archiveLocation, {
    onSuccess: (response: any) => {
      queryClient.setQueryData(
        'locations',
        (currentLocations: LocationResponse[] | undefined) => {
          return currentLocations
            ? currentLocations.map((location: LocationResponse) =>
                location.locationId === response.locationId
                  ? response
                  : location,
              )
            : []
        },
      )
      refetch()
      showSuccess(
        'Location will no longer be suggested when creating shifts.',
        'Location archived!',
      )
    },
  })

  const getLocationById = (id?: string) => {
    if (!id) {
      return undefined
    }
    return locations?.find((location) => location.locationId === id)
  }

  const uniqueLocations = getUniqueLocations(locations)
  const validLocations = uniqueLocations.filter(isLocationValid)
  const activeLocations = uniqueLocations?.filter(isLocationActive) || []
  const activeValidLocations = activeLocations.filter(isLocationValid)
  const archivedLocations = locations?.filter(isLocationArchived) || []
  const archivedValidLocations = archivedLocations.filter(isLocationValid)

  return {
    isLoading,
    isError,
    error,
    isLocationsFetched,
    getLocationById,
    locations: locations || [],
    activeLocations,
    activeValidLocations,
    archivedLocations,
    archivedValidLocations,
    uniqueLocations,
    validLocations,
    createLocation: createLocationMutation.mutateAsync,
    editLocation: editLocationMutation.mutateAsync,
    archiveLocation: archiveLocationMutation.mutateAsync,
    refetch,
  }
}

export function useRegionLocationMap() {
  const { isLoading, validLocations, activeValidLocations } = useLocations()

  const regionLocationMap = validLocations.reduce<
    Record<string, LocationResponse[]>
  >((acc, location) => {
    const key = isLocationArchived(location)
      ? ARCHIVED_REGION_ID
      : location.regionId
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(location)
    return acc
  }, {})

  const activeRegionsWithLocationsMap = activeValidLocations.reduce<
    Record<string, LocationResponse[]>
  >((acc, location) => {
    const key = location.regionId
    if (!acc[key]) {
      acc[key] = []
    }
    acc[key].push(location)
    return acc
  }, {})

  return { isLoading, regionLocationMap, activeRegionsWithLocationsMap }
}
