import { theme } from '@traba/theme'
import { ShiftStatus } from '@traba/types'
import { addYears, isAfter, isBefore } from 'date-fns'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import {
  Event as BigCalendarEvent,
  View as BigCalendarViewType,
  luxonLocalizer,
} from 'react-big-calendar'
import { useNavigate } from 'react-router-dom'
import { ColorSet, useCalendarContext } from 'src/hooks/useCalendarContext'
import useMobile from 'src/hooks/useMobile'
import { ShiftAndAddress } from 'src/hooks/useShifts'
import { useHotSettings } from 'src/hooks/useSystem'
import { useUser } from 'src/hooks/useUser'
import { USER_CALENDER_VIEW } from 'src/libs/constants'
import {
  determineVisibleMonth,
  getCalendarMonthDisplayRange,
} from 'src/utils/calendarUtils'

import { CalendarViewCustomEvent } from './CalendarViewCustomEvent'
import { CalendarViewCustomHeader } from './CalendarViewCustomHeader'
import { CalendarViewCustomToolbar } from './CalendarViewCustomToolbar'
import {
  CalendarView as BigCalendarView,
  canceledStyle,
} from './Calender.styles'

const localizer = luxonLocalizer(DateTime)
const formats = {
  timeGutterFormat: 'h a',
}

export interface ShiftItem {
  shift: ShiftAndAddress
  style: ColorSet
}

export type ShiftRequestParentWithShifts = {
  title: string
  shifts: ShiftItem[]
  shiftRequestParentId: string
  isShiftRequestParentWithShifts: true
  timeString: string
}

export function isShiftRequestParentWithShifts(
  s: any,
): s is ShiftRequestParentWithShifts {
  return !!s.isShiftRequestParentWithShifts
}

export function isShiftAndAddress(s: any): s is ShiftAndAddress {
  return !s.isShiftRequestParentWithShifts
}

export interface TrabaCalendarEvent {
  title: string
  start: Date
  end: Date
  resource?: {
    item: ShiftAndAddress | ShiftRequestParentWithShifts
    style: ColorSet
  }
}

function getShiftRequestParentMaps(filteredShifts: ShiftAndAddress[]) {
  const shiftRequestParentItemsMap = new Map<
    string,
    Map<string, ShiftAndAddress[]>
  >()
  const addedShiftIds = new Set<string>()
  for (const shift of filteredShifts) {
    if (!shift.shiftRequestParentId || !shift.shiftRequestParent) {
      continue
    }
    const dateKey = (
      shift.businessStartTime ?? shift.startTime
    ).toLocaleDateString('en-us', {
      timeZone: shift.timezone,
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    })
    let map = shiftRequestParentItemsMap.get(shift.shiftRequestParentId)
    if (!map) {
      map = new Map<string, ShiftAndAddress[]>()
      shiftRequestParentItemsMap.set(shift.shiftRequestParentId, map)
    }
    let arr = map.get(dateKey)
    if (!arr) {
      arr = []
      map.set(dateKey, arr)
    }
    arr.push(shift)
    addedShiftIds.add(shift.shiftId)
  }

  return { shiftRequestParentItemsMap, addedShiftIds }
}

function getShiftRequestParentItems(
  shiftRequestParentItemsMap: Map<string, Map<string, ShiftAndAddress[]>>,
  getShiftColor: (shift: ShiftAndAddress) => ColorSet,
) {
  const shiftRequestParentItems: TrabaCalendarEvent[] = []
  for (const shiftsByDay of shiftRequestParentItemsMap.values()) {
    for (const shiftsArr of shiftsByDay.values()) {
      if (!shiftsArr[0].shiftRequestParent) {
        // should never happen
        continue
      }
      const earliestStartTime = shiftsArr
        .map((shift) => shift.businessStartTime ?? shift.startTime)
        .reduce(
          (prev, curr) => {
            if (isBefore(curr, prev)) {
              return curr
            }
            return prev
          },
          addYears(new Date(), 1000),
        )
      const latestEndTime = shiftsArr
        .map((shift) => shift.endTime)
        .reduce((prev, curr) => {
          if (isAfter(curr, prev)) {
            return curr
          }
          return prev
        }, new Date(0))
      const shiftRequestParent = shiftsArr[0].shiftRequestParent
      shiftRequestParentItems.push({
        start: earliestStartTime,
        end: latestEndTime,
        title: shiftRequestParent.title,
        resource: {
          item: {
            title: shiftRequestParent.title,
            timeString: `${earliestStartTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}-${latestEndTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`,
            shifts: shiftsArr.map((shift) => {
              return {
                shift,
                style: getShiftColor(shift),
              }
            }),
            shiftRequestParentId: shiftRequestParent.shiftRequestParentId,
            isShiftRequestParentWithShifts: true,
          },
          style: {
            backgroundColor: 'white',
            borderColor: theme.colors.MidnightBlue,
          },
        },
      })
    }
  }
  return shiftRequestParentItems
}

interface Props {
  setSelectedShift: React.Dispatch<
    React.SetStateAction<ShiftAndAddress | undefined>
  >
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLElement | null>>
  endAfter: Date
  startBefore: Date
  setEndAfter: React.Dispatch<React.SetStateAction<Date>>
  setStartBefore: React.Dispatch<React.SetStateAction<Date>>
  shifts: ShiftAndAddress[]
  selectedShift: ShiftAndAddress | undefined
  isLoading: boolean
  shiftRequestParentIdFilter?: string
  isFromScheduleDetails?: boolean
}

export const CalendarView = ({
  setSelectedShift,
  setAnchorEl,
  selectedShift,
  endAfter,
  startBefore,
  setEndAfter,
  setStartBefore,
  shifts,
  isLoading,
  shiftRequestParentIdFilter,
  isFromScheduleDetails,
}: Props) => {
  const navigate = useNavigate()
  const { hotSettings } = useHotSettings()
  const userLastCalendarView =
    localStorage.getItem(USER_CALENDER_VIEW) ?? 'week'
  const { user } = useUser()
  const enableShiftRequestParentsInCalendar =
    hotSettings?.enableShiftRequestParentsInCalendar &&
    !user?.email.includes('demo+')
  const [currView, setCurrView] = useState(userLastCalendarView)
  const { isReactNativeApp } = useMobile()
  const {
    state: { rolesMap },
    dispatch,
  } = useCalendarContext()

  useEffect(() => {
    dispatch({
      type: 'GET_ROLE_MAP',
      payload: shifts.filter(
        (shift) =>
          isBefore(shift.businessStartTime ?? shift.startTime, startBefore) &&
          isAfter(shift.endTime, endAfter),
      ),
    })
  }, [shifts, dispatch, startBefore, endAfter])

  const filteredShifts = shifts.filter(
    (shift) =>
      isBefore(shift.businessStartTime ?? shift.startTime, startBefore) &&
      isAfter(shift.endTime, endAfter) &&
      !!rolesMap[shift.roleId] &&
      !rolesMap[shift.roleId]?.hideFromView &&
      (!shiftRequestParentIdFilter ||
        shift.shiftRequestParentId === shiftRequestParentIdFilter),
  )

  const { shiftRequestParentItemsMap, addedShiftIds } =
    getShiftRequestParentMaps(filteredShifts)
  const shiftsToDisplay = filteredShifts.filter(
    (s) => !addedShiftIds.has(s.shiftId),
  )

  function getShiftColor(shift: ShiftAndAddress): ColorSet {
    return shift.status === ShiftStatus.CANCELED
      ? canceledStyle
      : rolesMap[shift.roleId].color
  }

  const shiftItems: TrabaCalendarEvent[] = (
    enableShiftRequestParentsInCalendar ? shiftsToDisplay : filteredShifts
  ).map((shift) => {
    return {
      title: shift.shiftRole,
      start: shift.businessStartTime ?? shift.startTime,
      end: shift.endTime,
      resource: {
        item: shift,
        style: getShiftColor(shift),
      },
    }
  })

  const shiftRequestParentItems = enableShiftRequestParentsInCalendar
    ? getShiftRequestParentItems(shiftRequestParentItemsMap, getShiftColor)
    : []

  const eventPropGetter = (event: BigCalendarEvent) => {
    return { style: event.resource?.style }
  }

  const handleClickOnFab = (
    event: React.SyntheticEvent<HTMLElement, Event>,
  ) => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <BigCalendarView
      isReactNativeApp={isReactNativeApp}
      isFromScheduleDetails={isFromScheduleDetails}
      showMultiDayTimes // Prevent showing overnight events as all day events
      startAccessor={(event: any) => new Date(event.start)}
      endAccessor={(event: any) => new Date(event.end)}
      style={{
        flex: 1,
        zIndex: 0,
        height: currView === 'month' ? 800 : '100%',
      }}
      views={['month', 'week', 'day']}
      localizer={localizer}
      events={[...shiftItems, ...shiftRequestParentItems]}
      messages={{
        showMore: (total) =>
          isReactNativeApp ? `+ ${total}` : `+ ${total} more`,
      }}
      // provides the full range of visible dates for the entire view, even if you switch between different views (e.g., from week view to month view).
      onRangeChange={(range) => {
        const rangeStart = Array.isArray(range) ? range[0] : range.start
        // Range end is always the start of the day, we want to include all shifts till end of the day
        const rangeEnd = DateTime.fromJSDate(
          Array.isArray(range) ? range[range.length - 1] : range.end,
        )
          .endOf('day')
          .toJSDate()
        setSelectedShift(undefined)

        // Set the fetch shifts range to exact match the visible range
        const visibleMonth = determineVisibleMonth(rangeStart, rangeEnd)
        const [newEndAfter, newStartBefore] =
          getCalendarMonthDisplayRange(visibleMonth)
        setEndAfter(newEndAfter)
        setStartBefore(newStartBefore)
      }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore Unfortunately there's no typescript support for customized event
      onSelectEvent={(event: TrabaCalendarEvent, e) => {
        if (
          isShiftRequestParentWithShifts(event.resource?.item) &&
          !isFromScheduleDetails
        ) {
          return navigate(
            `/schedule/${event.resource?.item.shiftRequestParentId}`,
          )
        }
        if (isShiftAndAddress(event.resource?.item)) {
          handleClickOnFab(e)
          if (selectedShift?.shiftId === event.resource?.item.shiftId) {
            setSelectedShift(undefined)
          } else {
            setSelectedShift(event.resource?.item)
          }
        }
      }}
      components={{
        event: (props) => (
          <CalendarViewCustomEvent {...props} currView={currView} />
        ),
        toolbar: (props) => (
          <CalendarViewCustomToolbar
            {...props}
            isLoading={isLoading}
            isFromScheduleDetails={isFromScheduleDetails}
          />
        ),
        header: CalendarViewCustomHeader,
      }}
      eventPropGetter={eventPropGetter}
      onView={(view: BigCalendarViewType) => {
        setCurrView(view)
        localStorage.setItem(USER_CALENDER_VIEW, view)
      }}
      formats={formats}
      defaultView={userLastCalendarView as BigCalendarViewType}
    />
  )
}
