import { DEFAULT_TIMEZONE } from '@traba/consts'
import { Card, InlineBanner, Text } from 'src/components'
import { EditableContentCardV2 } from 'src/components/EditableContentCard/EditableContentCardV2'
import { useCompanyWorkersByIds } from 'src/hooks/useCompanyWorkers'
import { useInvoiceGroups } from 'src/hooks/useInvoiceGroups'
import { useLocations } from 'src/hooks/useLocations'
import { useMembers } from 'src/hooks/useMembers'
import { useRoles } from 'src/hooks/useRoles'
import { theme } from 'src/libs/theme'
import { BookShiftsProps } from '../BookShiftsScreen'
import {
  getInvoiceGroupEntries,
  getLocationCardEntries,
  getScheduleCardEntriesShiftDataModel,
  getRoleCardEntriesShiftDataModel,
} from '../utils'

enum BookShiftPages {
  LOCATION,
  SCHEDULE,
  WORKERS,
  INVOICE,
  CONFIRM,
}

export function BookShiftsConfirmContentShiftDataModel(props: BookShiftsProps) {
  const {
    shiftRequest,
    onNavigate,
    isEdit,
    editsResult,
    selectedSingleShiftDates,
    recurringRoles,
  } = props

  const workerIdsByBatch = shiftRequest.shiftInvitations?.flatMap(
    (shiftInvitation) =>
      shiftInvitation.workers.map((worker) => worker.workerId),
  )
  const { workersMap } = useCompanyWorkersByIds({ workerIds: workerIdsByBatch })

  const { roles } = useRoles()
  const { activeLocations } = useLocations()
  const { members } = useMembers()
  const { activeInvoiceGroups: invoiceGroups } = useInvoiceGroups()

  const location = activeLocations.find(
    (l) => l.locationId === shiftRequest.locationId,
  )
  const parkingLocation = activeLocations.find(
    (l) => l.locationId === shiftRequest.parkingLocationId,
  )

  const hasInvoiceGroup = !!shiftRequest.parentInvoiceGroupId
  const invoiceGroup = hasInvoiceGroup
    ? invoiceGroups.find(
        (group) => group.id === shiftRequest.parentInvoiceGroupId,
      )
    : undefined

  return (
    <Card style={{ backgroundColor: theme.colors.Grey10 }}>
      {!isEdit && (
        <Text variant="h4" style={{ marginBottom: theme.space.xs }}>
          Confirm
        </Text>
      )}
      {isEdit && editsResult && (
        <InlineBanner
          style={{ marginBottom: theme.space.sm }}
          severity={editsResult.severity}
          text={editsResult.title}
          subTitle={editsResult.subTitle}
        />
      )}
      <div>
        <EditableContentCardV2
          sections={[
            {
              title: 'Location',
              iconName: 'location',
              onEdit: () => {
                onNavigate(BookShiftPages.LOCATION)
                window.analytics.track(`User Clicked Edit Location`, { isEdit })
              },
              entries: getLocationCardEntries(location),
            },
            ...(parkingLocation
              ? [
                  {
                    title: 'Parking',
                    onEdit: () => {
                      onNavigate(BookShiftPages.LOCATION)
                      window.analytics.track(
                        `User Clicked Edit Parking Location`,
                        {
                          isEdit,
                        },
                      )
                    },
                    entries: getLocationCardEntries(parkingLocation),
                  },
                ]
              : []),
          ]}
        />
        <EditableContentCardV2
          style={{ marginTop: theme.space.lg }}
          sections={[
            {
              title: 'Schedule',
              onEdit: () => {
                onNavigate(BookShiftPages.SCHEDULE)
                window.analytics.track(`User Clicked Edit Schedule`, { isEdit })
              },
              iconName: 'calendar',
              entries: getScheduleCardEntriesShiftDataModel(
                shiftRequest,
                location?.timezone || DEFAULT_TIMEZONE,
                isEdit,
                selectedSingleShiftDates,
              ),
            },
            {
              title: 'Roles & Workers',
              iconName: 'twoUser',
              onEdit: () => {
                onNavigate(BookShiftPages.WORKERS)
                window.analytics.track(`User Clicked Edit Workers`, { isEdit })
              },
              entries: recurringRoles.map((roleInfo, idx) => {
                const roleNumber = recurringRoles.length > 1 ? idx + 1 : 0
                return getRoleCardEntriesShiftDataModel({
                  roleNumber,
                  role: roles.find((r) => r.roleId === roleInfo.roleId),
                  supervisor: members.find(
                    (m) => m.uid === roleInfo.supervisorId,
                  ),
                  roleInfo,
                  workersMap,
                })
              }),
            },
          ]}
        />
        {hasInvoiceGroup && (
          <EditableContentCardV2
            style={{ marginTop: theme.space.lg }}
            sections={[
              {
                title: 'Invoice Group',
                iconName: 'dollar',
                onEdit: () => {
                  onNavigate(BookShiftPages.INVOICE)
                  window.analytics.track(`User Clicked Edit Invoice Group`, {
                    isEdit,
                  })
                },
                entries: getInvoiceGroupEntries(invoiceGroup),
              },
            ]}
          />
        )}
      </div>
    </Card>
  )
}
