import { DEFAULT_TIMEZONE } from '@traba/consts'
import { Td, Text, Tr } from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  Shift,
  ShiftPayType,
  ShiftStatus,
  UserRolePermission,
  WorkerShiftWithWorkerDetails,
} from '@traba/types'
import { Dispatch, SetStateAction, useMemo } from 'react'
import { WorkerOnShiftListItem } from 'src/components/WorkersOnShiftTable/WorkerOnShiftListItem'
import {
  useWorkersOnShift,
  useWorkersOnShiftTable,
} from 'src/components/WorkersOnShiftTable/WorkersOnShiftTable.hooks'
import { useConnections } from 'src/hooks/useConnections'
import { useTimeToDestinations } from 'src/hooks/useTimeToDestinations'

import { useUserPermissions } from 'src/hooks/useUser'
import { useWorkerShifts } from 'src/hooks/workerShiftHooks'

interface Props {
  shift: Shift
  searchFilter: string
  handleWorkerShiftRowSelection: (
    checked: boolean,
    workerShift: WorkerShiftWithWorkerDetails,
  ) => void
  isFirstShift?: boolean
  selectedRows: WorkerShiftWithWorkerDetails[]
  setSearchFilter: Dispatch<SetStateAction<string>>
  dayViewRoleColor: string
  reviewColSpan: number
}

export const ScheduleDailyViewWorkerTableRoleChunk = (props: Props) => {
  const {
    shift,
    searchFilter,
    dayViewRoleColor,
    handleWorkerShiftRowSelection,
    reviewColSpan,
    selectedRows,
  } = props
  const userCanViewWages = useUserPermissions([UserRolePermission.ViewPay])
  const timezone = shift?.timezone || DEFAULT_TIMEZONE
  const shiftIsCanceled = shift && shift.status === ShiftStatus.CANCELED
  const isUpcoming = shift.startTime > new Date()
  const showEarnedMoney = userCanViewWages && !isUpcoming && !shiftIsCanceled
  const connections = useConnections()
  const { data: workerShifts } = useWorkerShifts(shift.shiftId)
  const { timeToDestinations } = useTimeToDestinations(shift?.shiftId)
  const timeToDestinationsByWorkerId = new Map(
    timeToDestinations?.workerShiftTimeToDestinations?.map((ttd) => [
      ttd.workerId,
      ttd,
    ]) || [],
  )
  const {
    workersOnShift: activeWorkerShifts,
    workersOnBackup,
    inactiveWorkerShifts,
  } = useWorkersOnShift({
    isUpcoming: true,
    workerShifts,
    isShiftCanceled: props.shift.status === ShiftStatus.CANCELED,
    slotsRequested: shift.slotsRequested,
  })

  const logic = useWorkersOnShiftTable({
    shift,
    isUpcoming: true,
  })
  const allWorkersShifts: Array<WorkerShiftWithWorkerDetails> = useMemo(
    () => [
      ...activeWorkerShifts,
      ...(workersOnBackup || []),
      ...inactiveWorkerShifts,
    ],
    [activeWorkerShifts, workersOnBackup, inactiveWorkerShifts],
  )
  const isPBUShift = shift?.payType === ShiftPayType.UNIT
  // Filter the allWorkers array based on searchString and selectedWorkerShifts
  const filteredWorkers = allWorkersShifts?.filter((workerShift) => {
    const { worker: workerInfo } = workerShift

    // Check if the workerShift is in the selectedWorkerShifts array
    const isInSelectedShifts = selectedRows.some(
      (selectedRow) => selectedRow.workerId === workerShift.workerId,
    )

    // Check if the name includes the searchString (case-insensitive)
    const name = workerInfo.firstName + ' ' + workerInfo.lastName
    const includesSearchString = name
      .toLowerCase()
      .includes(searchFilter.toLowerCase())

    // Keep the worker if either it's in selectedWorkerShifts or the name includes the searchString
    return isInSelectedShifts || includesSearchString
  })
  const backupWorkerIds = new Set(
    (workersOnBackup || []).map((w) => w.workerId),
  )

  return filteredWorkers && filteredWorkers.length > 0 ? (
    filteredWorkers.map((workerShift, index) => {
      const checked = !!selectedRows.find(
        ({ workerId }) => workerId === workerShift.workerId,
      )

      return (
        <WorkerOnShiftListItem
          {...logic}
          isFirstItem={index === 0}
          key={workerShift.workedShiftId}
          dayViewRoleColor={dayViewRoleColor}
          checked={checked}
          onCheckBoxClicked={() => {
            handleWorkerShiftRowSelection(checked, workerShift)
          }}
          isBackup={backupWorkerIds.has(workerShift.workerId)}
          isPBUShift={isPBUShift}
          reviewColSpan={reviewColSpan}
          shift={shift}
          showEarnedMoney={showEarnedMoney}
          timeToDestinationsByWorkerId={timeToDestinationsByWorkerId}
          timezone={timezone}
          workerShift={workerShift}
          connections={connections}
          editMode={false}
          isUpcoming={isUpcoming}
        />
      )
    })
  ) : (
    <Tr
      style={{
        height: 66,
        borderBottom: `1px solid ${theme.colors.Grey20}`,
      }}
    >
      <Td
        style={{
          borderLeft: `3px solid ${dayViewRoleColor}`,
        }}
      >
        <Text variant="h7" ml={theme.space.xs} color={dayViewRoleColor}>
          {shift.shiftRole}
        </Text>
      </Td>
      <Td>
        <Text variant="body2" ml={theme.space.xs}>
          - No workers scheduled -
        </Text>
      </Td>
    </Tr>
  )
}
