import {
  Button,
  ButtonVariant,
  Row,
  SvgIcon,
  Text,
} from '@traba/react-components'
import { theme } from '@traba/theme'
import {
  DailyViewOfScheduleDetails,
  DailyViewShiftMetadata,
} from '@traba/types'
import { getAddressString } from '@traba/utils'
import { useState } from 'react'
import { Col } from 'src/components'
import { ClockCode } from 'src/components/base/ClockCode'
import { ScheduleDailyViewRoleCard } from './ScheduleDailyViewRoleCard'

interface ScheduleDailyViewDetailsProps {
  shiftRequestParentDayDetails: DailyViewOfScheduleDetails | undefined
}

export const ScheduleDailyViewDetails: React.FC<
  ScheduleDailyViewDetailsProps
> = (props: ScheduleDailyViewDetailsProps) => {
  const { shiftRequestParentDayDetails } = props
  const [selectedMetaData, setSelectedMetaData] =
    useState<DailyViewShiftMetadata>()

  if (!shiftRequestParentDayDetails) {
    return null
  }

  return (
    <Row pt={theme.space.sm} justifyBetween fullWidth>
      <Row gap={theme.space.xxxl} style={{ rowGap: theme.space.xs }} wrap>
        <Col style={{ gap: theme.space.xs }}>
          <Text variant="h5">Clock in code</Text>
          <ClockCode code={Number(shiftRequestParentDayDetails.clockInCode)} />
          <Text variant="h5" mt={theme.space.xs}>
            Clock out code
          </Text>
          <ClockCode code={Number(shiftRequestParentDayDetails.clockOutCode)} />
        </Col>
        <Col style={{ flex: 1 }}>
          <Row gap={theme.space.sm} wrap>
            <Col>
              <Text variant="caption" style={{ marginBottom: theme.space.xxs }}>
                WORK SITE ADDRESS
              </Text>
              <Text variant="body1">
                {getAddressString(shiftRequestParentDayDetails.address)}
              </Text>
            </Col>
            {shiftRequestParentDayDetails.invoiceGroup && (
              <Col>
                <Text
                  variant="caption"
                  style={{ marginBottom: theme.space.xxs }}
                >
                  INVOICE GROUP
                </Text>
                <Text variant="body1">
                  {shiftRequestParentDayDetails.invoiceGroup}
                </Text>
              </Col>
            )}
          </Row>
          <Col mt={theme.space.sm}>
            <Text variant="caption" style={{ marginBottom: theme.space.xxs }}>
              ROLES
            </Text>
            {Object.values(
              shiftRequestParentDayDetails.shiftRequestToShiftMetadataMap ?? {},
            )?.map((individual, index) => {
              const { shift } = individual
              return (
                <Col key={`${shift.shiftId}-${shift.role}-${index}`}>
                  <Row
                    alignCenter
                    gap={theme.space.xxs}
                    onClick={() => {
                      if (selectedMetaData?.shift.shiftId === shift.shiftId) {
                        setSelectedMetaData(undefined)
                      } else {
                        setSelectedMetaData(individual)
                      }
                    }}
                  >
                    <Text variant="body1">
                      {`${shift.slotsRequested} ${shift.shiftRole} - ${individual.startTimeToEndTime}`}
                    </Text>
                    <SvgIcon
                      name={
                        selectedMetaData?.shift.shiftId === shift.shiftId
                          ? 'chevronUp'
                          : 'chevronDown'
                      }
                      color={theme.colors.Violet}
                      size={12}
                    />
                  </Row>
                  {selectedMetaData?.shift.shiftId === shift.shiftId && (
                    <ScheduleDailyViewRoleCard
                      selectedMetaData={selectedMetaData}
                    />
                  )}
                </Col>
              )
            })}
          </Col>
        </Col>
      </Row>

      <Button
        variant={ButtonVariant.FILLED}
        onClick={() => {
          // TODO(polyphilz): Implement
          console.log('edit shift')
        }}
      >
        Edit shift
      </Button>
    </Row>
  )
}
