import { IMenuItem, SelectDropdown } from '@traba/react-components'
import { COMPANY_WIDE_ID, COMPANY_WIDE_TEXT } from '@traba/types'
import { getLocationNameOrTruncatedAddress } from '@traba/utils'
import { useCallback, useEffect } from 'react'
import { useSelectedRegionalFilterLocations } from 'src/hooks/useRegionalFilter'

export const ALL_SELECTED_ID = 'all'
export const SELECT_LOCATION_TEXT = 'All locations'

interface LocationSingleSelectFilterProps {
  selectedLocation?: string
  setSelectedLocation: (newString: string) => void
  allLocationsSelectedText?: string
  analyticsKey?: string
}

export function LocationSingleSelectFilter({
  selectedLocation = ALL_SELECTED_ID,
  setSelectedLocation,
  allLocationsSelectedText = SELECT_LOCATION_TEXT,
  analyticsKey,
}: LocationSingleSelectFilterProps) {
  const { selectedActiveLocations: locationsForDropdown, selectedLocationIds } =
    useSelectedRegionalFilterLocations()

  const onChangeLocationFilter = useCallback((locationId: string) => {
    setSelectedLocation(locationId)
    window.analytics.track(
      `User Updated Locations Filter${analyticsKey ? ` for ${analyticsKey}` : ''}`,
      { locationId },
    )
  }, [])
  const allOptionsForLocationDropdown: IMenuItem[] = [
    { label: allLocationsSelectedText, value: ALL_SELECTED_ID },
    { label: COMPANY_WIDE_TEXT, value: COMPANY_WIDE_ID },
    ...locationsForDropdown.map((loc) => ({
      label: getLocationNameOrTruncatedAddress(loc),
      value: loc.locationId,
    })),
  ]

  const isAllLocationsSelected = selectedLocation === ALL_SELECTED_ID
  const isCompanyWideSelected = selectedLocation === COMPANY_WIDE_ID
  const isSelectedLocationNoLongerInRegionalFilterSelectedLocations =
    !isAllLocationsSelected &&
    !isCompanyWideSelected &&
    !selectedLocationIds.has(selectedLocation)

  useEffect(() => {
    if (isSelectedLocationNoLongerInRegionalFilterSelectedLocations) {
      setSelectedLocation(ALL_SELECTED_ID)
    }
  }, [isSelectedLocationNoLongerInRegionalFilterSelectedLocations])

  return (
    <SelectDropdown
      label="Location"
      placeholder="Location"
      value={selectedLocation}
      handleSelect={onChangeLocationFilter}
      menuItems={allOptionsForLocationDropdown}
      style={{ minWidth: '244px' }}
    />
  )
}
